import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "underscore";
import {
  Container, Row, Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup, FormSelect, FormInput, Button, FormCheckbox, FormTextarea, InputGroup, InputGroupText, InputGroupAddon
} from "shards-react";
//https://designrevision.com/docs/shards-react/getting-started

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

@inject(({ common }) => ({ getStoreTotalConfig: common.getStoreTotalConfig, updateDoc: common.updateDoc, getDoc: common.getDoc, getCollectionCount: common.getCollectionCount }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ company }) => ({ updateStore:company.updateStore, getApplySkins:company.getApplySkins, applyAllSkin:company.applyAllSkin, applySkin:company.applySkin}))
@inject(({ review }) => ({ setReviewSync: review.setReviewSync, cloudFlareMng:review.cloudFlareMng }))

@inject(({ AOA }) => ({ initReviewData: AOA.initReviewData }))

@observer
class ReviewWriteConfig extends Component {

  //basic
  @observable teamUser = {};
  @observable permissions = {}
  @observable store = {};
  @observable company = {};
  @observable isLogin = false;
  //
  @observable modals = {
    isProductModal: false,
    isCategoryModal: false,
  };

  //page
  //카테고리 정보와 상품 정보 샵에서 가져와야함.
  @observable skins = [];
  @observable category = [
    {depth:1, name: '상의', subCategory: [
        {depth:2, name: '티셔츠'},
        {depth:2, name: '반팔'},
      ]},
    {depth:1, name: '하의'},
  ];
  @observable product = [
    {name:'흰색 남방', productNo: '123123', img: ''},
    {name:'반팔티', productNo: '123123', img: ''},
    {name:'검은색 남방', productNo: '123123', img: ''},
  ];

  @observable reviewWrightLimit = {
    store_id: null,

    isUseNormalReview: true,
    nReviewLimitCategory: [],
    nReviewLimitProduct: [],

    isUseSnsReview: false,
    snsLimitCategory: [],
    snsLimitProduct: [],

    isUseNoPurchase: false,
    noPurchaseLimitCategory: [],
    noPurchaseLimitProduct: [],
  }

  @observable basicSettings = {
    store_id: null,
    minChars: 10,
    maxChars: 5000,
    mainPhase: [],
    subPhase: [],
    writeEnabledState: 1,
    writeEnabledDate: 50
  };
  @observable newMainPhase = '';
  @observable newSubPhase = '';

  @observable reviewFiltering = {
    store_id: null,
    filterWords: []
  };
  @observable recmFilter = {
    isUse: true
  }
  @observable filterWords = [];
  @observable newFilterWord = { block: '', alter: ''};

  @observable reviewAutoReply = {
    store_id: null,
    isUse: false,
    displayTime: 30,
    replyContents: []
  }

  @observable newReplyContents = {
    grade: null,
    text: ''
  };

  @observable reviewCount = {};


  constructor(props) {
    super(props);
    this.init()

    this.onChangeInputs = this.onChangeInputs.bind(this);
    this.onChangeChecked = this.onChangeChecked.bind(this);
    this.updateModalResult = this.updateModalResult.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.teamUser.store;
      // this.comp1any = this.props.me.company ? this.props.me.company : {};
      this.permissions = this.props.me.permissions;
      this.getApplySkins(false);
      //page
      this.props.getCollectionCount({store_id: this.teamUser.selStore_id, collections: 'review reviewScore'}, function(res) {
        _this.reviewCount = res.data;
      });

      this.props.getStoreTotalConfig({store_id: this.teamUser.selStore_id}, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          let results;
          if(res && res.data) {
            results = res.data;
            if(results.basicSettings) {
              _this.basicSettings = results.basicSettings;
            }
            if(results.filtering) {
              _this.reviewFiltering.filterWords = results.filtering.filterWords;
              _this.filterWords = results.filtering.filterWords;
            }
            if(results.writeLimit) {
              _this.reviewWrightLimit = results.writeLimit;
            }
            if(results.autoReplay) {
              _this.reviewAutoReply = results.autoReplay;
            }
            _this.basicSettings.store_id = _this.teamUser.selStore_id;
            _this.reviewFiltering.store_id = _this.teamUser.selStore_id;
            _this.reviewWrightLimit.store_id = _this.teamUser.selStore_id;
            _this.reviewAutoReply.store_id = _this.teamUser.selStore_id;
          }
        }
      });



    }
  };

  onChangeInputs = async (event, targetObj) => {
    let { name, value } = event.target;
    let isPrg = true;
    if(targetObj) {
      if(name == 'writeEnabledDate') {
        if(value > 0 && value < 60) {

        }
        else {
          if(value == '' || value === null) {
            value = '';
          }
          else {
            alert('최소 1, 최대 60일까지 가능합니다.')
            isPrg = false;
            console.log(value, typeof value)
          }

        }
      }
      if(isPrg) {
        targetObj[name] = value;
      }
    }
  };
  nullCheck = async (event, targetObj, defalutValue) => {
    let { name, value } = event.target;
    if(value == '' || value === null) {
      value = defalutValue;
    }
    targetObj[name] = value;
  }

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
      if(keyArray[0] === 'isSetScript') {
        targetObj.id = targetObj._id;
        this.props.applySkin(targetObj, function(res) {
          if(res.errors && res.errors.length > 0) {
            alert(res.errors[0].message);
            targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
          else {
            console.log(res)
            toast('처리 되었습니다.');
            //targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
        });
      }
    }
  };

  onChangeCheckList = async (targetObj, index, keyArray) => {
    if(keyArray[0]) {
      targetObj[index][keyArray[0]] = !targetObj[index][keyArray[0]];
    }
  };

  modalToggle = function(target, data) {
    this.modals[target] = !this.modals[target];
    if(!target) {
      for( var i in this.modals) {
        this.modals[i] = false;
      }
    }
    if(data) {
      this.modals.data = data;
    }

  };



  updateModalResult = async (resultData, property, targetModal) => {

    if(resultData && property[0]) {
      if(this[property[0]] && [property[1]]) {
        this[property[0]][property[1]] = [];
        for(var i in resultData) {
          let row = resultData[i];
          if(row.is) {
            this[property[0]][property[1]].push(row)
          }
          row.is = false;
        }
        this.modals.data = {};
      }
    }

    if(targetModal) {
      this.modalToggle(targetModal);
    }

  };


  updateLimits = async (event) => {
    let _this = this;

    this.props.updateDoc('updateLimits', this.reviewWrightLimit, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        _this.newFilterWord = { block: '', alter: ''};
      }
    });


  };

  addPhrase = async (event, target) => {
    let _this = this;
    if(target == 'mainPhase') {
      this.basicSettings.mainPhase.push(this.newMainPhase);
      this.newMainPhase = '';
    }
    else if(target == 'subPhase') {
      this.basicSettings.subPhase.push(this.newSubPhase);
      this.newSubPhase = '';
    }
    this.props.updateDoc('updateBasicSettings', this.basicSettings, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {

      }
    });
  }

  removePhrase = async (event, target, i) => {
    let _this = this;
    let index = i
    if(target == 'mainPhase') {
      this.basicSettings.mainPhase.splice(i, 1);
    }
    else if(target == 'subPhase') {
      this.basicSettings.subPhase.splice(i, 1);
    }
    this.props.updateDoc('updateBasicSettings', this.basicSettings, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {

      }
    });
  }



  updateStore = async (event) => {
    //cafe24UseShopNo: String, cafe24UseReviewBoardNo: String,

    if(!this.store.cafe24UseReviewBoardNo) {
      alert('리뷰 게시판 ID를 입력해 주세요.(기본값 4')
      return false;

    }

    let updateStore = {
      id: this.teamUser.store._id,
      //cafe24UseShopNo: this.store.cafe24UseShopNo,
      cafe24UseReviewBoardNo: parseInt(this.store.cafe24UseReviewBoardNo),
    }

    this.props.updateStore( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
    });
  }


  getApplySkins = async (actSync) => {
    let _this = this;
    let updateStore = {
      store_id: this.teamUser.store._id,
      actSync: actSync
    }
    this.props.getApplySkins( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        console.log(res.data);
        _this.skins = res.data;
        if(actSync) {
          toast('업데이트가 완료 되었습니다.');
        }
      }
    });
  }

  applyAllSkin = async (event) => {
    let updateStore = {
      store_id: this.teamUser.store._id
    }
    this.props.applyAllSkin( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
    });
  }

  updateBasicSetting = async (event) => {
    console.log(this.basicSettings)
    this.props.updateDoc('updateBasicSettings', this.basicSettings, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
    });
  }

  updateFilter = async (event) => {
    let _this = this;
    if(!_this.newFilterWord.block) {
      alert("차단어를 입력해 주세요");
      return false;
    }
    if(!_this.newFilterWord.alter) {
      alert("대체어를 입력해 주세요");
      return false;
    }
    this.filterWords.push(_this.newFilterWord);
    this.reviewFiltering.filterWords = this.filterWords;

    this.props.updateDoc('updateFilter', this.reviewFiltering, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        _this.newFilterWord = { block: '', alter: ''};
      }

    });
  };


  addReplyContent = async (event) => {
    let _this = this;
    console.log(_this.newReplyContents)
    if(!_this.newReplyContents.grade) {
      alert("사용자 평가 항목을 선택해 주세요.");
      return false;
    }
    else if(!_this.newReplyContents.text) {
      alert("내용을 입력해 주세요");
      return false;
    }
    else {
      this.reviewAutoReply.replyContents.push(_this.newReplyContents);
      _this.newReplyContents = {
        grade: null,
        text: ''
      };
    }

  };

  updateReplyContent = async (event) => {
    let _this = this;
    if(!_this.reviewAutoReply.store_id) {
      console.log(_this.reviewAutoReply)
      return false;
    }
    this.props.updateDoc('updateReplyContent', _this.reviewAutoReply, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
        // _this.newReplyContents = {
        //   grade: null,
        //   text: ''
        // };
      }

    });
  }

  delItem = async (del_index, targetObj) => {
    let _this = this;
    targetObj.splice(del_index, 1);
    toast('삭제 되었습니다.');
    // this.props.updateDoc('updateFilter', this.reviewFiltering, function(res) {
    //   if(res.errors && res.errors.length > 0) {
    //     alert(res.errors[0].message);
    //   }
    // });
  };

  delFilter = async (del_index, event) => {
    let _this = this;
    this.filterWords.splice(del_index, 1);

    this.reviewFiltering.filterWords = this.filterWords;

    this.props.updateDoc('updateFilter', this.reviewFiltering, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('삭제 되었습니다.');
      }

    });
  };

  setReviewSync = async (type) => {
    let _this = this;

    if(type === 'import') {
      _this.isLoading = true;
      let data = {
        store_id: _this.store._id
      }

      _this.props.setReviewSync(data, function(res) {
        _this.isLoading = false;
        if(res.errors && res.errors.length > 0) {
          toast(res.errors[0].message);
        }
        else {
          _this.props.getCollectionCount({store_id: _this.teamUser.selStore_id, collections: 'review reviewScore'}, function(res) {
            console.log(res.data);
            _this.reviewCount = res.data;
            toast(_this.reviewCount.review + '개의 리뷰를 가져왔습니다.');
          });
        }
      });
    }
    else if(type === 'export') {
      //alert('준비중입니다.');
      let data = {
        store_id: _this.store._id
      }
      _this.props.cloudFlareMng(data, function(res) {
        _this.isLoading = false;
        if(res.errors && res.errors.length > 0) {
          toast(res.errors[0].message);
        }
        else {
          toast('처리가 완료되었습니다.');
        }
      });

    }
  };

  // 슈퍼관리자만 실행
  initReviewData = async (pointInfo, currentReview) => {
    let _this = this;
    let confimRes = window.prompt("기존 온리뷰의 리뷰는 모두 삭제되고 카페24 리뷰로 대체 됩니다. 진행을 원하시면 이메일 '"+this.store.email+"'을 하단에 입력해 주세요.");
    if(confimRes === this.store.email) {
      let params = {
        store_id : _this.teamUser.selStore_id
      }
      let result = await this.props.initReviewData(params);

      _this.props.getCollectionCount({store_id: _this.teamUser.selStore_id, collections: 'review reviewScore'}, function(res) {
        console.log(res.data);
        _this.reviewCount = res.data;
        toast('리븊 데이터가 초기화가 완료되었습니다.');
      });
    }
    else {
      toast("이메일을 정확하게 입력해 주세요.");
    }
  };

  onDragStart = async (del_index, event) => {

  };

  onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.basicSettings.mainPhase = reorder(
      this.basicSettings.mainPhase,
      result.source.index,
      result.destination.index
    );
  }




  render() {

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
      userSelect: "none",
      background: isDragging ? "lightgreen" : "",
      ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "lightblue" : "",
    });

    return (
      <>
      <div className="main-content">
          <PageTitle />

          {( this.isLogin && this.teamUser.emailId ) ?
            <div className="write-post-box">

              {/* 연동 설정 */}
              <div className="section-box">
                <div className="substitute-table">
                  <div className="section-tit">
                    <h3>카페24 리뷰 연동 설정</h3>
                  </div>
                  <table className="s-table">
                    <colgroup>
                      <col width="170px" />
                      <col width="" />
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>디자인 스킨 적용</th>
                      <td>
                        <Row>
                          <Col md="8">
                            <ul className="skin-list">
                              {this.skins && this.skins.length > 0 ?
                                  this.skins.map((item, j) => (
                                      <li key={j}>
                                        <FormCheckbox toggle small checked={item.isSetScript} onChange={event=> this.onChangeChecked(event, item, ['isSetScript'])} >
                                          {item.skin_name}[{item.language_code}]<strong className="warning-font">{item.published_in == '1' ? (' - ' + (item.usage_type == 'M' ? '모바일': 'PC') + ' 사용스킨') : ''}</strong>
                                        </FormCheckbox>
                                      </li>
                                  ))
                                  : null
                              }
                            </ul>
                          </Col>
                          <Col>
                            <Button className="save-button" onClick={event => this.getApplySkins(true)}>스킨 업데이트</Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <th>리뷰 게시판 ID</th>
                      <td>
                        <InputGroup><FormInput value={this.store.cafe24UseReviewBoardNo} id="cafe24UseReviewBoardNo" name="cafe24UseReviewBoardNo" className="text-center" onChange={event => this.onChangeInputs(event, this.store)} placeholder="초기 셋팅 id는 4입니다." /></InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>카페24 리뷰 가져오기</th>
                      {this.reviewCount && this.reviewCount.review === 0 && this.reviewCount.reviewScore === 0 ? (
                        <td className="text-left"><Button id="getPlatformReview" size="sm" onClick={event=> this.setReviewSync('import')}>카페24 리뷰 가져오기</Button>(* 온리뷰에서 작성된 모든 리뷰데이터가 삭제되니 주의부탁 드립니다.)</td>
                      ) : <td className="text-left"><span className="text-primary">카페24 리뷰는 처음 시작할때 또는 기존 리뷰데이터를 초기화 후 사용이 가능합니다.</span><Button className="ml-2" size="sm" onClick={event=> this.initReviewData()}>리뷰데이터 초기화</Button></td> }
                    </tr>
                    {this.reviewCount && this.reviewCount.review === 0 && this.reviewCount.reviewScore === 0 ? (
                    <tr>
                      <th>카페24 리뷰 내보내기 해당 기능은 슈퍼관리자로 이동</th>
                      <td className="text-left"><Button size="sm" onClick={event=> this.setReviewSync('export')}>카페24 리뷰 내보내기</Button>(* 온리뷰에서 작성된 모든 리뷰가 카페24 리뷰 게시판으로 데이터를 전달됩니다.)</td>
                    </tr>
                    ) : null }
                    {/*{this.teamUser.emailId == "admin@onnomads.com" ? (*/}
                    {/*    <tr>*/}
                    {/*      <th>리뷰데이터 리셋</th>*/}

                    {/*    </tr>*/}
                    {/*) : null}*/}
                    </tbody>
                  </table>
                  <div className="btn-save">
                    <Button className="save-button" onClick={event => this.updateStore(event)}>저장</Button>
                  </div>
                </div>
              </div>

              {/* 기본 설정 */}
              <div className="section-box" id="basicWriteSettings">
                <div className="substitute-table">
                  <div className="section-tit">
                    <h3>기본 설정</h3>
                  </div>
                  <table className="s-table">
                    <colgroup>
                      <col width="170px" />
                      <col width="" />
                      <col />
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>작성 가능 시점 및 기간</th>
                      <td>
                        <FormSelect value={this.basicSettings.writeEnabledState} name="writeEnabledState" onChange={event => {this.onChangeInputs(event, this.basicSettings)}} >
                          <option value="1">배송완료 후</option>
                          <option value="2">주문 완료 후(결제완료)</option>
                          <option value="3">주문 완료 후(결제미완료 포함)</option>
                        </FormSelect>
                      </td>
                      <td>
                        <InputGroup>
                          <FormInput value={this.basicSettings.writeEnabledDate} name="writeEnabledDate" className="text-center" type="number" max="60" onChange={event => this.onChangeInputs(event, this.basicSettings)} onBlur={event => this.nullCheck(event, this.basicSettings, 50)} placeholder="작성 시점에서 몇 일까지 작성이 가능한지 입력해 주세요." />
                          <InputGroupAddon type="append" className="addon-text-notice" >일 까지 작성 가능(최대 60일)</InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>글자수 제한</th>
                      <td>
                        <InputGroup>
                          <FormInput value={this.basicSettings.minChars} name="minChars" className="text-center" onChange={event => this.onChangeInputs(event, this.basicSettings)} placeholder="최소 입력 글자수를 입력해 주세요." />
                          <InputGroupAddon type="append" className="addon-text-notice" >글자 이상</InputGroupAddon>
                        </InputGroup>
                      </td>
                      <td>
                        <InputGroup>
                          <FormInput value={this.basicSettings.maxChars} name="maxChars" className="text-center" onChange={event => this.onChangeInputs(event, this.basicSettings)} placeholder="최대 입력 글자수를 입력해 주세요." />
                          <InputGroupAddon type="append" className="addon-text-notice" >글자 이하</InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>작성 주요 안내문구</th>
                      <td colSpan={2}>
                        {this.basicSettings && this.basicSettings.mainPhase && this.basicSettings.mainPhase.length > 0 ?
                        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <ul className='phase-list' ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                {this.basicSettings.mainPhase.map((val, i) => (
                                  <Draggable key={i} draggableId={'item-' + i} index={i}>{(provided, snapshot) => (
                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                      {val}
                                      <FeatherIcon size="15" icon="x-circle" className="nd-icon" onClick={e => this.removePhrase(e, 'mainPhase', i)} /></li> )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext>
                          :
                          null
                        }
                        <InputGroup>
                          <FormInput value={this.newMainPhase} name="newMainPhase" onChange={event => {this.newMainPhase = event.target.value }} placeholder="문구를 입력해 주세요." />
                          <InputGroupAddon type="append"><Button theme="secondary" onClick={e => this.addPhrase(e, 'mainPhase')}>추가하기</Button></InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>작성 참고 안내문구</th>
                      <td colSpan={2}>
                        <ul className='phase-list'>
                        {this.basicSettings && this.basicSettings.subPhase && this.basicSettings.subPhase.length > 0 ?
                          this.basicSettings.subPhase.map((val, i) => (
                            <li className='text-left' key={i} draggable>{val} <FeatherIcon size="15" icon="x-circle" className="nd-icon" onClick={e => this.removePhrase(e, 'subPhase', i)} /></li>
                          ))
                          :
                          null
                        }
                        </ul>
                        <InputGroup>
                          <FormInput value={this.newSubPhase}  name="newSubPhase" onChange={event => {this.newSubPhase = event.target.value }} placeholder="문구를 입력해 주세요."/>
                          <InputGroupAddon type="append"><Button theme="secondary" onClick={e => this.addPhrase(e, 'subPhase')}>추가하기</Button></InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="btn-save">
                    <Button className="save-button" onClick={event => this.updateBasicSetting(event)}>저장</Button>
                  </div>
                </div>
              </div>

              {/* 리뷰 작성 제한 */}
              <div className="section-box force-hide">
                <div className="review-limit-table">
                  <div className="section-tit">
                    <h3>리뷰 작성 제한</h3>
                  </div>
                  <table className="s-table">
                    <colgroup>
                      <col width="14%" />
                      <col width="8%" />
                      <col width="39%" />
                      <col width="39%" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th>리뷰 종류</th>
                      <th>모든 상품에서 제한</th>
                      <th>카테고리별 제한</th>
                      <th>상품별 제한</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>일반 리뷰(텍스트+포토리뷰)</td>
                      <td>
                        <fieldset>
                          <FormCheckbox toggle small checked={this.reviewWrightLimit.isUseNormalReview} onChange={event=> this.onChangeChecked(event, this.reviewWrightLimit, ['isUseNormalReview'])} >
                            {this.reviewWrightLimit.isUseNormalReview ? '사용중' : '미사용중'}
                          </FormCheckbox>
                        </fieldset>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option" onClick={event=> this.modalToggle('isCategoryModal', { property: ['reviewWrightLimit', 'nReviewLimitCategory'] })}><FeatherIcon icon="plus" className="nd-icon" /></button>
                          </div>
                          {this.reviewWrightLimit.nReviewLimitCategory && this.reviewWrightLimit.nReviewLimitCategory.length > 0 ?
                            this.reviewWrightLimit.nReviewLimitCategory.map((item, i) => (
                              <div className="option-cont" key={i}>
                                <FeatherIcon icon="x" className="nd-icon" />
                                <span>{item.name}</span>
                              </div>
                            ))
                            :
                            <p className="guide-text">일반리뷰 작성을 제한할 카테고리를 선택해주세요.</p>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option" onClick={event=> this.modalToggle('isProductModal', { property: ['reviewWrightLimit', 'nReviewLimitProduct'] })}><FeatherIcon icon="plus" className="nd-icon" /></button>
                          </div>
                          {this.reviewWrightLimit.nReviewLimitProduct && this.reviewWrightLimit.nReviewLimitProduct.length > 0 ?
                            this.reviewWrightLimit.nReviewLimitProduct.map((item, i) => (
                              <div className="option-cont" key={i}>
                                <FeatherIcon icon="x" className="nd-icon" />
                                <span>{item.name}</span>
                              </div>
                            ))
                            :
                            <p className="guide-text">일반리뷰 작성을 제한할 상품을 선택해주세요.</p>
                          }
                        </div>
                      </td>
                    </tr>
                    <tr className="active">
                      <td>SNS 리뷰</td>
                      <td>
                        <fieldset>
                          <FormCheckbox toggle small checked={this.reviewWrightLimit.isUseSnsReview} onChange={event=> this.onChangeChecked(event, this.reviewWrightLimit, ['isUseSnsReview'])} >
                            {this.reviewWrightLimit.isUseSnsReview ? '사용중' : '미사용중'}
                          </FormCheckbox>
                        </fieldset>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option" onClick={event=> this.modalToggle('isCategoryModal', { property: ['reviewWrightLimit', 'snsLimitCategory'] })}><FeatherIcon icon="plus" className="nd-icon" /></button>
                          </div>
                          {this.reviewWrightLimit.snsLimitCategory && this.reviewWrightLimit.snsLimitCategory.length > 0 ?
                            this.reviewWrightLimit.snsLimitCategory.map((item, i) => (
                              <div className="option-cont" key={i}>
                                <FeatherIcon icon="x" className="nd-icon" />
                                <span>{item.name}</span>
                              </div>
                            ))
                            :
                            <p className="guide-text">일반리뷰 작성을 제한할 카테고리를 선택해주세요.</p>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option" onClick={event=> this.modalToggle('isProductModal', { property: ['reviewWrightLimit', 'snsLimitProduct'] })}><FeatherIcon icon="plus" className="nd-icon" /></button>
                          </div>
                          {this.reviewWrightLimit.snsLimitProduct && this.reviewWrightLimit.snsLimitProduct.length > 0 ?
                            this.reviewWrightLimit.snsLimitProduct.map((item, i) => (
                              <div className="option-cont" key={i}>
                                <FeatherIcon icon="x" className="nd-icon" />
                                <span>{item.name}</span>
                              </div>
                            ))
                            :
                            <p className="guide-text">일반리뷰 작성을 제한할 상품을 선택해주세요.</p>
                          }
                        </div>
                      </td>
                    </tr>
                    <tr className="active">
                      <td>
                        <span>미구매자 리뷰 작성</span>
                        <span className="tooltip-icon">
                      <FeatherIcon icon="alert-circle" className="nd-icon" />
                    </span>
                      </td>
                      <td>
                        <fieldset>
                          <FormCheckbox toggle small checked={this.reviewWrightLimit.isUseNoPurchase} onChange={event=> this.onChangeChecked(event, this.reviewWrightLimit, ['isUseNoPurchase'])} >
                            {this.reviewWrightLimit.isUseNoPurchase ? '사용중' : '미사용중'}
                          </FormCheckbox>
                        </fieldset>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option" onClick={event=> this.modalToggle('isCategoryModal', { property: ['reviewWrightLimit', 'noPurchaseLimitCategory'] })}><FeatherIcon icon="plus" className="nd-icon" /></button>
                          </div>
                          {this.reviewWrightLimit.noPurchaseLimitCategory && this.reviewWrightLimit.noPurchaseLimitCategory.length > 0 ?
                            this.reviewWrightLimit.noPurchaseLimitCategory.map((item, i) => (
                              <div className="option-cont" key={i}>
                                <FeatherIcon icon="x" className="nd-icon" />
                                <span>{item.name}</span>
                              </div>
                            ))
                            :
                            <p className="guide-text">일반리뷰 작성을 제한할 카테고리를 선택해주세요.</p>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option" onClick={event=> this.modalToggle('isProductModal', { property: ['reviewWrightLimit', 'noPurchaseLimitProduct'] })}><FeatherIcon icon="plus" className="nd-icon" /></button>
                          </div>
                          {this.reviewWrightLimit.noPurchaseLimitProduct && this.reviewWrightLimit.noPurchaseLimitProduct.length > 0 ?
                            this.reviewWrightLimit.noPurchaseLimitProduct.map((item, i) => (
                              <div className="option-cont" key={i}>
                                <FeatherIcon icon="x" className="nd-icon" />
                                <span>{item.name}</span>
                              </div>
                            ))
                            :
                            <p className="guide-text">일반리뷰 작성을 제한할 상품을 선택해주세요.</p>
                          }
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  {/*<div className="table-guide">*/}
                  {/*  <div className="review-policy-link">*/}
                  {/*    <strong>회원종류별 작성제한</strong>*/}
                  {/*    <a href="#">리뷰정책 바로가기</a>*/}
                  {/*  </div>*/}
                  {/*  <p>회원, 비회원 NPay 구매자의 리뷰작성 제한은 리뷰 정책에서 설정할 수 있습니다.</p>*/}
                  {/*</div>*/}
                  <div className="btn-save">
                    <Button className="save-button" onClick={e => this.updateLimits(e)}>저장</Button>
                  </div>
                </div>
              </div>

              <Modal className='questionGenModal' open={this.modals.isCategoryModal} toggle={this.modalToggle.bind(this)} centered={true}>
                <ModalHeader>제한할 카테고리 선택</ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <FormInput value={this.searchKeyword} name="searchKeyword" onChange={event => this.onChangeInputs(event)} placeholder="검색어를 입력해 주세요." />
                    </FormGroup>
                    <FormGroup>
                      <table>
                        <colgroup>
                          <col width="62px" />
                          <col width="" />
                        </colgroup>
                        <thead>
                        <tr>
                          <th className="text-center"><input type="checkbox" name="" /></th>
                          <th>카테고리명</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.category && this.category.length > 0 ?
                          this.category.map((item, i) => (
                            <tr key={i}>
                              <td className="text-center">
                                <FormCheckbox checked={item.is || false} onChange={event=> this.onChangeCheckList(this.category, i, ['is'])} ></FormCheckbox>
                              </td>
                              <td>{item.name}</td>
                            </tr>
                          ))
                          :
                          null
                        }
                        </tbody>
                      </table>
                    </FormGroup>
                    <div className='btn-box text-center'>
                      <Button onClick={event => this.updateModalResult(this.category, this.modals.data.property, 'isCategoryModal')}>설정하기</Button>
                      <Button onClick={event=> this.modalToggle('isCategoryModal') } theme="danger">닫기</Button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
              <Modal className='questionGenModal' open={this.modals.isProductModal} toggle={this.modalToggle.bind(this)} centered={true}>
                <ModalHeader>제한할 카테고리 선택</ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <FormInput value={this.searchKeyword} name="searchKeyword" onChange={event => this.onChangeInputs(event)} placeholder="검색어를 입력해 주세요." />
                    </FormGroup>
                    <FormGroup>
                      <table>
                        <colgroup>
                          <col width="62px" />
                          <col width="" />
                        </colgroup>
                        <thead>
                        <tr>
                          <th className="text-center"><input type="checkbox" name="" /></th>
                          <th>카테고리명</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.product && this.product.length > 0 ?
                          this.product.map((item, i) => (
                            <tr key={i}>
                              <td className="text-center">
                                <FormCheckbox checked={item.is || false} onChange={event=> this.onChangeCheckList(this.product, i, ['is'])} ></FormCheckbox>
                              </td>
                              <td>{item.name}</td>
                            </tr>
                          ))
                          :
                          null
                        }
                        </tbody>
                      </table>
                    </FormGroup>
                    <div className='btn-box text-center'>
                      <Button onClick={event => this.updateModalResult(this.product, this.modals.data.property, 'isProductModal')}>설정하기</Button>
                      <Button onClick={event=> this.modalToggle('isProductModal') } theme="danger">닫기</Button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>

              {/*필터링*/}
              <div className="section-box">
                <div className="substitute-table">
                  <div className="section-tit">
                    <h3>리뷰 단어 필터링 설정</h3>
                  </div>
                  <div className="auto-reply-use">
                    <div className="table-tit">
                      <h4>추천 필터링 사용 여부</h4>
                    </div>
                    <table className="s-table">
                      <colgroup>
                        <col width="245px" />
                        <col width="" />
                      </colgroup>
                      <tbody>
                      <tr>
                        <th>
                          <fieldset>
                            <FormCheckbox toggle small checked={this.recmFilter.isUse} onChange={event=> this.onChangeChecked(event, this.recmFilter, ['isUse'])} >
                              추천 필터링 {this.recmFilter.isUse ? '사용중' : '미사용중'}
                            </FormCheckbox>
                          </fieldset>
                        </th>
                        <td>
                          온리뷰가 제공하는 추천 필터링을 사용하시고 필터링을 아래에 더 추가가 가능합니다.
                        </td>
                        {/*<td>*/}
                        {/*  자동 답글 사용 시, 일반리뷰(SNS 리뷰 외)에 아래 내용으로 사용자 평가에 따라 랜덤하게 답글이 작성됩니다.*/}
                        {/*</td>*/}
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="s-table mt-3">
                    <colgroup>
                      <col width="20px" />
                      <col width="" />
                      <col width="" />
                      <col width="200px" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th>no</th>
                      <th>차단어</th>
                      <th>대체어</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.filterWords && this.filterWords.length > 0 ?
                        this.filterWords.map((item, i) => (
                            <tr key={i}>
                              <td className="keyword-num">{i+1}</td>
                              <td>{item.block}</td>
                              <td>{item.alter}</td>
                              <td><Button theme="warning" onClick={e => this.delFilter(i, e)}>삭제하기</Button></td>
                            </tr>
                        ))
                        :
                        null
                    }
                    <tr>
                      <td>+</td>
                      <td><FormGroup><FormInput value={this.newFilterWord.block} name="block" className="text-center" onChange={event => this.onChangeInputs(event, this.newFilterWord)} placeholder="차단어를 입력해 주세요." /></FormGroup></td>
                      <td><FormGroup><FormInput value={this.newFilterWord.alter} name="alter" className="text-center" onChange={event => this.onChangeInputs(event, this.newFilterWord)} onKeyUp={e => this.updateFilter(e)} placeholder="대체어를 입력해 주세요." /></FormGroup></td>
                      <td><Button theme="primary" onClick={e => this.updateFilter(e)}>추가하기</Button></td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="btn-save">
                    <Button className="save-button" onClick={event => this.updateReplyContent(event)}>저장</Button>
                  </div>
                </div>
              </div>


              {/* 답글 자동 작성 */}
              <div className="section-box">
                <div className="auto-reply-table">
                  <div className="section-tit">
                    <h3>답글 자동 작성 설정</h3>
                  </div>
                  <div className="auto-reply-use">
                    <div className="table-tit">
                      <h4>답글 자동작성 사용 여부</h4>
                    </div>
                    <table className="s-table">
                      <colgroup>
                        <col width="245px" />
                        <col width="" />
                      </colgroup>
                      <tbody>
                      <tr>
                        <th>
                          <fieldset>
                            <FormCheckbox toggle small checked={this.reviewAutoReply.isUse} onChange={event=> this.onChangeChecked(event, this.reviewAutoReply, ['isUse'])} >
                              자동 답글 {this.reviewWrightLimit.isUseNoPurchase ? '사용중' : '미사용중'}
                            </FormCheckbox>
                          </fieldset>

                        </th>
                        <td>
                          <FormGroup>
                            자동 답글 사용 시 &nbsp;
                            <FormInput value={this.reviewAutoReply.displayTime} default name="displayTime" className="inline-input-sm" onChange={event => this.onChangeInputs(event, this.reviewAutoReply)} placeholder="" />
                            &nbsp; 분 후 하단 사용자 평가에 따라 답글이 노출됩니다.
                          </FormGroup>
                        </td>
                        {/*<td>*/}
                        {/*  자동 답글 사용 시, 일반리뷰(SNS 리뷰 외)에 아래 내용으로 사용자 평가에 따라 랜덤하게 답글이 작성됩니다.*/}
                        {/*</td>*/}
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="auto-reply-list">
                    <div className="table-tit">
                      <h4>답글 리스트</h4>
                    </div>
                    {/*<div className="btn-reply">*/}
                    {/*  <a href="#none" className="btn-table reply-add">추가</a>*/}
                    {/*  <button type="" name="" value="" className="reply-default">모두 기본 값으로</button>*/}
                    {/*</div>*/}
                    <table className="s-table">
                      <colgroup>
                        <col width="70px" />
                        <col width="172px" />
                        <col width="" />
                        <col width="174px" />
                      </colgroup>
                      <thead>
                      <tr>
                        <th>사용 여부</th>
                        <th>사용자 평가</th>
                        <th>내용</th>
                        <th>관리</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.reviewAutoReply.replyContents && this.reviewAutoReply.replyContents.length > 0 ?
                        this.reviewAutoReply.replyContents.map((item, i) => (
                          <tr key={i}>
                            <td className='text-center' >
                              <fieldset>
                                <FormCheckbox toggle small checked={item.isUse} onChange={event=> this.onChangeChecked(event, item, ['isUse'])} ></FormCheckbox>
                              </fieldset>
                            </td>
                            <td>
                              <div className="rating-select">
                                <FormSelect name="grade" className="" value={item.grade || ''} onChange={event => this.onChangeInputs(event, item)}>
                                  <option value="">사용자 평가 선택</option>
                                  <option value="41_100">3~5점(긍정)</option>
                                  <option value="1_40">1~2점(부정)</option>
                                  <option value="81_100">5점</option>
                                  <option value="61_80">4점</option>
                                  <option value="41_60">3점</option>
                                  <option value="21_40">2점</option>
                                  <option value="1_20">1점</option>
                                </FormSelect>
                              </div>
                            </td>
                            <td>
                              <div className="reply-content active">
                                <FormGroup>
                                  <FormTextarea name="text" value={item.text} onChange={event => this.onChangeInputs(event, item)} placeholder="사용자 리뷰에 자동으로 작성될 답글내용을 입력해 주세요." />
                                </FormGroup>
                              </div>
                            </td>
                            <td>
                              <Button theme="warning" onClick={e => this.delItem(i, this.reviewAutoReply.replyContents)}>삭제하기</Button>
                            </td>
                          </tr>
                        ))
                        :
                        null
                      }
                      <tr>
                        <td className='text-center'> - </td>
                        <td>
                          <div className="rating-select">
                            <select name="grade" className="" value={this.newReplyContents.grade || ''} onChange={event => this.onChangeInputs(event, this.newReplyContents)}>
                              <option value="">사용자 평가 선택</option>
                              <option value="41_100">3~5점(긍정)</option>
                              <option value="1_40">1~2점(부정)</option>
                              <option value="81_100">5점</option>
                              <option value="61_80">4점</option>
                              <option value="41_60">3점</option>
                              <option value="21_40">2점</option>
                              <option value="1_20">1점</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="reply-content active">
                            <FormGroup>
                              <FormTextarea name="text" value={this.newReplyContents.text} onChange={event => this.onChangeInputs(event, this.newReplyContents)} placeholder="사용자 리뷰에 자동으로 작성될 답글내용을 입력해 주세요." />
                            </FormGroup>
                          </div>
                        </td>
                        <td>
                          <Button theme="primary" onClick={event => this.addReplyContent(event)}>추가하기</Button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="btn-save">
                    <Button className="save-button" onClick={event => this.updateReplyContent(event)}>저장</Button>
                  </div>
                </div>

              </div>
            </div>
            :
            null
          }
        </div>


      </>
    )
  }
}

export default ReviewWriteConfig;
