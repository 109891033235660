import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class TextPhotoReview extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.shopUser[name] = value;
    }
    else {
      this.shopInfo[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />

        <div className="text-photo-box">
          <div className="section-box">
            <div className="setting-link-table">
              <div className="section-tit">
                <h3>SNS리뷰 게시 시점 / 혜택 / 문자 설정</h3>
              </div>
              <table>
                <tbody>
                <tr>
                  <th>리뷰 혜택</th>
                  <td>
                    <a href="#">혜택관리 바로가기</a>
                    <p className="guide-text">리뷰 후 받게 될 혜택의 크기를 설정할 수 있습니다.</p>
                  </td>
                </tr>
                <tr>
                  <th>리뷰 문자관리</th>
                  <td>
                    <a href="#">문자관리 바로가기</a>
                    <p className="guide-text">리뷰 후 받게 될 문자알림을 설정할 수 있습니다.</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>
    )
  }
}

export default TextPhotoReview;
