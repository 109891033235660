import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class SnsReview extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.shopUser[name] = value;
    }
    else {
      this.shopInfo[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />


        <div className="sns-review-box">
          <div className="section-box">
            <div className="account-connect-table">
              <div className="section-tit">
                <h3>SNS 리뷰 계정 연결</h3>
              </div>
              <table>
                <tbody>
                <tr>
                  <th>SNS 리뷰 사용 여부</th>
                  <td>
                    <div className="table-cont">
                      <label className="btn-switch">
                        <input type="checkbox" />
                          <span className="slider"></span>
                      </label>
                      <span>사용</span>
                    </div>
                    <a href="#">SNS 리뷰가 무엇인가요?</a>
                  </td>
                </tr>
                <tr>
                  <th>SNS 계정 연결</th>
                  <td>
                    <button className="account-connect facebook">Facebook 연결하기</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="section-box">
            <form name="" id="" method="get">
              <div className="certify-condition-table hashtag">
                <div className="section-tit">
                  <h3>SNS 리뷰 인증 조건 / 해시태그</h3>
                </div>
                <div className="all-product-apply">
                  <div className="table-tit">
                    <h4>모든 상품에 적용</h4>
                  </div>
                  <table>
                    <tbody>
                    <tr>
                      <td>
                        <div className="hashtag-box">
                          <div className="hashtag-box-icon">
                            <FeatherIcon icon="hash" className="nd-icon" />
                          </div>
                          <div className="option-wrap">
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>여성쇼핑몰</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>가성비갑</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="category-apply">
                  <div className="table-tit">
                    <h4>카테고리별 적용</h4>
                  </div>
                  <div className="btn-rule">
                    <button type="submit" name="" value="" className="rule-del">삭제</button>
                    <a href="#none" className="btn-table rule-add">
                      <FeatherIcon icon="plus" className="nd-icon" />
                      <span>규칙 추가</span>
                    </a>
                  </div>
                  <table>
                    <colgroup>
                      <col width="4%" />
                      <col width="44%" />
                      <col width="44%" />
                      <col width="8%" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><input type="checkbox" name="" /></th>
                      <th>카테고리 선택</th>
                      <th>해시태그 설정</th>
                      <th>삭제</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><input type="checkbox" name="" /></td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="hashtag-box">
                          <div className="hashtag-box-icon">
                            <FeatherIcon icon="hash" className="nd-icon" />
                          </div>
                          <div className="option-wrap">
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>나이키바지</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>아디다스티</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button className="rule-del">삭제</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="situation-apply">
                  <div className="table-tit">
                    <h4>상황별 적용</h4>
                  </div>
                  <div className="btn-rule">
                    <button type="submit" name="" value="" className="rule-del">삭제</button>
                    <a href="#none" className="btn-table rule-add">
                      <FeatherIcon icon="plus" className="nd-icon" />
                      <span>규칙 추가</span>
                    </a>
                  </div>
                  <table>
                    <colgroup>
                      <col width="4%" />
                      <col width="44%" />
                      <col width="44%" />
                      <col width="8%" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><input type="checkbox" name="" /></th>
                      <th>상품 선택</th>
                      <th>해시태그 설정</th>
                      <th>삭제</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><input type="checkbox" name="" /></td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="hashtag-box">
                          <div className="hashtag-box-icon">
                            <FeatherIcon icon="hash" className="nd-icon" />
                          </div>
                          <div className="option-wrap">
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>나이키바지</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>아디다스티</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>나이키바지</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>아디다스티</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>나이키바지</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>아디다스티</span>
                            </div>
                            <div className="option-cont">
                              <FeatherIcon icon="x" className="nd-icon" />
                              <span>나이키바지</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button className="rule-del">삭제</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="btn-save">
                  <input type="submit" value="저장" className="save-button" />
                </div>
              </div>
            </form>
          </div>
          <div className="section-box">
            <form name="" id="" method="">
              <div className="certify-condition-table period">
                <div className="section-tit">
                  <h3>SNS 리뷰 인증 조건 / 유지기간</h3>
                </div>
                <table>
                  <tbody>
                  <tr>
                    <th>SNS 유지기간</th>
                    <td>
                      <input type="number" className="table-input-number" />
                        <span>일 유지시, SNS 리뷰 인정</span>
                        <p className="guide-text">검수완료버튼 클릭시 일정과 관계없이 쿠폰이 지급됩니다.</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="btn-save">
                  <input type="submit" value="저장" className="save-button" />
                </div>
              </div>
            </form>
          </div>
          <div className="section-box">
            <div className="setting-link-table">
              <div className="section-tit">
                <h3>SNS리뷰 게시 시점 / 혜택 / 문자 설정</h3>
              </div>
              <table>
                <tbody>
                <tr>
                  <th>SNS 리뷰 게시시점</th>
                  <td>
                    <a href="#">게시관리 바로가기</a>
                    <p className="guide-text">SNS리뷰 후 게시 시점을 설정할 수 있습니다.</p>
                  </td>
                </tr>
                <tr>
                  <th>SNS 리뷰 혜택</th>
                  <td>
                    <a href="#">혜택관리 바로가기</a>
                    <p className="guide-text">SNS리뷰 후 받게 될 혜택의 크기를 설정할 수 있습니다.</p>
                  </td>
                </tr>
                <tr>
                  <th>SNS 리뷰 게시시점</th>
                  <td>
                    <a href="#">문자관리 바로가기</a>
                    <p className="guide-text">SNS리뷰 후 받게 될 문자알림을 설정할 수 있습니다.</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>
    )
  }
}

export default SnsReview;
