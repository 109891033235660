import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"
import history from "../utils/history";


export class Product {


  @action getProductList = (data, cb) => {

    let property = ['store_id', 'limit', 'page', 'sort', 'searchParams'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getProductList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };



  @action setProductSync = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'setProductSync',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action getProductGroupList = (data, cb) => {

    let property = ['store_id', 'limit', 'page', 'sort', 'searchParams'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getProductGroupList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };
  @action updateProductGroup = (data, cb) => {
    if(data._id) data.id = data._id;
    let property = ['id', 'store_id', 'parentProduct_ids', 'childProduct_ids', 'product_ids', 'name', 'type']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateProductGroup',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };


  @action removeProductGroup = (data, cb) => {
    if(data._id) data.id = data._id;
    let property = ['id', 'store_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'removeProductGroup',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

}
