import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import './style.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";

@inject(({ teamUser }) => ({ doSignIn: teamUser.doSignIn, doLogOut: teamUser.doLogOut }))
@observer
class SignIn extends Component {

  @observable teamUser = {
    userId: '',
    password: ''
  };

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    this.props.doLogOut();
    let cafe24Token = await Storage.getObject('cafe24Token');
    this.doLogin = this.doLogin.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  };

  @action
  doLogin() {
    let _this = this;
    let params = {
      emailId : this.teamUser.userId,
      password : this.teamUser.password,
    };
    this.props.doSignIn(params, function(res) {
      _this.props.history.push('/reviewList');
    });
  }

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.teamUser[name] = value;
  };

  render() {
    return (
      <>
        <div className="sign-content">
          <div className="sign-content-bg">
            <span className="bg-cont1"></span>
            <span className="bg-cont2"></span>
            <span className="bg-cont3"></span>
            <span className="bg-cont4"></span>
          </div>
          <div className="sign-content-card">
            <h3>로그인</h3>
            <div className="sign-form">
              <dl>
                <dt><input name="userId" type="text" className="input-id" placeholder="이메일 아이디" value={this.teamUser.userId} onChange={e => this.onChangeValue(e)} /></dt>
              </dl>
              <dl>
                <dt><input name="password" type="password" className="input-password" placeholder="비밀번호" value={this.teamUser.password} onChange={e => this.onChangeValue(e)} /></dt>
              </dl>
            </div>
            <p className="guide-text">*초기 비밀번호는 이메일 아이디와 동일합니다.</p>
            <div className="sign-help">
              <div className="sign-auto">
                {/*<input name="" type="checkbox" className="input-sign-auto" />*/}
                {/*<span className="sign-auto-text">자동 로그인</span>*/}
              </div>
              <div className="sign-util">
                {/*<a href="#" className="find-id">아이디 찾기</a>*/}
                {/*<span className="bar"></span>*/}
                {/*<a href="#" className="find-password">비밀번호 찾기</a>*/}
              </div>
            </div>
            <div className="btn-box">
              <input type="button" value="로그인" className="save-button" onClick={event => this.doLogin()}/>
              {/*<Link to={"/signUp"}><span>회원가입</span></Link>*/}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SignIn;
