import React from "react";
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';

import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import classNames from "classnames";
import { Col } from "shards-react";

import { Store } from "../../flux";

import './Sidebar.scss'


const Depth2 = ({ items }) => {
  let menu = items;
  return (
    <ul className="depth2">
      {menu && menu.length > 0 ?
        menu.map((item, idx) => (
          <li key={idx}>
            <a href="#">{item.label}</a>
            {menu && <Depth3 menu={menu.submenu} />}
          </li>
        )) :
        null
      }
    </ul>
  )
};


const Depth3 = ({ menu }) => {
  return (
    <ul className="depth3">
    {menu && menu.length > 0 ?
      menu.map((item, idx) => (
        <li key={idx}><a href="#">{item.label}</a></li>
      )) :
      null
    }
    </ul>
  )
};

@inject(({ common }) => ({ navItems: common.navItems, setPageInfo: common.setPageInfo }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class Sidebar extends React.Component {

  @observable teamUser = {};

  constructor(props) {
    super(props);

    this.state = {
      activeMainMenu: this.props.route.info.mainMenu,
      activeSubMenu: false,
      currentMainMenu: this.props.route.info.mainMenu,
      currentSubMenu: this.props.route.info.subMenu,
      navItems: this.props.navItems
    }

    this.isLogin = this.props.isLogin;
    this.teamUser = this.props.me;

    //console.log(this.teamUser)

    //console.log(this.props.route)
    this.props.setPageInfo(this.props.route)

    this.handleToggleSubMenu = this.handleToggleSubMenu.bind(this);
  }

  // componentDidMount() {
  //
  // }
  //
  // componentWillUnmount() {
  //
  // }

  handleToggleSubMenu(item) {
    if(item.submenu) {
      this.setState({
        ...this.state,
        activeMainMenu: item.name,
        activeSubMenu: item.name,
      });
    }
    else {
      this.hideSubMenu();
    }

  }

  hideSubMenu() {
    this.setState({
      ...this.state,
      activeMainMenu: false,
      activeSubMenu: false,
    });
  }

  render() {
    let _this = this;
    const { navItems: items } = this.state;

    return (
      <div className={'aside-wrap ' + ( _this.state.activeSubMenu  ? 'open-submenu' : '')} onMouseLeave={event => this.hideSubMenu()}>
        <div className="aside-menu">
          {/*<div className="aside-menu-toggle">*/}
          {/*  <span className="toggle-bar"></span>*/}
          {/*  <span className="toggle-bar"></span>*/}
          {/*  <span className="toggle-bar"></span>*/}
          {/*</div>*/}
          <ul className="depth1">
            {items.map((item, idx) => (
               !item.isShowSuperAdmin || (item.isShowSuperAdmin && _this.teamUser.email == 'admin@onnomads.com') ? (
                  <li className={( item.name == this.state.activeMainMenu || item.name == this.state.currentMainMenu ) ? 'menu on' : 'menu'}  key={idx} onMouseEnter={event => this.handleToggleSubMenu(item)} onClick={event => this.handleToggleSubMenu(item)}>
                    {item.to ?
                      <Link to={item.to}>
                        <i className="material-icons">{item.mIcon}</i>
                        {item.label}
                      </Link>
                      :
                      <a>
                        <i className="material-icons">{item.mIcon}</i>
                        {item.label}
                      </a>
                    }
                  </li>
                  )
                  : null
            ))}
          </ul>
        </div>
        <div className={'aside-submenu ' + ( _this.state.activeSubMenu  ? 'open' : '')} >
          {items.map((item, idx) => (
            <div key={idx} className={( item.name == _this.state.activeMainMenu ) ? 'aside-submenu-section on' : 'aside-submenu-section'} id={item.name} >
              <ul className="depth2">
                {item.submenu && item.submenu.length > 0 ?
                  item.submenu.map((item, idx) => (
                    <li key={idx} className={( item.name == _this.state.currentSubMenu ) ? 'on' : ''}>
                      {item.to ?
                        <Link to={item.to}>
                          <i className="material-icons">{item.mIcon}</i>
                          {item.label}
                        </Link>
                        :
                        <>
                          <i className="material-icons">{item.mIcon}</i>
                          {item.label}
                        </>
                      }
                      <ul className="depth3">
                        {item.submenu && item.submenu.length > 0 ?
                          item.submenu.map((item, idx) => (
                            <li key={idx}>
                              {item.to ?
                                <Link to={item.to}>
                                  <i className="material-icons">{item.mIcon}</i>
                                  {item.label}
                                </Link>
                                :
                                <>
                                  <i className="material-icons">{item.mIcon}</i>
                                  {item.label}
                                </>
                              }
                            </li>
                          )) :
                          null
                        }
                      </ul>
                    </li>
                  )) :
                  null
                }
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  route: PropTypes.object,
  hideLogoText: PropTypes.bool
};

Sidebar.defaultProps = {
  route: {},
  hideLogoText: false
};

export default Sidebar;
