import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class Induce extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.shopUser[name] = value;
    }
    else {
      this.shopInfo[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />

        <div className="review-induce-box">
          <div className="section-box">
            <div className="message-send-_table">
              <form name="" id="" method="get">
                <div className="section-tit">
                  <h3>메시지 발송 시점</h3>
                </div>
                <table>
                  <tbody>
                  <tr>
                    <th>Cafe24</th>
                    <td>
                      <div className="table-radio-wrap">
                        <input type="radio" name="cafe-delivery" id="cafe-delivery-waiting" className="table-input-radio" checked="checked" />
                          <label htmlFor="cafe-delivery-waiting" className="on">
                            <span className="text-red">Cafe24의 배송대기 상태(송장입력 후)</span> 변경 후 당일 19시~20시에 예약 발송합니다.
                          </label>
                      </div>
                      <div className="table-radio-wrap">
                        <input type="radio" name="cafe-delivery" id="cafe-delivery-shipping"  className="table-input-radio" />
                          <label htmlFor="cafe-delivery-shipping">
                            <span className="text-red">Cafe24의 배송중</span> 상태로부터 <input type="number" className="table-input-number" /> 일 후 또는 당일 19시~20시에 예약 발송합니다.
                          </label>
                      </div>
                      <div className="table-radio-wrap">
                        <input type="radio" name="cafe-delivery" id="cafe-delivery-completed" className="table-input-radio" />
                          <label htmlFor="cafe-delivery-completed">
                            <span className="text-red">Cafe24의 배송완료</span> 상태로 변경 후 당일 19시~20시 사이에 발송합니다.
                          </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>NHN COMMERCE</th>
                    <td>
                      <div className="table-radio-wrap">
                        <input type="radio" name="nhn-delivery" id="nhn-delivery-waiting" className="table-input-radio on" checked="checked" />
                          <label htmlFor="nhn-delivery-waiting" className="on">
                            <span className="text-red">NHN COMMERCE의 배송대기 상태(송장입력 후)</span> 변경 후 당일 19시~20시에 예약 발송합니다.
                          </label>
                      </div>
                      <div className="table-radio-wrap">
                        <input type="radio" name="nhn-delivery" id="nhn-delivery-shipping" className="table-input-radio" />
                          <label htmlFor="nhn-delivery-shipping">
                            <span className="text-red">NHN COMMERCE의 배송중</span> 상태로부터 <input type="number" className="table-input-number" /> 일 후 또는 당일 19시~20시에 예약 발송합니다.
                          </label>
                      </div>
                      <div className="table-radio-wrap">
                        <input type="radio" name="nhn-delivery" id="nhn-delivery-completed" className="table-input-radio" />
                          <label htmlFor="nhn-delivery-completed">
                            <span className="text-red">NHN COMMERCE의 배송완료</span> 상태로 변경 후 당일 19시~20시 사이에 발송합니다.
                          </label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="btn-save">
                  <input type="submit" value="저장" className="save-button" />
                </div>
              </form>
            </div>
          </div>
          <div className="section-box">
            <div className="kakao-notice-table">
              <div className="section-tit">
                <h3>카카오 알림톡 등록 준비</h3>
              </div>
              <table>
                <tbody>
                <tr className="kakao-id">
                  <th>카카오톡 채널 아이디</th>
                  <td>
                    <div className="connected-account disappear-cont">
                      <span className="user-id">hong-gildong</span>
                      <span className="state-text">플러스 친구 등록됨</span>
                      <button type="button" className="btn-table btn-change">변경하기</button>
                    </div>
                    <div className="hidden-cont">
                      <input type="text" className="table-input-text" />
                        <p className="guide-text">플러스친구 관리자 센터에서 '비즈니스 인증'을 받은 플러스친구만 등록 가능합니다. (예 : @onreview)</p>
                    </div>
                  </td>
                </tr>
                <tr className="hidden-cont">
                  <th>관리자 휴대폰번호</th>
                  <td>
                    <input type="text" className="table-input-text" placeholder="관리자 휴대전화 번호를 입력해주세요." />
                      <button type="button" className="btn-table btn-certification">인증번호 전송</button>
                      <p className="guide-text">카카오톡 플러스 친구에 관리자로 등록된 휴대폰 번호만 사용 가능합니다.</p>
                  </td>
                </tr>
                <tr className="hidden-cont">
                  <th>인증번호</th>
                  <td>
                    <input type="text" className="table-input-text" placeholder="인증번호를 입력해주세요." />
                      <button type="button" className="btn-table btn-register focus-cont">플러스 친구 등록</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="caller-id-table">
              <div className="section-tit">
                <h3>문자메시지 발신번호 관리</h3>
              </div>
              <table>
                <tbody>
                <tr className="admin-phone">
                  <th>관리자 전화번호</th>
                  <td>
                    <div className="connected-account disappear-cont">
                      <span className="user-phone">01086079336</span>
                      <span className="state-text">발신번호 등록됨</span>
                      <button type="button" className="btn-table btn-change">변경하기</button>
                    </div>
                    <div className="hidden-cont">
                      <input type="text" className="table-input-text" placeholder="관리자 휴대전화 번호를 입력해주세요." />
                        <button type="button" className="btn-table btn-certification">인증번호 전송</button>
                    </div>
                  </td>
                </tr>
                <tr className="hidden-cont">
                  <th>인증번호</th>
                  <td>
                    <input type="text" className="table-input-text" placeholder="인증번호를 입력해주세요." />
                      <button type="button" className="btn-table btn-register focus-cont">플러스 친구 등록</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default Induce;
