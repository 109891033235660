import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Link, Redirect} from 'react-router-dom';
import './style.scss'
import './cafe.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Button, FormCheckbox, FormGroup} from "shards-react";

@inject(({ teamUser }) => ({ oauthByCafe24: teamUser.oauthByCafe24, genTokenByCafe24: teamUser.genTokenByCafe24, authCheck:teamUser.authCheck, cafe24SignUp:teamUser.cafe24SignUp }))
@observer
class Cafe extends Component {

  @observable cafe24Store = {};
  @observable teamUser = {
    userId: '',
    password: ''
  };
  @observable redirectInfo = {
    is: false,
    pathname: '/welcome'
  }
  @observable service = {
    agree: {
      serviceAgree: false,
      privateAgree: false,
    }

  }
  @observable nowStep = 0;
  @observable joinedTeamUser = false; //스토어는 가입X, 다른 스토어에 등록된 email이 있는 경우

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    let searchParams = new URLSearchParams(window.location.search);
    if(searchParams.get('error')) {
      //https://orvad.owphew.co.kr/cafeRedirect?error=invalid_scope&error_description=The+scope+added+by+Cafe24+Developers+is+invalid.+Please+try+again.&state=2026,100,730&trace_id=8bd387a764f7de447a06065159eaebbb
      alert('인증실패');
      window.close();
    }
    else if(window.location.pathname == '/cafe24' && searchParams.get('mall_id') || !searchParams.get('code')) {
      //https://onadmin.onreview.co.kr/cafe24?lang=ko_KR&mall_id=from2d&nation=KR&shop_no=1&timestamp=1695199088&user_id=from2d&user_name=%28%EC%A3%BC%29%EC%98%A8%EB%85%B8%EB%A7%88%EB%93%9C&user_type=P&hmac=v8D5e%2Bmjs%2BR%2BlSY5ngO9nNNYHq4IqfVKuVbXmMlJemc%3D
      //http://localhost:3000/cafe24?lang=ko_KR&mall_id=from2d&nation=KR&shop_no=1&timestamp=1695199088&user_id=from2d&user_name=%28%EC%A3%BC%29%EC%98%A8%EB%85%B8%EB%A7%88%EB%93%9C&user_type=P&hmac=v8D5e%2Bmjs%2BR%2BlSY5ngO9nNNYHq4IqfVKuVbXmMlJemc%3D
      this.oauthByCafe24(searchParams);
    }
    else if(window.location.pathname == '/cafeRedirect' && searchParams.get('code')) {
      // http://localhost:3000/cafeRedirect?code=R3u09hBOCldrS3HG4IR2cD&state=2026,100,730
      this.genTokenByCafe24(searchParams);
    }
    else {
      alert('잘못된 접근 방식입니다. 문의 바랍니다.');
    }

  };

  @action
  async oauthByCafe24(searchParams) {
    // 카페24로 접속 후 리다이렉트 페이지(/cafeRedirect)로 반환
    //'https://from2d.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=okf4eWcg3UC5G3bxBeuT4A&state=2026,100,730&redirect_uri=https://onadmin.onreview.co.kr/cafeRedirect&scope=mall.write_application,mall.read_application,mall.write_category,mall.read_category,mall.write_product,mall.read_product,mall.write_collection,mall.read_collection,mall.write_personal,mall.read_personal,mall.write_order,mall.read_order,mall.write_community,mall.read_community,mall.write_customer,mall.read_customer,mall.write_store,mall.read_store,mall.write_promotion,mall.read_promotion,mall.write_design,mall.read_design,mall.read_salesreport,mall.write_mileage,mall.read_mileage,mall.read_shipping,mall.write_shipping,mall.read_translation,mall.write_translation,mall.read_analytics'
    let _this = this;
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    let res = await this.props.oauthByCafe24(params);
    // console.log(res);
    let redirectUrl = res.redirectUrl;
    delete res.redirectUrl;
    Storage.remove('mallInfo');
    Storage.setObject('mallInfo', res);
    console.log(redirectUrl);
    window.location.replace(redirectUrl);
  }

  async genTokenByCafe24(searchParams) {
    let _this = this;
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    this.props.genTokenByCafe24(params, function(res) {
      if(res.joinedTeamUser) {
          _this.joinedTeamUser = res.joinedTeamUser;
      }
      if(res.cafe24Store && res.cafe24Store._id) {
        //_this.service.emailId = res.cafe24Store.email;
        _this.service.cafe24InstalledId = res.cafe24Store._id;
        _this.nowStep = 1;
      }
      else if(res.teamUser && res.token) {
        _this.redirectInfo.is = true;
      }
      else {
        alert("접근이 잘못 되었습니다.");
        //window.location.replace('/');
      }

      if(res.cafe24Store) {
        _this.cafe24Store = res.cafe24Store;
      }


      //console.log(res)
      // window.location.replace('/');
      // if(res.emailId && res.token) {
      //   _this.redirectInfo.is = true;
      // }
      // else {
      //   console.log(res);
      // }


    });
  }

  nextStep = async (nextStep) => {
    if(nextStep == 3) {
      if(!this.service.agree.serviceAgree || !this.service.agree.privateAgree) {
        alert('정책에 모두 동의를 하셔야 합니다.');
        return false;
      }
    }
    this.nowStep = nextStep;
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.service[name] = value;
  };

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  doCafe24SignUp() {
    let _this = this;
    if(!this.service.cafe24InstalledId) {
      alert("설정이 잘못 되었습니다.");
      //window.location.replace('/');//창 닫기
    }
    else if(!this.service.agree.serviceAgree || !this.service.agree.privateAgree) {
      alert('정책에 모두 동의를 하셔야 합니다.');
    }
    else if(!this.service.password) {
      alert('비밀번호를 입력 하셔야 합니다.')
    }
    else {
      this.props.cafe24SignUp(_this.service, function(res) {
        console.log(res)
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          if(res.hasStore) {
            alert('이미 가입 되어 있습니다.')
          }
          else {
            if(res.teamUser && res.token) {
              _this.redirectInfo.is = true;
            }
            else {
              alert("처리가 잘못 되었습니다.");
              //window.location.replace('/');
            }
          }
        }
      });
    }

  }

  render() {
    return (
      <>
        {this.redirectInfo.is ? (
            <Redirect to={{pathname: this.redirectInfo.pathname}} />
        ) : (
            <>
              <div className="sign-content">
                <div className="sign-content-bg">
                  <span className="bg-cont1"></span>
                  <span className="bg-cont2"></span>
                  <span className="bg-cont3"></span>
                  <span className="bg-cont4"></span>
                </div>
                <div className="sign-content-card">
                  {this.nowStep == 1 ? (
                      <div className="step_1">
                        <h3>{this.cafe24Store.president_name}님 환영합니다.</h3>
                        <p className="welcome-text">"온리뷰 서비스에 오신 것을 환영합니다! <br/>{this.cafe24Store.company_name}쇼핑몰과 함께할 수 있어 너무 멋져요. <br/>우리 함께 멋진 경험을 만들어봐요!"</p>
                        <div className="btn-box" >
                          <Button outline pill active theme="success" size="lg" onClick={event => this.nextStep(2)}>다음으로</Button>
                        </div>

                      </div>
                  ) : null}
                  {this.nowStep == 2 ? (
                      <div className="step_2">
                        <h3>서비스 이용을 위해 동의가 필요합니다</h3>
                        <div className="sign-form">
                          <fieldset>
                            <div>
                              <a href="https://onreview/serviceAgree.html" target="_blank">이용약관 동의 보기</a>
                            </div>
                            <div>
                              <FormGroup className="">
                                <FormCheckbox toggle
                                              name="serviceAgree"
                                              checked={this.service.agree.serviceAgree || false}
                                              onChange={event=> this.onChangeChecked(event, this.service.agree, ['serviceAgree'])} >
                                  동의하기
                                </FormCheckbox>
                              </FormGroup>
                            </div>
                            <div>
                              <a href="https://onreview/serviceAgree.html" target="_blank">개인정보 이용약관 보기</a>
                            </div>
                            <div>
                              <FormGroup className="">
                                <FormCheckbox toggle
                                              name="privateAgree"
                                              checked={this.service.agree.privateAgree || false}
                                              onChange={event=> this.onChangeChecked(event, this.service.agree, ['privateAgree'])} >
                                  동의하기
                                </FormCheckbox>
                              </FormGroup>
                            </div>
                          </fieldset>
                        </div>
                        <div className="btn-box" >
                          <Button outline pill active theme="light" size="lg" onClick={event => this.nextStep(1)}>이전</Button>
                          {!this.joinedTeamUser ?
                              (<Button outline pill active theme="success" size="lg" onClick={event => this.nextStep(3)}>다음으로</Button>) :
                              (<Button outline pill active theme="info" size="lg" onClick={event => this.doCafe24SignUp()}>시작하기</Button>) }
                        </div>
                      </div>
                  ) : null}
                  {this.nowStep == 3 ? (
                      <div className="step_3">
                        <h3>관리자 비밀번호 입력</h3>
                        <div className="sign-form">
                          <dl>
                            <td>로그인 ID : {this.cafe24Store.email}</td>
                          </dl>
                          <dl>
                            <dt><input name="password" type="password" className="" placeholder="비밀번호" value={this.service.password} onChange={event => this.onChangeValue(event)} /></dt>
                          </dl>
                        </div>
                        <div className="btn-box" >
                          <Button outline pill active theme="light" size="lg" onClick={event => this.nextStep(2)}>이전</Button>
                          <Button outline pill active theme="info" size="lg" onClick={event => this.doCafe24SignUp()}>시작하기</Button>
                        </div>
                      </div>
                  ) : null}

                  {/*<div className="btn-box">*/}
                  {/*  <input type="button" value="회원가입" className="save-button" onClick={event => this.doSignUp()}/>*/}
                  {/*  <div className="sign-in">*/}
                  {/*    <span>이미 계정이 있으신가요?</span><Link to={"/signIn"}><strong>로그인</strong></Link>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </>
          )}

      </>
    )
  }
}

export default Cafe;
