import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import { SketchPicker, BlockPicker, ChromePicker } from 'react-color';

import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeDropper } from '@fortawesome/fontawesome-free-solid'

import {Button, FormCheckbox, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText} from "shards-react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

fontawesome.library.add(faEyeDropper);

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ common }) => ({ updateDoc: common.updateDoc, getDoc: common.getDoc }))

@observer
class CommonDesign extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;

  //page
  @observable isShowMColor_1 = false;
  @observable isShowMColor_2 = false;

  @observable commons = {
    mColor_1: '#f93b22',
    mColor_2: '#141c3c',
    fontSize: 13,
    userNameType: 'id',
    userNameAltCount: 5,

  };

  @observable display = {
    star: true,
    name: true,
    createdAt: true,
    options: true,
    likeCount: true,
    reply: true,
  };


  @observable detailView = {};


  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
    this.showModalFn = this.showModalFn.bind(this);
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.permissions = this.props.me.permissions;

      //page
      this.props.getDoc('getDesignConfig', {store_id: this.teamUser.selStore_id}, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          let results;
          if(res && res.data) {
            results = res.data;
            if(results.store_id) {
              _this.commons = results.commons ? results.commons : _this.commons;
              _this.display = results.display ? results.display : _this.display;
            }
          }
        }

      });

    }
  };

  onChangeColor =  async (name, color, targetObj) => {
    targetObj[name] = color.hex;
    this.showModalFn();
  };

  onChangeValue =  async (event, targetObj) => {
    const { name, value } = event.target;
    targetObj[name] = value;
    console.log(value)
    this.showModalFn();
  };

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  showModalFn =  async (targetModal) => {
    this.isShowMColor_1 = false;
    this.isShowMColor_2 = false;
    if(targetModal) {
      this[targetModal] = true;
    }
  };

  updateDesignConfig = async (target) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      // customs: this.customs,
    }
    if(target === 'commons') {
      data.commons = this.commons;
    }
    else if(target === 'display') {
      data.display = this.display;
    }

    this.props.updateDoc('updateDesignConfig', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };

  render() {
    let _this = this;
    return (
      <div className="main-content">
        <PageTitle />


        <div className="common-design-box" >
          <div className="section-box" >
            <form name="" id="commonsDesign" method="">
              <div className="common-table">
                <div className="section-tit">
                  <h3>공통 디자인</h3>
                </div>
                <table >
                  <colgroup>
                    <col style={{width: '10%'}} />
                    <col style={{width: '22%'}} />

                    <col style={{width: '10%'}} />
                    <col style={{width: '22%'}} />

                    <col style={{width: '10%'}} />
                    <col />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>테마 색상 1</th>
                    <td>
                      <div className="color-picker">
                        {this.isShowMColor_1 ? (
                          <div className="color-picker-selecter-wrap">
                            <ChromePicker
                              name="mColor_1"
                              color={ this.commons.mColor_1 }
                              onChange={(color) => {this.onChangeColor('mColor_1', color, this.commons)}}
                            />
                          </div>) : null}
                        <InputGroup>
                          <InputGroupAddon type="append">
                            <InputGroupText onClick={event => { _this.showModalFn('isShowMColor_1'); }} style={{backgroundColor:this.commons.mColor_1}}>
                              <FontAwesomeIcon icon="fa-eye-dropper" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput value={this.commons.mColor_1} placeholder="색상값을 입력해 주세요." readOnly={true} onClick={event => { _this.showModalFn('isShowMColor_1'); }}/>
                        </InputGroup>
                      </div>
                    </td>
                    <th>테마 색상 2</th>
                    <td>
                      <div className="color-picker">
                        {this.isShowMColor_2 ? (
                          <div className="color-picker-selecter-wrap">
                            <ChromePicker
                              name="mColor_2"
                              color={ this.commons.mColor_2 }
                              onChange={(color) => {this.onChangeColor('mColor_2', color, this.commons)}}
                            />
                          </div>) : null}
                        <InputGroup>
                          <InputGroupAddon type="append">
                            <InputGroupText onClick={event => { _this.showModalFn('isShowMColor_2'); }} style={{backgroundColor:this.commons.mColor_2}}>
                              <FontAwesomeIcon icon="fa-eye-dropper" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput value={this.commons.mColor_2} placeholder="색상값을 입력해 주세요." readOnly={true} onClick={event => { _this.showModalFn('isShowMColor_2'); }}/>
                        </InputGroup>
                      </div>
                    </td>
                    <th>글씨 크기</th>
                    <td>
                      <InputGroup className="mb-2">
                        <FormInput name="fontSize" value={this.commons.fontSize} placeholder="기준이 되는 글씨 크기를 설정합니다." onChange={event => {this.onChangeValue(event, this.commons)}} />
                        <InputGroupAddon type="append">
                          <InputGroupText>PX</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </td>
                  </tr>
                  <tr>
                    <th>작성자 표기 방식</th>
                    <td>
                      <FormSelect value={this.commons.userNameType} name="userNameType" onChange={event => {this.onChangeValue(event, this.commons)}} >
                        <option value="id">작성자 ID</option>
                        <option value="name">작성자 이름</option>
                      </FormSelect>
                    </td>
                    <th>작성자 계정 노출보호</th>
                    <td >
                      <FormSelect value={this.commons.commons} name="userNameAltCount" onChange={event => {this.onChangeValue(event, this.commons)}} >
                        <option value="5">use*****</option>
                        <option value="3">usen***</option>
                        <option value="2">userna**</option>
                        <option value="1">usernam*</option>
                        <option value="0">username</option>
                      </FormSelect>
                      <p className="guide-text">작성자 이름/ID의 뒤에서 N자리 만큼 *로 변환되어 출력됩니다.</p>
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <th>작성자 이름 명칭</th>*/}
                  {/*  <td>*/}
                  {/*    <input type="text" className="table-input-text"/>*/}
                  {/*  </td>*/}
                  {/*  <th>작성자 ID 명칭</th>*/}
                  {/*  <td colSpan="3">*/}
                  {/*    <input type="text" className="table-input-text" />*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr>*/}
                  {/*  <th>리뷰 검증 여부 표시</th>*/}
                  {/*  <td colSpan="5">*/}
                  {/*    <div className="option-value">*/}
                  {/*      <label className="btn-switch">*/}
                  {/*        <input type="checkbox" />*/}
                  {/*        <span className="slider"></span>*/}
                  {/*      </label>*/}
                  {/*      <span>사용</span>*/}
                  {/*    </div>*/}
                  {/*    <p className="guide-text">실제 구매자가 장성한 리뷰에 한해 검증된 리뷰라는 아이콘이 표시됩니다.</p>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  </tbody>
                </table>
                <div className="btn-save">
                  <Button className="save-button" onClick={event => this.updateDesignConfig('commons')}>저장</Button>
                </div>
              </div>
            </form>
          </div>
          <div className="section-box" >
            <form name="" id="displaySettings" method="">
              <div className="detail-view-table">
                <div className="section-tit">
                  <h3>상세보기 디자인</h3>
                  <div className="table-sub-tit">
                    <span>리뷰를 클릭했을 때 띄워지는 리뷰 상세보기 영역의 디자인을 설정해보세요</span>
                  </div>
                </div>
                <table>
                  <tbody>
                  <tr>
                    <th>별점</th>
                    <td>
                      <FormCheckbox toggle small checked={this.display.star || false} onChange={event=> this.onChangeChecked(event, this.display, ['star'])} >
                        표시중
                      </FormCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <th>작성자명</th>
                    <td>
                      <FormCheckbox toggle small checked={this.display.name || false} onChange={event=> this.onChangeChecked(event, this.display, ['name'])} >
                        표시중
                      </FormCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <th>작성일자</th>
                    <td>
                      <FormCheckbox toggle small checked={this.display.createdAt || false} onChange={event=> this.onChangeChecked(event, this.display, ['createdAt'])} >
                        표시중
                      </FormCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <th>구매 옵션</th>
                    <td>
                      <FormCheckbox toggle small checked={this.display.options || false} onChange={event=> this.onChangeChecked(event, this.display, ['options'])} >
                        표시중
                      </FormCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <th>도움 수</th>
                    <td>
                      <FormCheckbox toggle small checked={this.display.likeCount || false} onChange={event=> this.onChangeChecked(event, this.display, ['likeCount'])} >
                        표시중
                      </FormCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <th>댓글</th>
                    <td>
                      <FormCheckbox toggle small checked={this.display.reply || false} onChange={event=> this.onChangeChecked(event, this.display, ['reply'])} >
                        표시중
                      </FormCheckbox>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="btn-save">
                  <Button className="save-button" onClick={event => this.updateDesignConfig('display')}>저장</Button>
                </div>
              </div>
            </form>
          </div>
        </div>


      </div>
    )
  }
}

export default CommonDesign;
