import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"
import history from "../utils/history";
// const SINGIN = gql`
// query signIn ($emailId: String!, $password: String!) {
//         signIn(emailId: $emailId, password: $password) {
//         name email token rfToken
//     }
// }`;

export class TeamUser {

  @observable isLogin = false;
  @observable me = {
    emailId: '',
    token: '',
    rfToken: ''
  };

  @action doSignup = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'signUpTeamUser',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action setMe = (info) => {
    if(info) {
      this.isLogin = info.emailId ? true: false;
      this.me = info;
    }
    else {
      this.isLogin = false;
      this.me.emailId = '';
      this.me.token = '';
      this.me.rfToken = '';
    }
  };

  @action doSignIn = (data, cb ) => {
    let authCheck = this.authCheck;
    let params = {
      type: 'query',
      operation: 'signInTeamUser',
      variables: data,
      getData: 'emailId permissions token rfToken'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(res.data.errors) {throw res.data.errors; }
        let resData = res.data.data[params.operation];

        Storage.set('emailId', resData.emailId);
        Storage.set('token', resData.token);
        Storage.set('rfToken', resData.rfToken);
        authCheck();
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }
      })
      .catch(function (error) {
        alert('아이디 또는 비밀번호가 잘못되었습니다.(아이디 또는 비밀번호 확인은 카페24의 앱을 통해 접속 후 확인해 주세요.)');
        //return error;
      });
  };

  @action signInJoinAfter = (data, cb ) => {
    let authCheck = this.authCheck;
    let params = {
      type: 'query',
      operation: 'signInJoinAfter',
      variables: data,
      getData: 'emailId permissions token rfToken'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors) {throw res.data.errors; }
          let resData = res.data.data[params.operation];

          Storage.set('emailId', resData.emailId);
          Storage.set('token', resData.token);
          Storage.set('rfToken', resData.rfToken);
          authCheck();
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        })
        .catch(function (error) {
          alert('아이디 또는 비밀번호가 잘못되었습니다.(아이디 또는 비밀번호 확인은 카페24의 앱을 통해 접속 후 확인해 주세요.)');
          //return error;
        });
  };

  @action doLogOut = (data) => {
    Storage.clear();
    this.setMe(false);
  };

  @action authCheck = (data) => {

    const {setMe} = this;
    const token = Storage.get('token');
    const rfToken = Storage.get('rfToken');
    axios.defaults.headers.common['Authorization'] = token;
    axios.defaults.headers.common['rfAuthorization'] = rfToken;

    let params = {
      type: 'query',
      operation: 'getMe',
      variables: {},
      //getData: 'id selStore_id store emailId emailValid name email tel company_id company permissions isSuper token newToken rfToken lastconDate settingProg useExpiryDate'
      getData: '_id selStore_id store emailId emailValid name email tel permissions isSuper lastconDate basicSettings replyCount data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res && res.data && res.data.data.getMe) {
          if(res.data.data.getMe.newToken) {
            Storage.set('token', res.data.data.getMe.newToken);
          }
          if(res.data.data.getMe.newRfToken) {
            Storage.set('rfToken', res.data.data.getMe.newRfToken);
          }
          res.data.data.getMe.id = res.data.data.getMe._id;
          setMe(res.data.data.getMe)
        }
      });
  };

  @action getRfAuth = (cb) => {
    let authCheck = this.authCheck;
    const emailId = Storage.get('emailId');
    let params = {
      type: 'query',
      operation: 'getRfAuth',
      variables: {emailId: emailId},
      getData: 'token rfToken'
    };
    const gpqParams = gql(params);


    axios.post('/', gpqParams)
      .then(function (res) {
        if(!res || res.data.errors) {
          if(!res) {
            cb(false);
          }
          else if(res.data.errors[0].message === 'Invalid rfAuth.') {
            cb(false);
          }
        }
        else {
          let resData = res.data.data[params.operation];
          Storage.set('token', resData.token);
          Storage.set('rfToken', resData.rfToken);
          Storage.set('emailId', resData.emailId);
          //authCheck();
          cb(true);
        }
      });
  };


  @action updateTeamUser = (data, cb) => {

    let property = ['id', 'password', 'name', 'tel', 'email']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateTeamUser',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });

  };


  @action updatePassword = (data, cb) => {

    let newData = {
      id : data._id,
      // currentPassword : data.password,
      newPassword : data.newPassword
    }

    let params = {
      type: 'mutation',
      operation: 'updatePassword',
      variables: newData,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        console.log(res.data.data[params.operation] !== null)
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action sendEmailValid = (data, cb) => {

    let property = ['id', 'email']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'sendEmailValid',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });

  };
  @action emailValided = (data, cb) => {
    let property = ['id', 'email', 'validNum']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'emailValided',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });

  };



  @action setMe = (info) => {
    if(info) {
      this.isLogin = info.emailId ? true: false;
      this.me = info;
    }
    else {
      this.isLogin = false;
      this.me.emailId = '';
      this.me.token = '';
      this.me.rfToken = '';
    }
  };

  @action withdrawal = (data, cb) => {

    let property = ['id', 'password', 'reason']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'withdrawal',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let resData = res.data.data[params.operation];
        history.push('/');

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action delTeamUser = (data, cb) => {

    let property = ['id', 'password']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'delTeamUser',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };



  @action getTeamUserList = (data, cb) => {

    let property = ['store_id', 'name', 'email', 'page', 'limit']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getTeamUserList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action oauthByCafe24 = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'oauthByCafe24',
      variables: {param:data},
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          if(res.data.data[params.operation] && res.data.data[params.operation].data && res.data.data[params.operation].data.authUrl) {
            if(res.data.data[params.operation].data.authUrl.indexOf('error') > -1) {
              alert('잘못된 접근 방식입니다. 당사의 문의 바랍니다.');
              console.log(res.data.data[params.operation].data);
            }
            else {
              data.redirectUrl = res.data.data[params.operation].data.authUrl;
              return data;
              // Storage.remove('mallInfo');
              // Storage.setObject('mallInfo', data);
              // window.location.replace(res.data.data[params.operation].data.authUrl);
            }
          }
          else {
            Storage.remove('mallInfo');
            //window.location.replace('/');
          }

        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });

  };

  @action genTokenByCafe24 = (data, cb) => {

    let mallInfo = Storage.getObject('mallInfo');
    for(let i in data) {
      mallInfo[i] = data[i]
    }
    mallInfo.code = data.code;
    if(!mallInfo) {
      return false;
    }
    let params = {
      type: 'mutation',
      operation: 'genTokenByCafe24',
      variables: {param:mallInfo},
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let resData;
        if(res.data.data[params.operation] && res.data.data[params.operation].data) {
          resData = res.data.data[params.operation].data;
          Storage.remove('cafe24Token');
          Storage.setObject('cafe24Token', resData.cafe24Token);
          Storage.set('emailId', resData.emailId);
          Storage.set('token', resData.token);
          Storage.set('rfToken', resData.rfToken);
        }
        else {
          Storage.remove('cafe24Token');
        }

        if(typeof cb === 'function') {
          cb(resData);
        }
        else {
          return resData;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action cafe24SignUp = (data, cb ) => {
    let params = {
      type: 'mutation',
      operation: 'cafe24SignUp',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation].data);
          }
          else {
            return res.data.data[params.operation].data;
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action updateInAppOrder = (data, cb) => {
    // store_id: {type: Schema.Types.ObjectId, ref: 'store'},
    // teamUser_id: {type: Schema.Types.ObjectId, ref: 'teamUser'},
    // isPaySuccess: {type: Boolean, default: false},
    // storeName: {type: String, trim: true},
    // userName: {type: String, trim: true},
    // tel: {type: String, trim: true},
    // email: {type: String, trim: true},
    // orderName: {type: String, trim: true},
    // payType: {type: String, trim: true},
    // plan: {type: String, trim: true},
    // automaticPayment: {type: String, trim: true},
    // orderAmount: {type: Number, trim: true},
    // orderOrgAmount: {type: Number, trim: true},
    // orderSaleAmount: {type: Number, trim: true},
    // orderPlanPrice: {type: Number, trim: true},
    // privateAgree: {type: Boolean, default: false},
    // serviceAgree: {type: Boolean, default: false},
    // startDate: {type: Date},
    // endDate: {type: Date},
    // nextPay: {type: Date},
    // payment: {type: Object},

    let property = ['store_id', 'teamUser_id', 'platformOrderId', 'isPaySuccess', 'storeName', 'userName', 'tel', 'email', 'orderName', 'payType', 'plan', 'automaticPayment',
      'orderAmount', 'orderOrgAmount', 'orderSaleAmount', 'orderPlanPrice', 'privateAgree', 'serviceAgree', 'startDate', 'endDate', 'nextPayDate', 'payment'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateInAppOrder',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result;
          if(res.data && res.data.data[params.operation]) {
            result = res.data.data[params.operation];
          }
          else {
            result = res.data;
          }
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });

  };

  @action freeEventApply = (data, cb) => {
    let property = ['store_id', 'teamUser_id', 'storeName', 'userName', 'tel', 'email', 'orderName', 'payType', 'plan', 'privateAgree', 'serviceAgree'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'freeEventApply',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result;
          if(res.data && res.data.data[params.operation]) {
            result = res.data.data[params.operation];
          }
          else {
            result = res.data;
          }
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });

  };

  @action updateInAppOrderByReturnUrl = (data, cb) => {
    let property = ['platformOrderId', 'paySuccess'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateInAppOrderByReturnUrl',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors && res.data.errors[0]) {
            throw res.data.errors[0];
          }
          else if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          else {
            return error;
          }
        });
  };

  @action getInAppOrderList = (data, cb) => {

    let property = ['store_id', 'name', 'email', 'page', 'limit']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getInAppOrderList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            if(typeof cb === 'function') {
              cb(res.data.data[params.operation]);
            }
            else {
              return res.data.data[params.operation];
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };

  @action checkOrder = (data, cb) => {
    let variables = data;

    let params = {
      type: 'mutation',
      operation: 'checkOrder',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors && res.data.errors[0]) {
            throw res.data.errors[0];
          }
          else if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          else {
            return error;
          }
        });
  };

}
