import React, {Component} from 'react';
import axios from "axios/index";
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";
import moment from "moment/moment";
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {
  Row,
  Col,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  Button
} from "shards-react";



@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ common }) => ({ getDoc: common.getDoc }))
@inject(({ review }) => ({ getReviewList:review.getReviewList, setReviewSync: review.setReviewSync, updateReviewSome: review.updateReviewSome, updateReviewWidgetRef: review.updateReviewWidgetRef, removeReviewWidgetRef: review.removeReviewWidgetRef,
  updateReviewComment: review.updateReviewComment, getReviewHistoryList:review.getReviewHistoryList, getReviewMileageInfo:review.getReviewMileageInfo }))
@inject(({ product }) => ({ getProductList: product.getProductList, setProductSync: product.setProductSync }))



@observer
class ProductList extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;

  //page
  @observable isShowDetailSearch = false;
  @observable myWidgets = {
    'best' : {label: '베스트리뷰'},
    'main' : {label: '메인페이지'},
  };
  @observable addMileage;
  @observable reviewMileageConfig;

  //modals
  @observable modals = {
    isMileage: false,
    isHistory: false
  };

  //tooltip
  @observable isReviewHistoryTip = false;
  @observable isMileageHistoryTip = false;

  @observable listParams = {page:1, limit:20};
  @observable list = [];


  @observable reviewHistoryListParams = {page:1, limit:5};
  @observable curReviewHistoryList = [];
  @observable curReview = {};
  @observable curReviewMileageInfo = {};


  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){


  }

  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {

      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      //console.log(this.teamUser)
      // this.store = this.props.me.store ? this.props.me.store : {};
      // console.log('store', this.store)
      this.permissions = this.props.me.permissions;

      //page
      this.listParams.store_id = this.teamUser.selStore_id;
      this.listParams.teamUserId = this.teamUser.id;
      this.getProductList();
    }
  };

  //ui fn
  onChangeValue =  async (event, targetObj) => {
    const { name, value } = event.target;
    targetObj[name] = value;
  };

  onChangeValueAndUpdate = async (type, event, item) => {

    const { name, value } = event.target;
    if(!value || value == '') {
      return false;
    }
    let params = {};

    let result;
    if(type == 'widgetType') {
      params = {
        review_id: item._id
      };
      params[name] = value;
      result = await this.props.updateReviewWidgetRef(params, function(res) {
        item.reviewWidgetRef_ids = res.data;
      });
    }
    else {
      params = {
        id: item._id
      };
      params[name] = parseInt(value);
      result = await this.props.updateReviewSome(params);
      item[name] = value;
    }
  };


  //page fn
  getProductList = async() => {
    if(this.listParams.teamUserId) {
      this.list = await this.props.getProductList(this.listParams);
    }
    else {
      //console.log('null store_id');
    }
  };

  setProductSync = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      teamUser_id: this.teamUser.id,
    }

    this.props.setProductSync(data, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        //window.location.reload();
      }
    });
  };



  render() {
    let _this = this;


    return (
      <div className="main-content">
        <PageTitle />
        <Row className="mtb-10 text-right">
          <Col sm={{ size: 1, order: 2, offset: 11 }} >
            {this.teamUser && this.teamUser.selStore_id ? (
            <Button onClick={event=> this.setProductSync()}><FeatherIcon icon="refresh-cw" className="nd-icon" /> 상품 동기화</Button>)
              : <Button theme="secondary" disabled={true}><FeatherIcon icon="refresh-cw" className="nd-icon" /> 상품 동기화</Button>
              }
          </Col>
        </Row>

        {/*<div>*/}
        {/*  <button type='button' onClick={event=> this.setProductSync()}><FeatherIcon icon="refresh-cw" className="nd-icon" /></button>*/}
        {/*</div>*/}
        <Row>
        </Row>
        <div className="review-search-box">
          <form id="reviewSearchForm" method="get" className="review-search-form">
            <div className="section-box">
              <div className="search-input-box">
                <span>검색</span>
                <FormSelect name="searchType" className="search-type">
                  <option value="">전체</option>
                  <option value="review">리뷰</option>
                  <option value="goods">상품</option>
                  <option value="name">주문자이름</option>
                  <option value="id">아이디</option>
                  <option value="phone">전화번호</option>
                </FormSelect>
                <input type="text" name="keyword" className="table-input-text search-input" />
              </div>
              <div className="calendar-box">
                <span>조회기간</span>
                <div className="calendar-wrap">
                  <input id="datepicker1" type="text" className="table-input-text search-calendar start" /> &#126;
                  <input id="datepicker2" type="text" className="table-input-text search-calendar end" />
                </div>
              </div>
              {this.isShowDetailSearch ? (
              <div>
                <div className="detail-filter-box">
                  <table>
                    <tbody>
                    <tr>
                      <th>별점</th>
                      <td>
                        <label className="review-star star05"><input type="checkbox" name="" /><span></span></label>
                        <label className="review-star star04"><input type="checkbox" name="" /><span></span></label>
                        <label className="review-star star03"><input type="checkbox" name="" /><span></span></label>
                        <label className="review-star star02"><input type="checkbox" name="" /><span></span></label>
                        <label className="review-star star01"><input type="checkbox" name="" /><span></span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>리뷰종류</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>일반리뷰</span></label>
                        <label><input type="checkbox" name="" /><span>SNS리뷰</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>작성자 종류</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>회원</span></label>
                        <label><input type="checkbox" name="" /><span>비회원</span></label>
                        <label><input type="checkbox" name="" /><span>NPAY회원</span></label>
                        <label><input type="checkbox" name="" /><span>관리자</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>주문상태</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>취소</span></label>
                        <label><input type="checkbox" name="" /><span>반품</span></label>
                        <label><input type="checkbox" name="" /><span>교환</span></label>
                        <label><input type="checkbox" name="" /><span>관리자</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>구매여부</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>실제구매자</span></label>
                        <label><input type="checkbox" name="" /><span>구매여부미상</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>댓글작성</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>관리자작성</span></label>
                        <label><input type="checkbox" name="" /><span>미작성</span></label>
                        <label><input type="checkbox" name="" /><span>랜덤작성</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>작성플랫폼</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>온리뷰</span></label>
                        <label><input type="checkbox" name="" /><span>스마트스토어</span></label>
                        <label><input type="checkbox" name="" /><span>지그재그</span></label>
                        <label><input type="checkbox" name="" /><span>카페24</span></label>
                        <label><input type="checkbox" name="" /><span>인스타그램</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>리뷰종류</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>포토</span></label>
                        <label><input type="checkbox" name="" /><span>동영상</span></label>
                        <label><input type="checkbox" name="" /><span>설문</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>게시상태</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>게시예정</span></label>
                        <label><input type="checkbox" name="" /><span>게시대기</span></label>
                        <label><input type="checkbox" name="" /><span>게시됨</span></label>
                        <label><input type="checkbox" name="" /><span>숨김</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>혜택상태</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>지급예정</span></label>
                        <label><input type="checkbox" name="" /><span>지금대기</span></label>
                        <label><input type="checkbox" name="" /><span>비회원지급대기</span></label>
                        <label><input type="checkbox" name="" /><span>지급완료</span></label>
                        <label><input type="checkbox" name="" /><span>지급취소</span></label>
                        <label><input type="checkbox" name="" /><span>지급제한</span></label>
                        <label><input type="checkbox" name="" /><span>지급포인트없음</span></label>
                        <label><input type="checkbox" name="" /><span>회수완료</span></label>
                        <label><input type="checkbox" name="" /><span>회수실패</span></label>
                        <label><input type="checkbox" name="" /><span>지급경고</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>위젯</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>위젯 노출 중</span></label>
                        <label><input type="checkbox" name="" /><span>지정된 위젯 없음</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>순서 고정</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>상단 고정</span></label>
                        <label><input type="checkbox" name="" /><span>하단 고정</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>메모</th>
                      <td>
                        <label><input type="checkbox" name="" /><span>메모 있음</span></label>
                        <label><input type="checkbox" name="" /><span>메모 없음</span></label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="button-area">
                  <a href="#none" className="search-filter-toggle">검색 필터</a>
                  <a href="#none" className="btn_reset">검색 초기화</a>
                </div>
              </div>
              ) : null}
            </div>
            <div onClick={event=> this.isShowDetailSearch = !this.isShowDetailSearch }>
              상세검색 열기
            </div>
            <div className="btn-search">
              <input type="submit" value="검색" className="search-button" />
            </div>
          </form>
        </div>

        <div className="review-list-box">
          <form id="reviewListForm" className="review-list-form">
            <div className="section-box review-list-table">
              <table>
                <thead>
                <tr>
                  <th></th>
                  <th>상품이미지</th>
                  <th>상품정보</th>
                </tr>
                </thead>
                <tbody>
                {this.list && this.list.docs && this.list.docs.length > 0 ?
                  this.list.docs.map((item, i) => (
                    <tr key={i}>
                      <td className="text-center vertical-middle">{item.platformProductId}</td>
                      <td><img src={item.image} /></td>
                      <td>
                        <p>{item.productName}</p>
                        <p>가격 : {item.price} / 할인가 : {item.discountPrice}</p>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td></td>
                  </tr>
                }
                </tbody>
              </table>
            </div>
          </form>
        </div>

      </div>
    )
  }
}

export default ProductList;
