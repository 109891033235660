import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup, FormSelect, FormInput, Button, FormCheckbox, FormTextarea, InputGroup, InputGroupText, InputGroupAddon
} from "shards-react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


@inject(({ common }) => ({ updateDoc: common.updateDoc, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

// reviewFilteringSchema
// company_id: {type: Schema.Types.ObjectId, ref: 'company'},
// filterWords: {type: Object},

@observer
class ReviewPointConfig extends Component {

  //basic
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;

  //page
  @observable noticeText = "";
  @observable pointSettings = {
    member: { text: 0, image:0, movie:0, instagram:0 },
    noMember: { text: 0, image:0, movie:0,instagram:0 },
    isNoMemberDiff: false,
    isAuto: false
  }

  @observable pointBonusSettings = {
    //하나만 지급
    isOnlyOnce: true,
    //회원최초
    joinFirst: {
      isUse: false,
      point_1: 1000,
    },
    //선착순
    fsb: {
      isUse: false,
      point_1: 1000,
      range_1 : 10,
    },
    //이미지 수
    perImg: {
      isUse: false,
      point_1: 1000,
      range_1 : 5,
    },
    //글 수
    perText: {
      isUse: false,
      point_1: 1000,
      range_1 : 300,
    },
    //조기 작성
    early: {
      isUse: false,
      point_1: 1000,
      range_1 : 5,
    },
    //영상 선착순
    fsbMovie: {
      isUse: false,
      point_1: 1000,
      range_1 : 10,
    },
    //영상 길이
    perMovie: {
      isUse: false,
      point_1: 1000,
      range_1 : 3,
    },
    //sns 최초 작성
    snsJoinFirst: {
      isUse: false,
      point_1: 1000,
    },
    //sns 선착순
    snsFsb: {
      isUse: false,
      point_1: 1000,
      range_1 : 10,
    }
  }


  constructor(props) {
    super(props);
    this.init()

    this.onChangeInputs = this.onChangeInputs.bind(this);
    this.onChangeChecked = this.onChangeChecked.bind(this);
  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      // console.log(this.teamUser);
      // this.comp1any = this.props.me.company ? this.props.me.company : {};
      this.permissions = this.props.me.permissions;

      //page
      this.props.getDoc('getReviewPointConfig', {store_id: this.teamUser.selStore_id}, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          let results;
          if(res && res.data) {
            results = res.data;
            console.log(results)
            if(results.store_id) {
              _this.noticeText = results.noticeText ? results.noticeText : '';
              _this.pointSettings = results.pointSettings ? results.pointSettings : _this.pointSettings;
              _this.pointBonusSettings = results.pointBonusSettings ? results.pointBonusSettings : _this.pointBonusSettings;
            }
          }
        }
        _this.pointSettings.store_id = _this.teamUser.selStore_id;
        _this.pointBonusSettings.store_id = _this.teamUser.selStore_id;
      });

    }
  };

  onChangeInputs = async (event, targetObj) => {
    const { name, value } = event.target;
    targetObj[name] = value;
  };

  onChangeChecked = async (event, targetObj, keyArray) => {

    if(keyArray[0] == 'isRate') {
      if(!targetObj[keyArray[0]]) {
        alert('지급 포인트를 백분율(%) 단위로 입력해 주세요.');
      }
      else {
        alert('지급 포인트를 포인트 단위로 입력해 주세요.');
      }
      targetObj.member = { text: 0, image:0, movie:0, instagram:0 };
    }

    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  updateNoticeText = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      noticeText: this.noticeText,
    }

    this.props.updateDoc('updateNoticeText', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };

  updatePointSettings = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      pointSettings: this.pointSettings,
    }

    this.props.updateDoc('updatePointSettings', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };

  updatePointBonusSettings = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      pointBonusSettings: this.pointBonusSettings,
    }

    this.props.updateDoc('updatePointSettings', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };



  render() {
    return (
      <div className="main-content">
        <PageTitle />
        <div className="benefits-box">
          <div className="section-box">
            <div className="section-tit">
              <h3>포인트 안내 문구(상세 리뷰리스트 상단 안내 표시)</h3>
            </div>
            <FormGroup>
              <FormInput id="noticeText" value={this.noticeText} type="text" name="noticeText" className="" onChange={event => this.onChangeInputs(event, this)} placeholder="" />
            </FormGroup>
            <div className="btn-save">
              <Button className="save-button" onClick={event => this.updateNoticeText(event)}>저장</Button>
            </div>
          </div>

          <div className="section-box" id="basicPointSettings">
            <div className="point-payments-table">
              <div className="section-tit">
                <h3>기본 포인트 지급 설정</h3>
                {/*<div className="table-sub-tit">*/}
                {/*  <span>각 항목에 대해서는 <span className="text-red">누적</span>하여 아래 <span className="text-red">합산된 포인트가 지급</span>됩니다. 포인트 지급방식</span>*/}
                {/*  <a href="#" className="detail-more">자세히 알아보기</a>*/}
                {/*</div>*/}
              </div>
              <FormGroup>
                <FormCheckbox toggle small checked={this.pointSettings.isAuto || false} onChange={event=> this.onChangeChecked(event, this.pointSettings, ['isAuto'])} >
                  자동 적립(리뷰 작성 즉시 자동으로 적립합니다.)
                </FormCheckbox>
              </FormGroup>
              <FormGroup>
                <FormCheckbox toggle small checked={this.pointSettings.isRate || false} onChange={event=> this.onChangeChecked(event, this.pointSettings, ['isRate'])} >
                  결제 금액에 비례 백분율(%)로 지급
                </FormCheckbox>
              </FormGroup>
              { this.pointSettings.isRate ? (
                  <><p> - ex.상품 가격이 1000원이고 10%프로 포인트를 지급설정 -> 100 포인트 지급</p>
                  <p> - 10자리에서 반올림하여 지급합니다.</p></>) : null
              }
              <br />
              <table className="ss-table">
                <tbody>
                <tr>
                  <td></td>
                  <td colSpan="3">일반리뷰</td>
                  {/*<td>SNS 리뷰</td>*/}
                </tr>
                <tr>
                  <td>항목</td>
                  <td>텍스트</td>
                  <td>포토</td>
                  {/*<td>동영상</td>*/}
                  {/*<td>인스타그램</td>*/}
                </tr>
                <tr>
                  <td>일반회원</td>
                  <td>
                    <InputGroup className="mb-2">
                      <FormInput value={this.pointSettings.member.text} type="number" name="text" className="table-input-number text-right" onChange={event => this.onChangeInputs(event, this.pointSettings.member)} placeholder="" />
                      { this.pointSettings.isRate ? ( <InputGroupAddon type="append"><InputGroupText>%</InputGroupText></InputGroupAddon> ) : <InputGroupAddon type="append"><InputGroupText>포인트</InputGroupText></InputGroupAddon> }
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup>
                      <FormInput value={this.pointSettings.member.image} type="number" name="image" className="table-input-number text-right" onChange={event => this.onChangeInputs(event, this.pointSettings.member)} placeholder="" />
                      { this.pointSettings.isRate ? ( <InputGroupAddon type="append"><InputGroupText>%</InputGroupText></InputGroupAddon> ) : <InputGroupAddon type="append"><InputGroupText>포인트</InputGroupText></InputGroupAddon> }
                    </InputGroup>
                  </td>
                  {/*<td><FormGroup><FormInput value={this.pointSettings.member.movie} type="number" name="movie" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointSettings.member)} placeholder="" /></FormGroup></td>*/}
                  {/*<td><FormGroup><FormInput value={this.pointSettings.member.instagram} type="number" name="instagram" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointSettings.member)} placeholder="" /></FormGroup></td>*/}
                </tr>
                {/*<tr>*/}
                {/*  <td>비회원</td>*/}
                {/*  <td>*/}
                {/*    <InputGroup>*/}
                {/*      <FormInput value={this.pointSettings.noMember.text} type="number" name="text" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointSettings.noMember)} placeholder="" />*/}
                {/*      <InputGroupAddon type="append">*/}
                {/*        <InputGroupText>%</InputGroupText>*/}
                {/*      </InputGroupAddon>*/}
                {/*    </InputGroup>*/}
                {/*  </td>*/}
                {/*  <td>*/}
                {/*    <InputGroup>*/}
                {/*      <FormInput value={this.pointSettings.noMember.image} type="number" name="image" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointSettings.noMember)} placeholder="" />*/}
                {/*      <InputGroupAddon type="append">*/}
                {/*        <InputGroupText>%</InputGroupText>*/}
                {/*      </InputGroupAddon>*/}
                {/*    </InputGroup>*/}
                {/*    </td>*/}
                {/*  /!*<td><FormGroup><FormInput value={this.pointSettings.noMember.movie} type="number" name="movie" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointSettings.noMember)} placeholder="" /></FormGroup></td>*!/*/}
                {/*  /!*<td><FormGroup><FormInput value={this.pointSettings.noMember.instagram} type="number" name="instagram" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointSettings.noMember)} placeholder="" /></FormGroup></td>*!/*/}
                {/*</tr>*/}
                </tbody>
              </table>
              <div className="table-guide"><p>*고정 포인트가 아닌 상품 결제 금액에 비례 백분율(%)로 지급 가능(10단위에 반올림)</p></div>
              {/*<div className="table-switch">*/}
              {/*  <FormCheckbox toggle small checked={this.pointSettings.isNoMemberDiff} onChange={event=> this.onChangeChecked(event, this.pointSettings, ['isNoMemberDiff'])} >*/}
              {/*    비회원 종류에 따라 포인트 다르게 설정*/}
              {/*  </FormCheckbox>*/}
              {/*</div>*/}
            </div>
            <div className="btn-save">
              <Button className="save-button" onClick={event => this.updatePointSettings(event)}>저장</Button>
            </div>
          </div>


          <div className="section-box" id="bonusPointSettings">
            <div className="add-point-table">
              <div className="section-tit">
                <h3>보너스 포인트</h3>
              </div>
              <FormCheckbox toggle small checked={this.pointBonusSettings.isBonusAuto || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isBonusAuto'])} >
                보너스 포인트 자동 적립(리뷰 작성 즉시 자동으로 적립합니다.)
              </FormCheckbox>
              <div className="basic-review mt-5">
                <table className="s-table">
                  <tbody>
                  <tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.isOnlyOnce || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isOnlyOnce'])} >
                        보너스포인트는 한 번만 지급
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">리뷰에서 보너스포인트 종류는 최대 포인트 1번만 받도록 설정</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.joinFirst.isUse || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings.joinFirst, ['isUse'])} >
                        회원 최초
                      </FormCheckbox>
                    </th>
                    <td>
                      <div className="option-value">회원가입 후 첫 번째로 작성한 포토리뷰에 보너스 포인트
                        <FormInput value={this.pointBonusSettings.joinFirst.point_1 || 0} type="number" name="point_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.joinFirst)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.fsb.isUse || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings.fsb, ['isUse'])} >
                        선착순
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">포토리뷰 선착순
                        <FormInput value={this.pointBonusSettings.fsb.range_1 || 0} type="number" name="range_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.fsb)} placeholder="" />
                        등 까지 보너스 포인트
                        <FormInput value={this.pointBonusSettings.fsb.point_1 || 0} type="number" name="point_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.fsb)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>

                  <tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.perImg.isUse || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings.perImg, ['isUse'])} >
                        많은 이미지
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">
                        <FormInput value={this.pointBonusSettings.perImg.range_1 || 0} type="number" name="range_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.perImg)} placeholder="" />
                        장 이상일 경우 보너스 포인트
                        <FormInput value={this.pointBonusSettings.perImg.point_1 || 0} type="number" name="point_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.perImg)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>
                  <tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.perText.isUse || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings.perText, ['isUse'])} >
                        장문 텍스트
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">
                        <FormInput value={this.pointBonusSettings.perText.range_1 || 0} type="number" name="range_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.perText)} placeholder="" />
                        자 이상일 경우 보너스 포인트
                        <FormInput value={this.pointBonusSettings.perText.point_1 || 0} type="number" name="point_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.perText)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>
                  <tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.early.isUse || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings.early, ['isUse'])} >
                        조기 작성 인센티브
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">
                        배송완료 후
                        <FormInput value={this.pointBonusSettings.early.range_1 || 0} type="number" name="range_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.early)} placeholder="" />
                        안에 리뷰 작성 시 보너스 포인트
                        <FormInput value={this.pointBonusSettings.early.point_1 || 0} type="number" name="point_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings.early)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>
                  {/*<tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.isFsbMovie || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isFsbMovie'])} >
                        동영상 리뷰 선착순
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">
                        동영상리뷰 선착순
                        <FormInput value={this.pointBonusSettings.fsbMovieNum_1 || ''} type="number" name="fsbMovieNum_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        등 까지 보너스 포인트
                        <FormInput value={this.pointBonusSettings.fsbMovie_1 || ''} type="number" name="fsbMovie_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>
                  <tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.pointBonusSettings.isPerMovie || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isPerMovie'])} >
                        동영상 길이
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>트
                    <td>
                      <div className="option-value">
                        <FormInput value={this.pointBonusSettings.perMovieNum_1 || ''} type="number" name="perMovieNum_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        초 이상일 경우 보너스 포인트
                        <FormInput value={this.pointBonusSettings.perMovie_1 || ''} type="number" name="perMovie_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        지급
                      </div>
                      <div className="option-value add-option">
                        <FormInput value={this.pointBonusSettings.perMovieNum_2 || ''} type="number" name="perMovieNum_2" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        초 이상일 경우 보너스 포인트
                        <FormInput value={this.pointBonusSettings.perMovie_2 || ''} type="number" name="perMovie_2" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        지급
                      </div>
                      <div className="option-value add-option">
                        <FormInput value={this.pointBonusSettings.perMovieNum_3 || ''} type="number" name="perMovieNum_3" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        초 이상일 경우 보너스 포인트
                        <FormInput value={this.pointBonusSettings.perMovie_3 || ''} type="number" name="perMovie_3" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>*/}
                  </tbody>
                </table>
              </div>
              {/*<div className="sns-review">*/}
              {/*  <div className="table-tit">*/}
              {/*    <h4>SNS리뷰</h4>*/}
              {/*  </div>*/}
              {/*  <table>*/}
              {/*    <tbody>*/}
              {/*    <tr>*/}
              {/*      <th>*/}
              {/*        <FormCheckbox toggle small checked={this.pointBonusSettings.isSnsJoinFirst || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isSnsJoinFirst'])} >*/}
              {/*          회원 최초*/}
              {/*        </FormCheckbox>*/}
              {/*      </th>*/}
              {/*      <td>*/}
              {/*        <div className="option-value">*/}
              {/*          회원가입 후 첫 번째로 작성한 포토리뷰에 보너스 포인트*/}
              {/*          <FormInput value={this.pointBonusSettings.snsJoinFirst_1 || ''} type="number" name="snsJoinFirst_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />*/}
              {/*          지급*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*    </tr>*/}
              {/*    <tr>*/}
              {/*      <th>*/}
              {/*        <FormCheckbox toggle small checked={this.pointBonusSettings.isSnsFsb || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isSnsFsb'])} >*/}
              {/*          선착순*/}
              {/*        </FormCheckbox>*/}
              {/*        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>*/}
              {/*      </th>*/}
              {/*      <td>*/}
              {/*        <div className="option-value">*/}
              {/*          포토리뷰 선착순*/}
              {/*          <FormInput value={this.pointBonusSettings.snsFsbNum_1 || ''} type="number" name="snsFsbNum_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />*/}
              {/*          등 까지 보너스 포인트*/}
              {/*          <FormInput value={this.pointBonusSettings.snsFsb_1 || ''} type="number" name="snsFsb_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.pointBonusSettings)} placeholder="" />*/}
              {/*          지급*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*    </tr>*/}
              {/*    <tr className="active">*/}
              {/*      <th>*/}
              {/*        <FormCheckbox toggle small checked={this.pointBonusSettings.isSnsOnlyOnce || false} onChange={event=> this.onChangeChecked(event, this.pointBonusSettings, ['isSnsOnlyOnce'])} >*/}
              {/*          보너스포인트는 한 번만 지급*/}
              {/*        </FormCheckbox>*/}
              {/*        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>*/}
              {/*      </th>*/}
              {/*      <td>*/}
              {/*        <div className="option-value">*/}
              {/*          <span>리뷰에서 보너스포인트 종류는 최대 포인트 1번만 받도록 설정</span>*/}
              {/*          /!*<div className="btn-option">*!/*/}
              {/*          /!*  <button className="btn-add-option">*!/*/}
              {/*          /!*    <FeatherIcon icon="plus" className="nd-icon" />*!/*/}
              {/*          /!*  </button>*!/*/}
              {/*          /!*</div>*!/*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*    </tr>*/}
              {/*    </tbody>*/}
              {/*  </table>*/}
              {/*</div>*/}
              <div className="btn-save">
                <Button className="save-button" onClick={event => this.updatePointBonusSettings(event)}>저장</Button>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default ReviewPointConfig;
