import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import './style.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";

@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin, me:teamUser.me }))
@observer
class Welcome extends Component {

  @observable teamUser = {
    userId: '',
    password: ''
  };
  @observable redirectInfo = {
    is: false,
    pathname: '/'
  }

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    console.log(this.props.isLogin)
    if(this.props.isLogin) {
      this.redirectInfo.is = true;
    }
    else {
      let auth = await this.props.authCheck();
      console.log(auth)
      console.log(this.props.isLogin, this.teamUser.me);
    }

  };

  render() {
    return (
        <>
        {this.redirectInfo.is ? (
              <Redirect to={{pathname: this.redirectInfo.pathname}} />
          ) : (
              <>
                <div className="welcome-content">
                  <div className="welcome-content-bg">
                    <div>감사합니다. 이제 리뷰 서비스를 활용하요 고객에게 실패 없는 쇼핑을 할 수 있도록 멋지게 셋팅해 주세요.</div>
                    <div><a href='/signin'>시작하기</a></div>
                    <div>온리뷰 가이드 및 사용법 보기</div>
                    <div>이런 기능을 활용해 보세요. 기능 1, 기능 2, 기능3설명</div>
                  </div>
                </div>
              </>
              )}
        </>
    )
  }
}

export default Welcome;
