import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class keyword extends Component {

  @observable teamUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.teamUser[name] = value;
    }
    else {
      this.teamUser[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />

        <div className="review-keyword-box">
          <div className="section-box">
            <form name="" id="" method="get">
              <div className="keyword-table">
                <div className="section-tit">
                  <h3>추가 포인트</h3>
                </div>
                <div className="btn-keyword">
                  <button type="submit" name="" value="" className="keyword-del">삭제</button>
                  <a href="#none" className="btn-table keyword-add">키워드추가</a>
                </div>
                <table>
                  <colgroup>
                    <col width="62px" />
                    <col width="200px" />
                    <col width="" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th><input type="checkbox" name="" /></th>
                    <th>no</th>
                    <th>키워드</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="keyword-num">1</td>
                    <td className="keyword-text">별로</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>

      </div>
    )
  }
}

export default keyword;
