var moment = require("moment/moment");
exports.PointCalculator = {
  reviewPointConfig: {},
  pointSettings: {},
  pointBonusSettings: {},
  isMember: '',
  isGuest: '',
  isRate: false,
  rate: 1,
  orderAmount: 0,
  init: function (data, config) {
    this.reviewPointConfig = config;
    this.pointBonusSettings = config.pointBonusSettings;
    if(config.pointSettings.isRate) {
      this.isRate = true;
      this.orderAmount = parseInt(data.reviewHolder_id && data.reviewHolder_id.orderAmount ? data.reviewHolder_id.orderAmount : data.orderAmount);
    }

    this.setPointSettings(data);
  },
  setPointSettings: function (data) {
    this.isMember = data.isMember;
    this.isGuest = data.isGuest;
    if(this.isMember && !this.isGuest) {
      this.pointSettings = this.reviewPointConfig.pointSettings.member;
    }
    else {
      this.pointSettings = this.reviewPointConfig.pointSettings.noMember;
    }
  },
  getReviewType: function(data) {
    // image, instagram, movie, text
    //data.reviewType = data.reviewType ? data.reviewType : 'text';
  },
  getUnpaid: function(data) {
    let point;
    let payPointStatus = data.payPointStatus;

    switch (payPointStatus){
      case '1' :
      case 1 :
        if(this.isRate) {
          point = this.pointSettings[data.reviewType] ? (this.orderAmount * this.pointSettings[data.reviewType] / 100) : 0;
        }
        else {
          point = this.pointSettings[data.reviewType] ? this.pointSettings[data.reviewType] : 0;
        }

        break;
      case '2' :
      case 2 :
        point = 0;
        break;
      case '90' :
      case 90 :
        point = 0;
        break;
      default :
        point = 0;
        break;
    }

    return point;
  },
  getBonus: function(data) {
    let bonus = {
      // payPointStatusStr:this.getPayPointStatusStr(data.payBonusPointStatus)
    };
    //회원최초
    bonus.joinFirst = 0;
    if((this.pointBonusSettings.joinFirst && this.pointBonusSettings.joinFirst.isUse) && data.writerReviewNo == 0) {
      bonus.joinFirst = parseInt(this.pointBonusSettings.joinFirst.point_1);
    }
    //선착순
    bonus.fsb = 0;
    if((this.pointBonusSettings.fsb && this.pointBonusSettings.fsb.isUse) && data.reviewNo <= this.pointBonusSettings.fsb.range_1) {
      bonus.fsb = parseInt(this.pointBonusSettings.fsb.point_1);
    }
    //이미지 수
    bonus.perImg = 0;
    if((this.pointBonusSettings.perImg && this.pointBonusSettings.perImg.isUse) && data.images.length >= this.pointBonusSettings.perImg.range_1) {
      bonus.perImg = parseInt(this.pointBonusSettings.perImg.point_1);
    }
    //글 수
    bonus.perText = 0;
    if((this.pointBonusSettings.perText && this.pointBonusSettings.perText.isUse) && data.contents.length >= this.pointBonusSettings.perText.range_1) {
      bonus.perText = parseInt(this.pointBonusSettings.perText.point_1);
    }
    //조기 작성
    bonus.early = 0;
    if((this.pointBonusSettings.early && this.pointBonusSettings.early.isUse)) {
      let orderDate = moment(data.userOrder_id.orderData);
      let reviewDate = moment(data.createdAt);
      if(reviewDate.diff(orderDate, 'days') <= this.pointBonusSettings.early.range_1) {
        bonus.early = parseInt(this.pointBonusSettings.early.point_1);
      }
    }

    //하나만 지급
    let bonusOnlyOne = {
      point: 0,
    }
    if(this.pointBonusSettings.isOnlyOnce) {
      for(let key in bonus) {
        if(bonusOnlyOne.point < bonus[key] || !bonusOnlyOne.key) {
          bonusOnlyOne.key = key;
          bonusOnlyOne.point = bonus[key]
        }
      }
    }
    else {
      for(let key in bonus) {
        bonusOnlyOne.point += bonus[key];
      }
      bonusOnlyOne.key = 'total';
    }
    bonus.bonusOnlyOne = bonusOnlyOne;
    bonus.payPointStatusStr = this.getPayPointStatusStr(data.payBonusPointStatus);
    return bonus;
  },
  getPayPointStatusStr: function(payPointStatus) {
    let str = '';
    switch (payPointStatus){
      case 1 :
        str = '지급예정';
        break;
      case 2 :
        str = '지급대기';
        break;
      case 3 :
        str = '비회원 지급대기';
        break;
      case 4 :
        str = '지급완료';
        break;
      case 90 :
        str = '지급취소';
        break;
      case 91 :
        str = '지급경고';
        break;
      case 92 :
        str = '지급제한';
        break;
      case 93 :
        str = '지급포인트없음';
        break;
      case 95 :
        str = '관리자에서 확인요망';
        break;
      case 81 :
        str = '회수완료';
        break;
      case 82 :
        str = '회수실';
        break;
      default :
        str = '?';
        break;
    }
    return str;
  },
  total: function(data, reviewPointConfig) {
    this.init(data, reviewPointConfig);
    let result = {
      unpaid: this.getUnpaid(data),
      bonus: this.getBonus(data),
    }

    // console.log('pointCalculator.js result', result);
    return result;
  }
};
