import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class Widget extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.shopUser[name] = value;
    }
    else {
      this.shopInfo[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />


        <div className="widget-box">
          <div className="section-box">
            <form id="" action method="" target="" className="">
              <div className="detail-view-table">
                <div className="section-tit">
                  <h3>상세보기 디자인</h3>
                  <div className="table-sub-tit">
                    <span>리뷰를 클릭했을 때 띄워지는 리뷰 상세보기 영역의 디자인을 설정해보세요</span>
                  </div>
                </div>
                <div className="widget-pick-list">
                  <div className="pick-list-count">
                    총 <span>2</span>개
                  </div>
                  <div className="choice-num-view">
                    <select name="" className="">
                      <option value="">100개씩 보기</option>
                      <option value="">75개씩 보기</option>
                      <option value="">50개씩 보기</option>
                      <option value="">25개씩 보기</option>
                    </select>
                  </div>
                </div>
                <div className="btn-widget">
                  <button type="submit" name="" value="" className="widget-del">
                    <FeatherIcon icon="x" className="nd-icon" />
                    <span>삭제</span>
                  </button>
                  <a href="#none" className="btn-table widget-add">
                    <FeatherIcon icon="plus" className="nd-icon" />
                    <span>위젯 추가</span>
                  </a>
                </div>
                <table>
                  <colgroup>
                    <col width="4%" />
                    <col width="12%" />
                    <col width="" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th><input type="checkbox" name="" /></th>
                    <th>위젯 세트 이름</th>
                    <th>기능</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">리뷰 게시판</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">상품상세 리뷰영역</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">심플포토</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">메인페이지 리뷰</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">플로팅리뷰 위젯</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">채팅리뷰 위젯</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">롤링 위젯</td>
                    <td className="left">
                      <div className="btn-wrap">
                        <button className="btn-code-copy">코드복사</button>
                        <button className="btn-widget-modify">위젯 수정하기</button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="pagination">
                  <ul>
                    <li className="btn-page-first">
                      <a href="#">
                        <FeatherIcon icon="chevrons-left" className="nd-icon" />
                      </a>
                    </li>
                    <li className="btn-page-prev">
                      <a href="#">
                        <FeatherIcon icon="chevron-left" className="nd-icon" />
                      </a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li className="on"><a href="#">10</a></li>
                    <li className="btn-page-next">
                      <a href="#">
                        <FeatherIcon icon="chevron-right" className="nd-icon" />
                      </a>
                    </li>
                    <li className="btn-page-last">
                      <a href="#">
                        <FeatherIcon icon="chevrons-right" className="nd-icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>


      </div>
    )
  }
}

export default Widget;
