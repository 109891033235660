import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class ReviewConnect extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.shopUser[name] = value;
    }
    else {
      this.shopInfo[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />

        <div className="review-connect-box">
          <div className="section-box">
            <form id="" action method="" target="" className="">
              <div className="review-connect-table">
                <div className="section-tit">
                  <h3>리뷰 연결</h3>
                </div>
                <div className="review-pick-list">
                  <div className="pick-list-count">
                    총 <span>2</span>개
                  </div>
                  <div className="choice-num-view">
                    <select name="" className="">
                      <option value="">100개씩 보기</option>
                      <option value="">75개씩 보기</option>
                      <option value="">50개씩 보기</option>
                      <option value="">25개씩 보기</option>
                    </select>
                  </div>
                </div>
                <div className="btn-connect">
                  <button type="submit" name="" value="" className="connect-del">
                    <FeatherIcon icon="x" className="nd-icon" />
                    <span>삭제</span>
                  </button>
                  <a href="#none" className="btn-table connect-add">
                    <FeatherIcon icon="plus" className="nd-icon" />
                    <span>연결 추가</span>
                  </a>
                </div>
                <table>
                  <colgroup>
                    <col width="4%" />
                    <col width="6%" />
                    <col width="12%" />
                    <col width="" />
                    <col width="5%" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th><input type="checkbox" name="" /></th>
                    <th>유형</th>
                    <th>이름</th>
                    <th>연결된 상품</th>
                    <th>수정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">그룹연결</td>
                    <td className="left">리뷰연결로 지정한 이름</td>
                    <td className="left">
                      <div className="product-connect-wrap">
                        <div className="product-content">보아 퍼 깔깔이 점퍼 (2color)</div>
                        <span className="product-connect group">
                          <FeatherIcon icon="chevron-left" className="nd-icon left" />
                          <FeatherIcon icon="chevron-right" className="nd-icon right" />
                        </span>
                        <div className="product-content">보아 퍼 깔깔이 점퍼 (red)</div>
                        <span className="product-connect group">
                          <FeatherIcon icon="chevron-left" className="nd-icon left" />
                          <FeatherIcon icon="chevron-right" className="nd-icon right" />
                        </span>
                        <div className="product-content">보아 퍼 깔깔이 점퍼 (black)</div>
                      </div>
                    </td>
                    <td>
                      <button className="btn-connect-modify">수정</button>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" name="" /></td>
                    <td className="left">단방향연결</td>
                    <td className="left">리뷰연결로 지정한 이름</td>
                    <td className="left">
                      <div className="product-connect-wrap">
                        <div className="product-content">보아 퍼 깔깔이 점퍼 (2color)</div>
                        <span className="product-connect one-way">
                          <FeatherIcon icon="chevron-right" className="nd-icon" />
                        </span>
                        <div className="product-content">보아 퍼 깔깔이 점퍼 (red)</div>
                        <div className="product-content">보아 퍼 깔깔이 점퍼 (black)</div>
                      </div>
                    </td>
                    <td>
                      <button className="btn-connect-modify">수정</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="pagination">
                  <ul>
                    <li className="btn-page-first">
                      <a href="#">
                        <FeatherIcon icon="chevrons-left" className="nd-icon" />
                      </a>
                    </li>
                    <li className="btn-page-prev">
                      <a href="#">
                        <FeatherIcon icon="chevron-left" className="nd-icon" />
                      </a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li className="on"><a href="#">10</a></li>
                    <li className="btn-page-next">
                      <a href="#">
                        <FeatherIcon icon="chevron-right" className="nd-icon" />
                      </a>
                    </li>
                    <li className="btn-page-last">
                      <a href="#">
                        <FeatherIcon icon="chevrons-right" className="nd-icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    )
  }
}

export default ReviewConnect;
