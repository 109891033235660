import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";

import PageTitle from "../../../components/common/PageTitle";
import infoLogo from "../../../images/img/info_logo.png";
import FeatherIcon from 'feather-icons-react';

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class ReviewMng extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.shopUser[name] = value;
    }
    else {
      this.shopInfo[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />

        {( this.isLogin && this.emailId ) ?
          <div className="write-post-box">
            <div className="section-box">
              <form name="" id="" method="">
                <div className="review-limit-table">
                  <div className="section-tit">
                    <h3>리뷰 제한</h3>
                  </div>
                  <table>
                    <colgroup>
                      <col width="14%" />
                        <col width="8%" />
                          <col width="39%" />
                            <col width="39%" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th>리뷰 종류</th>
                      <th>모든 상품에서 제한</th>
                      <th>상품별 제한</th>
                      <th>카테고리별 제한</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>일반 리뷰(텍스트+포토리뷰)</td>
                      <td>
                        <label className="btn-switch">
                          <input type="checkbox" />
                            <span className="slider"></span>
                        </label>
                        <span>미사용</span>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                        </div>
                        <p className="guide-text">일반리뷰 작성을 제한할 상품명을 선택해주세요.</p>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                        </div>
                        <p className="guide-text">일반리뷰 작성을 제한할 카테고리명을 선택해주세요.</p>
                      </td>
                    </tr>
                    <tr className="active">
                      <td>SNS 리뷰</td>
                      <td>
                        <label className="btn-switch">
                          <input type="checkbox" />
                            <span className="slider"></span>
                        </label>
                        <span>사용</span>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>나이키바지</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>아디다스티</span>
                          </div>
                        </div>
                        <p className="guide-text">SNS리뷰 작성을 제한할 상품명을 선택해주세요.</p>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-니트웨어</span>
                          </div>
                          <div className="option-cont">
                            <FeatherIcon icon="x" className="nd-icon" />
                            <span>WOMEN-SWIM</span>
                          </div>
                        </div>
                        <p className="guide-text">SNS리뷰 작성을 제한할 카테고리명을 선택해주세요.</p>
                      </td>
                    </tr>
                    <tr className="active">
                      <td>
                        <span>미검증 리뷰</span>
                        <span className="tooltip-icon">
                          <FeatherIcon icon="alert-circle" className="nd-icon" />
                        </span>
                      </td>
                      <td>
                        <label className="btn-switch">
                          <input type="checkbox" />
                            <span className="slider"></span>
                        </label>
                        <span>사용</span>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                        </div>
                        <p className="guide-text">미검증 리뷰 작성을 제한할 상품명을 선택해주세요.</p>
                      </td>
                      <td>
                        <div className="option-box">
                          <div className="btn-option">
                            <button className="btn-add-option">
                              <FeatherIcon icon="plus" className="nd-icon" />
                            </button>
                          </div>
                        </div>
                        <p className="guide-text">미검증 리뷰 작성을 제한할 카테고리명을 선택해주세요.</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="table-guide">
                    <div className="review-policy-link">
                      <strong>회원종류별 작성제한</strong>
                      <a href="#">리뷰적책 바로가기</a>
                    </div>
                    <p>회원, 비회원 NPay 구매자의 리뷰작성 제한은 리뷰 정책에서 설정할 수 있습니다.</p>
                  </div>
                  <div className="btn-save">
                    <input type="submit" value="저장" className="save-button" />
                  </div>
                </div>
              </form>
            </div>
            <div className="section-box">
              <form name="" id="" method="get">
                <div className="substitute-table">
                  <div className="section-tit">
                    <h3>리뷰내용 대체어 / 필터링 설정</h3>
                  </div>
                  <div className="btn-substitute">
                    <button type="submit" name="" value="" className="substitute-del">삭제</button>
                    <a href="#none" className="btn-table substitute-add">대체어추가</a>
                  </div>
                  <table>
                    <colgroup>
                      <col width="62px" />
                        <col width="200px" />
                          <col width="" />
                            <col width="" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><input type="checkbox" name="" /></th>
                      <th>no</th>
                      <th>대상 단어</th>
                      <th>교체할 단어</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><input type="checkbox" name="" /></td>
                      <td className="keyword-num">1</td>
                      <td>별로</td>
                      <td>무난</td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="btn-save">
                    <input type="submit" value="저장" className="save-button" />
                  </div>
                </div>
              </form>
            </div>
            <div className="section-box">
              <form name="" id="" method="">
                <div className="auto-reply-table">
                  <div className="section-tit">
                    <h3>자동 답글</h3>
                  </div>
                  <div className="auto-reply-use">
                    <div className="table-tit">
                      <h4>자동 답글 사용 여부</h4>
                    </div>
                    <table>
                      <colgroup>
                        <col width="245px" />
                          <col width="" />
                      </colgroup>
                      <tbody>
                      <tr>
                        <th>
                          <label className="btn-switch">
                            <input type="checkbox" />
                              <span className="slider"></span>
                          </label>
                          <span>자동 댓글 사용</span>
                        </th>
                        <td>
                          자동 답글 사용 시, 일반리뷰(SNS 리뷰 외)에 아래 내용으로 랜덤하게 답글이 작성됩니다.
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="auto-reply-list">
                    <div className="table-tit">
                      <h4>자동 답글 리스트</h4>
                    </div>
                    <div className="btn-reply">
                      <button type="submit" name="" value="" className="reply-del">삭제</button>
                      <a href="#none" className="btn-table reply-add">추가</a>
                      <button type="" name="" value="" className="reply-default">모두 기본 값으로</button>
                    </div>
                    <table>
                      <colgroup>
                        <col width="62px" />
                          <col width="172px" />
                            <col width="" />
                              <col width="174px" />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><input type="checkbox" name="" /></th>
                        <th>만족도</th>
                        <th>답글 내용</th>
                        <th>답글 관리</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><input type="checkbox" name="" /></td>
                        <td>
                          <div className="rating-select">
                            <select name="" className="">
                              <option value="star5" data-image="/img/icon_star5.svg"></option>
                              <option value="star4"></option>
                              <option value="star3"></option>
                              <option value="star2"></option>
                              <option value="star1"></option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="reply-content active">
                            <textarea name=""></textarea>
                          </div>
                        </td>
                        <td>
                          <div className="btn-wrap">
                            <button className="btn-reply-modify">수정</button>
                            <button className="btn-reply-delete">삭제</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="" /></td>
                        <td>
                          <div className="rating-select">
                            <select name="" className="">
                              <option value="star5"></option>
                              <option value="star4"></option>
                              <option value="star3"></option>
                              <option value="star2"></option>
                              <option value="star1"></option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="reply-content">
                            <textarea name=""></textarea>
                          </div>
                        </td>
                        <td>
                          <div className="btn-wrap">
                            <button className="btn-reply-modify">수정</button>
                            <button className="btn-reply-delete">삭제</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="" /></td>
                        <td>
                          <div className="rating-select">
                            <select name="" className="">
                              <option value="star5"></option>
                              <option value="star4"></option>
                              <option value="star3"></option>
                              <option value="star2"></option>
                              <option value="star1"></option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="reply-content">
                            <textarea name=""></textarea>
                          </div>
                        </td>
                        <td>
                          <div className="btn-wrap">
                            <button className="btn-reply-modify">수정</button>
                            <button className="btn-reply-delete">삭제</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="" /></td>
                        <td>
                          <div className="rating-select">
                            <select name="" className="">
                              <option value="star5"></option>
                              <option value="star4"></option>
                              <option value="star3"></option>
                              <option value="star2"></option>
                              <option value="star1"></option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="reply-content">
                            <textarea name=""></textarea>
                          </div>
                        </td>
                        <td>
                          <div className="btn-wrap">
                            <button className="btn-reply-modify">수정</button>
                            <button className="btn-reply-delete">삭제</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="" /></td>
                        <td>
                          <div className="rating-select">
                            <select name="" className="">
                              <option value="star5"></option>
                              <option value="star4"></option>
                              <option value="star3"></option>
                              <option value="star2"></option>
                              <option value="star1"></option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="reply-content">
                            <textarea name=""></textarea>
                          </div>
                        </td>
                        <td>
                          <div className="btn-wrap">
                            <button className="btn-reply-modify">수정</button>
                            <button className="btn-reply-delete">삭제</button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="btn-save">
                    <input type="submit" value="저장" className="save-button" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          :
          <p className='empty'>편집할 질문지 템플릿을 선택하시거나 '새로만들기' 버튼을 클릭하세요.</p>
        }



      </div>
    )
  }
}

export default ReviewMng;
