import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";
import ReactDom from 'react-dom';
import './inAppOrderList.scss'
import moment from "moment";

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

//import images
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Button, FormInput, FormSelect, InputGroup, InputGroupAddon} from "shards-react";



let model = {
  store: {
    name: '',
    domain: '',
    logo: '',
    platform: '',
    platformId: '',
    addrMain: '',
    addrSub: '',
    presidentName: '',
    tel: '',
    email: '',
    notificationEmail: '',
    customerService: {},
    privacyOfficer: {},
    pTeamUser_id: ''
  }
}

// model.store = {
//   name: '온노마드',
//   domain: 'http://onnomad.co.kr/',
//   logo: '',
//   platform: 'nhn_godo',
//   platformId: '',
//   addrMain: '서울시 성동구 상원1길 25 ',
//   addrSub: '4160호 (주)온노마드',
//   presidentName: '윤정진',
//   tel: '02-6417-7000',
//   email: '',
//   notificationEmail: 'godrium@naver.com',
//   customerService: {},
//   privacyOfficer: {},
//   pTeamUser_id: ''
// }

@inject(({ common }) => ({ setIsShowPopup: common.setIsShowPopup, isShowModal: common.isShowModal, getIsShowPopup: common.getIsShowPopup  }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, getInAppOrderList: teamUser.getInAppOrderList }))
@inject(({ company }) => ({ updateStore:company.updateStore  }))

@observer
class InAppOrderList extends Component {

  //basic
  @observable store = {};

  @observable teamUser = {};
  @observable permissions = {}
  // @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  @observable listParams = {page:1, limit:20};
  @observable boardList = [];
  @observable isPopupOpen = false;

  constructor(props) {
    super(props);
    this.init()

    // this.onChangeValue = this.onChangeValue.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.delTeamUser = this.delTeamUser.bind(this);
    // this.updateStore = this.updateStore.bind(this);
    // this.openPostCode = this.openPostCode.bind(this);
    // this.closePostCode = this.closePostCode.bind(this);
    // this.setAddress = this.setAddress.bind(this);
    // this.getTeamUserList = this.getTeamUserList.bind(this);
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : model.store;
      this.listParams.store_id = this.store._id;


      this.permissions = this.props.me.permissions;
      if(this.teamUser.isSuper && this.listParams.store_id) {
        this.getList();
      }
    }
    else {

      if(Storage.get('token')) {
        //todo 재확인 - 로그인 후 해당 페이지로 넘어 오는 경우 authCheck() 함수 미샐행 할 수 있게.
        //this.props.authCheck();
      }
      // else {
      //   window.location.replace('signIn');
      // }

    }
  };

  @action
  getList =async() => {
    this.boardList = await this.props.getInAppOrderList(this.listParams);
  };

  render() {

    function PaySuccess(props) {
      let statusStr = '';
      let paySuccess = props.paySuccess;
      switch (paySuccess){
        case 'fail' :
          statusStr = '결제실패';
          break;
        case 'ing' :
          statusStr = '결제중';
          break;
        case 'refund' :
          statusStr = '환불완료';
          break;
        case 'success' :
          statusStr = '결제완료';
          break;
        default :
          statusStr = paySuccess;
          break;
      }
      return statusStr;
    }

    return (
      <div className="main-content">
        <PageTitle />


        <div className="section-box basic-info-table">
          {/*<div className="section-tit">*/}
          {/*  <h3>결제 내역</h3>*/}
          {/*</div>*/}
          <table>
            <thead>
            <tr>
              <th>결제 상품명</th>
              <th>결제 금액</th>
              <th>사용 기간</th>
              <th>다음 결제일</th>
              <th>결제 여부</th>
              <th>기타</th>
            </tr>
            </thead>
            <tbody>
            {this.boardList && this.boardList.docs && this.boardList.docs.length > 0 ?
                this.boardList.docs.map((item, i) => (
                    <tr key={i}>
                      <td>{item.orderName}</td>
                      <td>{item.orderAmount}</td>
                      <td>{moment(item.startDate).format('YYYY.MM.DD')} ~ {moment(item.endDate).format('YYYY.MM.DD')}</td>
                      <td>{moment(item.endDate).add(1, 'days').format('YYYY.MM.DD')} {item.automaticPayment && item.automaticPayment === 'T' ? '자동결제' : null}</td>
                      <td><PaySuccess paySuccess={item.paySuccess} /></td>
                      <td>{item.payType}</td>
                    </tr>
                ))
                : (
                    <tr>
                      <td colSpan="6" className="text-center mt-auto">결제내역이 없습니다.</td>
                    </tr>)
            }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default InAppOrderList;
