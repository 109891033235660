import React, {Component} from 'react';
import axios from "axios/index";
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Link} from "react-router-dom";
import _ from "underscore";
import moment from "moment/moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.scss'
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup, Button, Col, Row
} from "shards-react";

//https://github.com/wojtekmaj/react-calendar
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { PointCalculator } from "../../../utils/pointCalculator.js";
import ModalLoading from "../../../components/common/ModalLoading";

// 적립금 (포인트) 은 바로 현금처럼 사용할수 있기 때문에 해당API를 이용하려면 카페24에서 귀사에 WRITE_MILEAGE 권한 (scope) 을 추가해야 이용 가능합니다.
//     귀사에 적용 완료해 드렸습니다.
//
//     추후 앱스토어에 앱을 출시해서 적립금 (Create a points) API를 사용하려면 필수로 아래의 프로세스 (관리자 툴) 가 앱서비스에 반드시 포함돼 있어야합니다. (앱스토어 심사시 체크)
//
// 1) 운영자가 원할 경우 적립금을 회수할 수 있어야 한다 (운영자 승인 기능 필수)
// ㅁ 쇼핑몰고객이 프론트에서 적립금을 얻을경우 (승인전) 쇼핑몰 운영자가 확인 (승인) 해야 적립금이 지급 가능도록 해야된다
// ㅁ 쇼핑몰고객이 프론트에서 적립금을 얻은후 (즉시 적립) 쇼핑몰 운영자는 적립금을 차감 (취소) 할 수 있어야 한다
//
// 2) 운영자가 원할 경우 적립금 지급 기능을 중지할 수 있어야 한다 (On/Off 기능 필수)

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ common }) => ({ getDoc: common.getDoc }))
@inject(({ review }) => ({ getReviewList:review.getReviewList, setReviewSync: review.setReviewSync,
  updateReviewDisplayStatus: review.updateReviewDisplayStatus, updateReviewSome: review.updateReviewSome,
  updateReviewComment: review.updateReviewComment, getReviewHistoryList:review.getReviewHistoryList,
  getReviewPointInfo:review.getReviewPointInfo, updatePoint:review.updatePoint, updatePayPoint: review.updatePayPoint, adminDelReview:review.adminDelReview, updateUserOrderByCafe24:review.updateUserOrderByCafe24 }))
@inject(({ product }) => ({ setProductSync: product.setProductSync }))
@inject(({ myWidget }) => ({ getWidgetList:myWidget.getWidgetList, getWidgetInReviewList:myWidget.getWidgetInReviewList, updateWidgetInReview: myWidget.updateWidgetInReview, updateWidgetInReviewOrder: myWidget.updateWidgetInReviewOrder, removeWidgetInReview: myWidget.removeWidgetInReview,  }))

@inject(({ AOA }) => ({ initReviewData: AOA.initReviewData }))



@observer
class ReviewList extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //page
  @observable isShowDetailSearch = false;
  @observable isWidgetMode = false;
  @observable searchFormValue = {
    searchType: 'all',
    keyword: '',
    startDate: '',
    endDate: '',
    star: {
      all: true,
      star5: true,
      star4: true,
      star3: true,
      star2: true,
      star1: true,
    },
    writer: {
      all: true,
      member: true,
      admin: true,
    },
    reviewType: {
      all: true,
      text: true,
      image: true,
      // movie: true,
      // survey: true,
    },
    reviewDis: {
      all: true,
      on: true,
      will: true,
      pending: true,
    },
    isDelete: false,
    payPoint : {
      all: true,
      will: true,
      waiting: true,
      // noMemberWaiting: false,
      payed: true,
      cancel: true,
      limit: true,
      noPoint: true,
      recover: true,
      recoverFail: true,
      warning: true,
    },
    widgetDis : {
      all: true,
      on: true,
      no: true,
    },
    fix: {
      all: true,
      top: true,
      bottom: true
    }
  };
  @observable myWidgets = {};
  @observable addpoint = {
    type: 'increase',
    reason: '',
    pointAmout: '',
  };
  @observable reviewpointConfig;

  @observable isShowCalendar = {
    startDate: false,
    endDate: false
  }

  //modals
  @observable modals = {
    ispoint: false,
    isHistory: false
  };

  //tooltip
  @observable isReviewHistoryTip = false;
  @observable ispointHistoryTip = false;

  @observable listParams = {
    page:1, limit:20,
    displayStatus: 100,
    searchParams: {
      displayAt: 'all'
    }
  }

  @observable list = {};
  @observable refProducts = {};

  @observable curReview = {
    pointInfo : {}
  };
  @observable reviewHistoryListParams = {page:1, limit:5};
  @observable reviewPointInfParams = {}
  @observable curReviewHistoryList = [];
  @observable curReviewPointInfo = {};

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.onChangeCalendarValue = this.onChangeCalendarValue.bind(this);
    //this.removeWidgetInReview = this.removeWidgetInReview.bind(this);
  }
  componentDidUpdate(prevProps, prevState){ }

  @action
  init = async (key) => {

    let _this = this;
    if(this.props.isLogin) {

      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.permissions = this.props.me.permissions;

      //page
      this.props.getDoc('getReviewPointConfig', { store_id:this.teamUser.selStore_id }, function(res) {
        //todo 포인트 설정이 안되어 있을 경우 경고창과 함께 설정 페이지로 이동
        if(res.data._id) {
          _this.reviewpointConfig = res.data;
        }
        else {
          alert('포인트 지급 설정 후 사용이 가능합니다.');
          _this.props.history.push('/reviewPointConfig');
        }
      });
      this.listParams.store_id = this.teamUser.selStore_id;
      this.urlParamsToListParams();
    }
  };

  urlParamsToListParams = function() {

    if(this.urlParams.get('myWidget_id')) {
      this.listParams.myWidget_id = this.urlParams.get('myWidget_id');
      this.searchFormValue['myWidget_id'] = this.listParams.myWidget_id;
      this.isWidgetMode = true;
    }
    else {
      this.isWidgetMode = false;
      let searchFormValueArr = {};
      for (const key of this.urlParams.keys()) {

        if(['page'].indexOf(key) > -1 || ['limit'].indexOf(key) > -1) {
          this.listParams[key] = parseInt(this.urlParams.get(key));
        }
        else {
          if(key.indexOf('__') > -1) {
            let keyArr = key.split('__');
            if(!searchFormValueArr[keyArr[0]]) { searchFormValueArr[keyArr[0]] = {};}
            searchFormValueArr[keyArr[0]][keyArr[1]] = true;
          }
          else {
            this.searchFormValue[key] = this.urlParams.get(key);
            this.listParams.searchParams[key] = this.urlParams.get(key);
          }
        }
      }

      for(let key in searchFormValueArr) {
        if(searchFormValueArr[key].all) {
          for(let deKey in this.searchFormValue[key]) {
            this.searchFormValue[key][deKey] = true;
          }
        }
        else {
          for(let deKey in this.searchFormValue[key]) {
            if(searchFormValueArr[key][deKey]) {
              this.searchFormValue[key][deKey] = true;
            }
            else {
              this.searchFormValue[key][deKey] = false;
            }
          }
        }

        this.listParams.searchParams[key] = searchFormValueArr[key];
    }

    }
    this.getReviewList();
  }


  //ui fn
  doSearch = async() => {
    let urlParams = new URLSearchParams();
    if(this.searchFormValue['myWidget_id']) {
      urlParams.set('myWidget_id', this.searchFormValue['myWidget_id']);
    }
    else {
      for(let key in this.searchFormValue) {
        if(key != 'store_id' && key != 'teamUserId' && key != 'limit' && this.searchFormValue[key]) {
          if(typeof this.searchFormValue[key] == 'object') {
            if(!this.searchFormValue[key]['all']) {
              for(let depthKey in this.searchFormValue[key]) {
                if(this.searchFormValue[key][depthKey]) {
                  urlParams.set(key + '__' +depthKey, this.searchFormValue[key][depthKey]);
                }
              }
            }
            else {
              urlParams.set(key + '__all', this.searchFormValue[key]['all']);
            }

          }
          else {
            urlParams.set(key, this.searchFormValue[key]);
          }
        }
      }
    }
    window.location.search = urlParams.toString();
  };

  onChangeSearchValue = async (event, obj) => {
    const { name, value, type } = event.target;
    console.log(name, value, type);
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.searchFormValue[name] = newValue;
      if(name == 'isDelete') {
        for(let key in this.searchFormValue.reviewDis) {
          this.searchFormValue.reviewDis[key] = !newValue;
        }
      }
    }

  };

  onChangeCalendarValue = async (date, dateType) => {
    this.isShowCalendar = {
      startDate: false,
      endDate: false,
    };
    this.searchFormValue[dateType] = moment(date).format('yyyy-MM-DD');
  };

  onChangeValue =  async (event, targetObj) => {
    const { name, value } = event.target;
    targetObj[name] = value;
  };

  onChangeValueAndUpdate = async (type, event, item) => {

    const { name, value } = event.target;
    if(!value || value == '') {
      return false;
    }
    let params = {};

    let result;
    if(type === 'myWidget_id') {
      let updateMyWidgetId = value;
      params = {
        review_id: item._id,
        myWidget_id: value,
      };

      if(item.myWidget_ids && item.myWidget_ids.indexOf(params.myWidget_id) > -1) {
        // todo 이미 해당 리뷰의 위젯이 추가된 상황
        toast('이미 위젯이 추가 되었있습니다.');
        // params.reviewWidgetRef_ids = item.reviewWidgetRef_ids;
        // //todo reviewWidgetRef 해당 값 가져오기
        // params.review_ids = [item._id]
      }
      else {
        this.props.updateWidgetInReview(params, function(res) {
          if(!item.myWidget_ids) {
            item.myWidget_ids = [];
          }
          item.myWidget_ids.push(params.myWidget_id);
        });
      }
    }
    else if(type == 'payPointStatus') {
      if(item.userOrder_id && item.userOrder_id._id) {
        params = {
          review_id: item._id,
          userOrder_id: item.userOrder_id._id
        };
        params[name] = parseInt(value);
        result = await this.props.updatePayPoint(params, function(res) {

        });
      }
      else {
        toast('해당 리뷰는 포인트 처리가 불가능합니다.');
      }

    }
    else {
      params = {
        id: item._id,
        preStatus: item.displayStatus
      };
      params[name] = parseInt(value);
      alert('게시 상태변경에 따른 포인트 지불 또는 회수 처리는 수동으로 처리 하셔야 합니다.');
      result = await this.props.updateReviewDisplayStatus(params);
      console.log(result)
      toast('게시 상태가 변경되었습니다.');
      item[name] = params[name];
    }
  };

  removeWidgetInReview = async(item, myWidget_id, indexObj) => {
    let _this = this;
    let params = {
      myWidget_id: myWidget_id,
      review_id: item._id
    };

    this.props.removeWidgetInReview(params, function(res) {
      if(_this.searchFormValue.myWidget_id && _this.searchFormValue.myWidget_id === myWidget_id) {
        _this.list.docs.splice(indexObj.review, 1);
      }
      else {
        item.myWidget_ids.splice(indexObj.myWidget, 1);
      }
    });

  };

  updatePayPointStatus = async function(item, payPointStatus) {

    let pointInfo = PointCalculator.total(item, this.reviewpointConfig);
    console.log(pointInfo)

    // if(item.userOrder_id && item.userOrder_id._id) {
    //   let params = {
    //     review_id: item._id,
    //     userOrder_id: item.userOrder_id._id,
    //     payPointStatus: payPointStatus
    //   };
    //   await this.props.updatePayPoint(params, function(res) {
    //     console.log(res);
    //   });
    // }
    // else {
    //   alert('해당 리뷰는 포인트 처리가 불가능합니다.');
    // }
  };



  modalToggle = function(target, data) {
    // this.modals[target] = !this.modals[target];
    // if(!target) {
    //   for( var i in this.modals) {
    //     this.modals[i] = false;
    //   }
    // }
    if(data) {
      this.modals.data = data;
    }

  };

  toggleTip = async (target) => {
    this[target] = !this[target];
  }

  //page fn
  getWidgetList = async() => {
    if(this.listParams.store_id) {
      let params = {page:1, limit:20, store_id:this.listParams.store_id, selecting:'specify'}
      let widgetList = await this.props.getWidgetList(params);
      this.myWidgets = _.indexBy(widgetList.docs, '_id');
      //console.log(Object.keys(this.myWidgets).length);
    }
    else {
      //console.log('null store_id');
    }
  };

  getReviewList = async() => {
    if(this.listParams.store_id) {
      this.getWidgetList();
      // this.listParams.searchParams = {};
      // for(let key in this.searchFormValue) {
      //   if(key != 'store_id' && key != 'teamUserId' && key != 'limit' && this.searchFormValue[key]) {
      //     if(typeof this.searchFormValue[key] == 'object') {
      //       for(let depthKey in this.searchFormValue[key]) {
      //         if(this.searchFormValue[key][depthKey] && depthKey != 'all') {
      //           this.listParams.searchParams[depthKey] = this.searchFormValue[key][depthKey];
      //         }
      //       }
      //     }
      //     else {
      //       this.listParams.searchParams[key] = this.searchFormValue[key];
      //     }
      //   }
      // }
      //this.listParams.searchParams = this.searchFormValue;

      if(this.isWidgetMode && false) {
        let res = await this.props.getWidgetInReviewList(this.listParams);
        _.map(res.data.review_ids, function(row) {
          row.product = row.product_id;
          return row;
        });
        this.list.docs = res.data.review_ids;
      }
      else {
        let res = await this.props.getReviewList(this.listParams);
        _.map(res.docs, function(row) {
          row.product = row.product_id;
          return row;
        });
        this.list = res;
      }
      // if(this.list && this.list.refProducts) {
      //   this.refProducts = _.indexBy(this.list.refProducts, 'platformProductId');
      // }
      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };


  // getBonusPoint = async(curReview) => {
  //
  //   let pointBonusSettings = this.reviewpointConfig.pointBonusSettings;
  //
  //   let bonuspoint = {};
  //   //회원최초
  //   bonuspoint.joinFirst = 0;
  //   if((pointBonusSettings.joinFirst && pointBonusSettings.joinFirst.isUse) && curReview.writerReviewNo == 0) {
  //     bonuspoint.joinFirst = pointBonusSettings.joinFirst.point_1;
  //   }
  //   //선착순
  //   bonuspoint.fsb = 0;
  //   if((pointBonusSettings.fsb && pointBonusSettings.fsb.isUse) && curReview.reviewNo <= pointBonusSettings.fsb.range_1) {
  //     bonuspoint.fsb = pointBonusSettings.fsb.point_1;
  //   }
  //   //이미지 수
  //   bonuspoint.perImg = 0;
  //   if((pointBonusSettings.perImg && pointBonusSettings.perImg.isUse) && curReview.images.length >= pointBonusSettings.perImg.range_1) {
  //     bonuspoint.perImg = pointBonusSettings.perImg.point_1;
  //   }
  //   //글 수
  //   bonuspoint.perText = 0;
  //   if((pointBonusSettings.perText && pointBonusSettings.perText.isUse) && curReview.contents.length >= pointBonusSettings.perText.range_1) {
  //     bonuspoint.perText = pointBonusSettings.perText.point_1;
  //   }
  //   //조기 작성
  //   bonuspoint.early = 0;
  //   if((pointBonusSettings.early && pointBonusSettings.early.isUse)) {
  //     bonuspoint.early = pointBonusSettings.early.point_1;
  //   }
  //
  //   //하나만 지급
  //   let bonusOnlyOne = {
  //     point: 0,
  //   }
  //   console.log(bonuspoint)
  //   if(pointBonusSettings.isOnlyOnce) {
  //     for(let key in bonuspoint) {
  //       if(bonusOnlyOne.point < bonuspoint[key] || !bonusOnlyOne.key) {
  //         bonusOnlyOne.key = key;
  //         bonusOnlyOne.point = bonuspoint[key]
  //       }
  //     }
  //   }
  //   bonuspoint.bonusOnlyOne = bonusOnlyOne;
  //
  //
  //   return bonuspoint;
  //
  //   // <td>{moment(this.curReview.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
  //
  // };


  showReviewHistory = async(item) => {
    let _this = this;
    this.reviewHistoryListParams.review_id = item._id;
    item.pointInfo = PointCalculator.total(item, _this.reviewpointConfig);
    this.curReview = item;

    this.reviewPointInfParams = {
      review_id : item._id,
      //userOrder_id: item.userOrder_id._id
    };
    this.props.getReviewHistoryList(this.reviewPointInfParams, function(res) {
      _this.curReviewHistoryList = res;
      _this.modals.isHistory = true;
    });


  };

  showReviewPoint = async(item) => {
    let _this = this;
    //console.log(this.reviewpointConfig.pointBonusSettings);
    _this.addpoint = {
      type: 'increase',
      reason: '',
      pointAmout: '',
    };
    this.reviewHistoryListParams.review_id = item._id;
    this.reviewHistoryListParams.type = 'point';
    if(!item.userOrder_id || !item.userOrder_id._id) {
      return false;
    }
    else {
      this.reviewPointInfParams = {
        review_id : item._id,
        userOrder_id: item.userOrder_id._id
      };

      this.curReviewHistoryList = await this.props.getReviewHistoryList(this.reviewHistoryListParams);
      this.curReviewPointInfo = await this.props.getReviewPointInfo(this.reviewPointInfParams);
      this.curReviewPointInfo.payPointSum = 0;
      for(var i in this.curReviewPointInfo.userPointSum) {
        var row = this.curReviewPointInfo.userPointSum[i];
        if(row._id == 'increase') {
          this.curReviewPointInfo.payPointSum += row.pointAmout;
        }
        else {
          this.curReviewPointInfo.payPointSum -= row.pointAmout;
        }
      }
      // console.log(this.curReviewPointInfo, this.curReviewPointInfo.userPointHistory)

      item.pointInfo = PointCalculator.total(item, _this.reviewpointConfig);
      console.log(item.pointInfo)
      this.curReview = item;
      _this.modals.ispoint = true;
    }

  };



  setReviewSync = async (event) => {
    let _this = this;
    let confimRes = window.confirm("기존 온리뷰의 리뷰는 모두 삭제되고 카페24 리뷰로 대체 됩니다. 진행하시겠습니까?");
    if(confimRes) {
      _this.isLoading = true;
      let data = {
        store_id: _this.store._id
      }

      _this.props.setReviewSync(data, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        _this.getReviewList();
        _this.isLoading = false;
      });
    }

  };

  reviewContentModifyToggle = (item) => {

    if(!item.isEdit) {
      item.orgContents = item.contents;
      item.isEdit = true;
    }
    else {
      item.contents = item.orgContents;
      item.isEdit = false;
    }

  };

  onChangeWidgetOrder = (item, i, action) => {
    let selIndex = i;
    let relIndex;
    let selItem;
    let refItem;
    if(i > 0 && action === 'up') {
      relIndex = selIndex-1;
    }
    else if(i < this.list.docs.length-1 && action === 'down') {
      relIndex = selIndex+1;
    }
    selItem = this.list.docs[selIndex];
    refItem = this.list.docs[relIndex];
    this.list.docs.splice(selIndex, 1);
    this.list.docs.splice(relIndex, 0, selItem);

    let updateReviewOrder = _.pluck(this.list.docs, '_id');
    let params = {
      widget_id: this.searchFormValue.myWidget_id,
      updateReviewOrder: updateReviewOrder
    }
    this.props.updateWidgetInReviewOrder(params, function(res) {
      console.log(res);
    });
    console.log(updateReviewOrder)




  }

  updateReviewContent = async (item) => {
    let result = await this.props.updateReviewSome({ id:item._id, contents:item.contents });
    item.isEdit = false;
  };

  // updateReview = async (params, id) => {
  //   let _this = this;
  //   if(id) {
  //     params._id = id;
  //   }
  //   let result = await this.props.updateReview(params);
  // };



  updateComment = async (item) => {
    let _this = this;

    let result = await this.props.updateReviewComment({id:item._id, comment:{comment: item.comment.content}});
    if(result.e) {
      let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
      if(cf) {
        window.location.href = '/reviewWriteConfig';
      }
    }
  };

  updatePoint = async (pointInfo, currentReview) => {
    let _this = this;

    if(!pointInfo.pointAmout) {
      alert('추가 또는 차감할 포인트를 입력해 주세요.');
    }
    else if(!pointInfo.reason) {
      alert('추가 또는 차감할 포인트를 입력해 주세요.');
    }
    else{
      pointInfo.pointAmout = parseInt(pointInfo.pointAmout);
      pointInfo.store_id = _this.teamUser.selStore_id;
      pointInfo.review_id = currentReview._id;
      pointInfo.userOrder_id = currentReview.userOrder_id._id;
      //type - increase, decrease
      let result = await this.props.updatePoint(pointInfo);
      let alertMsg = result.data.reason + ' - 포인트 '+result.data.pointAmout;
      if(result.data.type == 'increase') {
        alertMsg += ' 지급 처리 완료';
      }
      else {
        alertMsg += ' 차감 처리 완료';
      }
      toast(alertMsg);
      this.showReviewPoint(this.curReview);
    }


    // if(pointInfo.pointAmout > 0 && pointInfo.reason && pointInfo.reason.length > 0) {
    //
    // }
    // else {
    //
    // }
  };

  updateUserOrderByCafe24 = async (item) => {
    console.log(item.store_id, item.platformOrderId)
    if(item.platformOrderId) {
      let params = {
        store_id: item.store_id,
        platformOrderId: item.platformOrderId
      };
      this.props.updateUserOrderByCafe24(params, function(res) {
        console.log(res);
        toast('주문 정보.');
      });
    }
    else {
      toast('주문 정보를 업데이트 할 수 없는 리뷰입니다.');
    }

  }

  updateReviewInfo = async (item) => {
    //todo

  }

  delReview = async (item) => {
    let _this = this;
    let message = '리뷰 삭제시 지급한 포인트 자동 회수 됩니다. (포인트 미회수로 처리 하시려면 게시상태를 숨김으로 처리해 주세요). 삭제 후 복구 불가능합니다. 삭제 진행 하시겠습니까?';
    if(item.isAdmin) {
      message = '리뷰를 정말 삭제 하시겠습니까?'
    }
    let result = window.confirm(message);
    if(result) {
      let params = {
        review_id: item._id,
        store_id:item.store_id
      };
      this.props.adminDelReview(params, function(res) {
        console.log(res);
        toast('리뷰가 삭제되었습니다.');
        _this.getReviewList();
      });
    }

  }

  openProductPage = async (item) => {
    let url = 'http://' + this.store.domain;
    if(this.store.platform == 'cafe24') {
      url += '/product/_/' + item.platformProductId;

    }
    else {

    }
    window.open(url);

  };


  render() {
    let _this = this;

    function Pagination() {
      let result = [];
      //let limit = _this.list.limit;
      let totalPages, curPageNum, nextPage, prevPage, startNum, endNum;
      if (_this.list && _this.list.totalPages) {
        totalPages = _this.list.totalPages;
        curPageNum = _this.list.page;
        nextPage = _this.list.nextPage;
        prevPage = _this.list.prevPage;
        startNum = (parseInt((curPageNum - 1) / 10) * 10) + 1;
        endNum = startNum + 9 < totalPages ? startNum + 9 : totalPages;
      }
      let rendering = () => {
        for (let i = startNum; i <= endNum; i++) {
          if (i == curPageNum) {
            result.push(<li key={i} className="on"><a href={"?page=" + i}>{i}</a></li>);
          } else {
            result.push(<li key={i}><a href={"?page=" + i}>{i}</a></li>);
          }
        }
        return result;
      }
      if (_this.list && _this.list.totalPages) {
        return (
          <div className="pagination">
            <ul>
              {curPageNum != 1 ?
                <li className="btn-page-first"><a href="?page=1"><FeatherIcon icon="chevrons-left" className="nd-icon"/></a></li> : null}
              {startNum - 1 > 0 ?
                <li className="btn-page-prev"><a href={'?page=' + (startNum - 1)}><FeatherIcon icon="chevron-left" className="nd-icon"/></a></li> : null}
              {rendering()}
              {endNum != totalPages ?
                <li className="btn-page-next"><a href={'?page=' + (startNum + 10)}><FeatherIcon icon="chevron-right" className="nd-icon"/></a></li> : null}
              {curPageNum != totalPages ?
                <li className="btn-page-last"><a href={'?page=' + totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon"/></a></li> : null}
            </ul>
          </div>);
      } else {
        return (<></>);
      }
    }

    function ReviewStar(props) {
      let goodsPt = props.goodsPt;
      let startClassName = 'review-star star0';
      if(-1 < goodsPt && goodsPt <= 20) {
        startClassName += '1';
      }
      else if(20 < goodsPt && goodsPt <= 40) {
        startClassName += '2';
      }
      else if(40 < goodsPt && goodsPt <= 60) {
        startClassName += '3';
      }
      else if(60 < goodsPt && goodsPt <= 80) {
        startClassName += '4';
      }
      else if(80 < goodsPt && goodsPt <= 100) {
        startClassName += '5';
      }
      return (<div className={startClassName}><span></span></div>);
    }

    function getDisplayStatusStr(displayStatus) {
      let result;
      switch (displayStatus){
        case 2 :
          result = '게시 중';
          break;
        case 1 :
          result = '게시 대기';
          break;
        case 90 :
          result = '숨김';
          break;
        default :
          result = displayStatus;
          break;
      }
      return result;
    }

    function ReviewHistoryTd(props) {
      let item = props.item;
      let type, content;
      switch (item.type) {
        case 'displayStatus' :
          type = '게시상태';
          content = getDisplayStatusStr(item.addDate.preStatus) + ' -> ' + getDisplayStatusStr(item.addDate.displayStatus);
          break;
        case 'del' :
          type = '삭제';
          content = '리뷰 삭제';
          break;
        case 'some' :
          type = '리뷰수정';
          content = item.addDate.contents;
          break;
        default :
          break;
      }
      //result.replace("{type}", type).replace("{content}", content).replace("{memo}", item.memo);

      return (<><td>{type}</td><td>{content}</td><td>{item.memo}</td></>);
    }

    function Unpaidpoint(props) {
      let payPointStatus = props.payPointStatus;
      let isMember = _this.curReview.isMember;
      let isGuest = _this.curReview.isGuest;
      let pointSettings;
      // image, instagram, movie, text
      if(isMember && !isGuest) {
        pointSettings = _this.reviewpointConfig.pointSettings.member;
      }
      else {
        pointSettings = _this.reviewpointConfig.pointSettings.noMember;
      }
      let pointBonusSettings = _this.reviewpointConfig.pointBonusSettings;
      // console.log('payPointStatus', payPointStatus);
      // console.log('pointSettings', pointSettings);
      // console.log('pointBonusSettings', pointBonusSettings);
      // console.log('curReview', _this.curReview)



      _this.curReview.reviewType = _this.curReview.reviewType ? _this.curReview.reviewType : 'text';
      let point = 0;
      switch (payPointStatus){
        case '1' :
          point = pointSettings[_this.curReview.reviewType] ? pointSettings[_this.curReview.reviewType] : 0;
          break;
        case '2' :
          point = 0;
          break;
        case '90' :
          point = 0;
          break;
        default :
          break;
      }
      _this.curReview.Unpaidpoint = point;
      return point;
    }

    // function DisplayStatus(props) {
    //   let statusStr = '';
    //   switch (props.displayStatus){
    //     case '2' :
    //       statusStr = '게시 중';
    //       break;
    //     case '1' :
    //       statusStr = '게시 대기';
    //       break;
    //     case '90' :
    //       statusStr = '숨김';
    //       break;
    //     default :
    //       break;
    //   }
    //   return (<div className="posting-state">게시상태 : <span className="posting-state-txt">{statusStr}</span></div>)
    // }

    function DisplayStatus(props) {
      let statusStr = '';
      let displayStatus = props.displayStatus ? parseInt(props.displayStatus) : null;
      switch (displayStatus){
        case 2 :
          statusStr = '게시 중';
          break;
        case 1 :
          statusStr = '게시 대기';
          break;
        case 90 :
          statusStr = '숨김';
          break;
        default :
          statusStr = displayStatus;
          break;
      }
      return (<div className="posting-state">게시 : <span className="posting-state-txt">{statusStr}</span></div>)
    }

    function PayPointStatus(props) {
      let statusStr = '';
      let bonusStatusStr = '';
      let payPointStatus = props.payPointStatus ? parseInt(props.payPointStatus) : null;
      let payBonusPointStatus = props.payBonusPointStatus ? parseInt(props.payBonusPointStatus) : null;

      //1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패
      switch (payPointStatus){
        case 1 :
          statusStr = '지급예정';
          break;
        case 2 :
          statusStr = '지급대기';
          break;
        case 3 :
          statusStr = '비회원 지급대기';
          break;
        case 4 :
          statusStr = '지급완료';
          // props.item.pointInfo = PointCalculator.total(props.item, _this.reviewpointConfig);
          // console.log(props.item.pointInfo)
          break;
        case 90 :
          statusStr = '지급취소';
          // props.item.pointInfo = PointCalculator.total(props.item, _this.reviewpointConfig);
          // console.log(props.item.pointInfo)
          break;
        case 92 :
          statusStr = '지급제한';
          break;
        case 81 :
          statusStr = '회수완료';
          break;
        case 82 :
          statusStr = '회수실패';
          break;
        case 93 :
          statusStr = '지급포인트없음';
          break;
        case 95 :
          statusStr = '쇼핑몰 관리자에서 확인';
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = payPointStatus;
          break;
      }

      switch (payBonusPointStatus){
        case 1 :
          bonusStatusStr = '지급예정';
          break;
        case 2 :
          bonusStatusStr = '지급대기';
          break;
        case 3 :
          bonusStatusStr = '비회원 지급대기';
          break;
        case 4 :
          bonusStatusStr = '지급완료';
          // props.item.pointInfo = PointCalculator.total(props.item, _this.reviewpointConfig);
          // console.log(props.item.pointInfo)
          break;
        case 90 :
          bonusStatusStr = '지급취소';
          // props.item.pointInfo = PointCalculator.total(props.item, _this.reviewpointConfig);
          // console.log(props.item.pointInfo)
          break;
        case 92 :
          bonusStatusStr = '지급제한';
          break;
        case 81 :
          bonusStatusStr = '회수완료';
          break;
        case 82 :
          bonusStatusStr = '회수실패';
          break;
        case 93 :
          bonusStatusStr = '지급포인트없음';
          break;
        case 95 :
          bonusStatusStr = '쇼핑몰 관리자에서 확인';
          break;
        case 99 :
          bonusStatusStr = '회수실패';
          break;
        case 98 :
          bonusStatusStr = '지급경고';
          break;
        default :
          bonusStatusStr = payBonusPointStatus ? '('+payBonusPointStatus+')' : '';
          break;
      }
      if(bonusStatusStr && bonusStatusStr != '') {
        bonusStatusStr = ' / (보너스)' + bonusStatusStr;
      }
      return (<div className="benefits-state">포인트 : <span className="benefits-state-txt">{statusStr}{bonusStatusStr}</span></div>)
    }

    function PayPointStatusRow(props) {
      let statusStr = '';
      let statusDateStr = '-';
      switch (props.payPointStatus){
        case 1 :
          statusStr = '지급예정';
          break;
        case 11 :
          statusStr = '지급대기';
          break;
        case 12 :
          statusStr = '비회원 지급대기';
          break;
        case 2 :
          statusStr = '지급완료';
          break;
        case 9 :
          statusStr = '지급취소';
          break;
        case 90 :
          statusStr = '지급포인트없음';
          break;
        case 91 :
          statusStr = '지급제한';
          break;
        case 92 :
          statusStr = '회수완료';
          break;
        case 93 :
          statusStr = '회수완료';
          break;
        case 95 :
          statusStr = '쇼핑몰 관리자에서 확인 요망';
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = props.payPointStatus;
          break;
      }
      return (<span className="review-state-time">{statusStr}</span>)
    }

    function OrderStatus(props) {
      let statusStr = '';
      //1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실
      switch (props.orderStatus){
        case 0 :
          statusStr = '입금대기';
          break;
        case 2 :
          statusStr = '지급대기';
          break;
        case 3 :
          statusStr = '비회원 지급대기';
          break;
        case 4 :
          statusStr = '지급완료';
          break;
        case 90 :
          statusStr = '지급취소';
          break;
        case 93 :
          statusStr = '지급포인트없음';
          break;
        case 91 :
          statusStr = '지급제한';
          break;
        case 81 :
          statusStr = '회수완료';
          break;
        case 95 :
          //쇼핑몰에서 작성된 리뷰
          return false;
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = props.orderStatus;
          break;
      }
      return (<span className="review-state">{statusStr}</span>)
    }

    function ProductTag(props) {
      let href = '//' + _this.store.domain;
      let product = props.product;
      if(_this.store.platform == 'cafe24') {
        href += '/product/' + product.productName + '/' + product.platformProductId;
      }
      else {

      }
      return (<a target="_blank" href={href}>{product ? product.productName : null }</a>)
    }

    return (
      <div className="main-content">
        <PageTitle />
        <Row className="mtb-10 text-right">
          <Col>
            {/*<Button size="sm" onClick={event=> this.setReviewSync()}>리뷰 전부 삭제 후 카페24 리뷰 가져오기</Button>*/}
            {/*{this.teamUser.emailId == "admin@onnomads.com" ? (<Button className="ml-2" size="sm" onClick={event=> this.initReviewData()}>리뷰데이터 리셋</Button>) : null}*/}
          </Col>
        </Row>
        <div className="review-search-box">
          <div className="section-box">
            <div className="search-input-box">
              <span>위젯</span>
              <FormSelect name="myWidget_id" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.myWidget_id} className="search-type">
                <option value="">-위젯 선택하기-</option>
                {this.myWidgets  ?
                    Object.keys(this.myWidgets).map((key, i) => (
                        <option key={i} value={key}>{this.myWidgets[key].title}</option>
                    ))
                    : null
                }
              </FormSelect>
              {this.myWidgets && Object.keys(this.myWidgets).length > 0 ? ( <span className="link-widget"><Link to="/myWidgetMng">위젯 관리하기</Link></span> ) : ( <span className="link-widget"><Link to="/myWidgetMng">위젯 생성하기</Link></span> ) }
            </div>
            <div className="search-input-box">
              <span>검색</span>
              <FormSelect disabled={this.isWidgetMode} name="searchType" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.searchType} className="search-type">
                <option value="all">전체</option>
                <option value="review">리뷰</option>
                <option value="goods">상품</option>
                <option value="name">주문자이름</option>
                <option value="id">아이디</option>
                <option value="phone">전화번호</option>
              </FormSelect>
              <FormInput disabled={this.isWidgetMode} name="keyword" placeholder="검색어" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.keyword} className="table-input-text search-input" onKeyPress={event => {if(event.key === 'Enter') this.doSearch()}}/>
            </div>
            <div className="calendar-box">
              <span>조회기간</span>
              <div className="calendar-wrap">
                <FormInput disabled={this.isWidgetMode} name="startDate" placeholder="" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.startDate} onClick={() => {this.isShowCalendar.endDate = false; this.isShowCalendar.startDate = true;}} className="table-input-text search-calendar star" /> &#126;
                <FormInput disabled={this.isWidgetMode} name="endDate" placeholder="" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.endDate} onClick={() => {this.isShowCalendar.startDate = false; this.isShowCalendar.endDate = true}} className="search-calendar end" />
                {this.isShowCalendar.startDate ? ( <Calendar className="startDate" onChange={date => {this.onChangeCalendarValue(date, 'startDate' )}} maxDate={new Date()} /> ) : null}
                {this.isShowCalendar.endDate ? (<Calendar className="endDate" onChange={date => {this.onChangeCalendarValue(date, 'endDate' )}} maxDate={new Date()} /> ) : null}
              </div>
            </div>
            {!this.isWidgetMode && this.isShowDetailSearch ? (
              <div>
                <div className="detail-filter-box">
                  <table>
                    <tbody>
                    <tr>
                      <th>별점</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={this.searchFormValue.star.all} checked={Boolean(this.searchFormValue.star.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label className="review-star star05"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star5)} checked={Boolean(this.searchFormValue.star.star5)} name="star5" type="checkbox" /><span></span></label>
                        <label className="review-star star04"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star4)} checked={Boolean(this.searchFormValue.star.star4)} name="star4" type="checkbox" /><span></span></label>
                        <label className="review-star star03"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star3)} checked={Boolean(this.searchFormValue.star.star3)} name="star3" type="checkbox" /><span></span></label>
                        <label className="review-star star02"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star2)} checked={Boolean(this.searchFormValue.star.star2)} name="star2" type="checkbox" /><span></span></label>
                        <label className="review-star star01"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star1)} checked={Boolean(this.searchFormValue.star.star1)} name="star1" type="checkbox" /><span></span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>작성자 종류</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.writer)}} value={this.searchFormValue.writer.all} checked={Boolean(this.searchFormValue.writer.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.writer)}} value={this.searchFormValue.writer.member} checked={Boolean(this.searchFormValue.writer.member)} name="member" type="checkbox" /><span>회원</span></label>
                        {/*<label><input type="checkbox" name="writerNoMember" checked={this.searchFormValue.writerNoMember} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.writerNoMember} /><span>비회원</span></label>*/}
                        {/*<label><input type="checkbox" name="writerNpayMember" checked={this.searchFormValue.writerNpayMember} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.writerNpayMember} /><span>NPAY회원</span></label>*/}
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.writer)}} value={this.searchFormValue.writer.admin} checked={Boolean(this.searchFormValue.writer.admin)} name="admin" type="checkbox" /><span>관리자</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>리뷰종류</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewType)}} value={this.searchFormValue.reviewType.all} checked={Boolean(this.searchFormValue.reviewType.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewType)}} value={this.searchFormValue.reviewType.text} checked={Boolean(this.searchFormValue.reviewType.text)} name="text" type="checkbox" /><span>텍스트</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewType)}} value={this.searchFormValue.reviewType.image} checked={Boolean(this.searchFormValue.reviewType.image)} name="image" type="checkbox" /><span>포토</span></label>
                        {/*<label><input type="checkbox" name="reviewTypeMovie" checked={this.searchFormValue.reviewTypeMovie} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.reviewTypeMovie} /><span>동영상</span></label>*/}
                        {/*<label><input type="checkbox" name="reviewTypeSurvey" checked={this.searchFormValue.reviewTypeSurvey} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.reviewTypeSurvey} /><span>설문</span></label>*/}
                      </td>
                    </tr>
                    <tr>
                      <th>게시상태</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewDis)}} value={this.searchFormValue.reviewDis.all} checked={Boolean(this.searchFormValue.reviewDis.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewDis)}} value={this.searchFormValue.reviewDis.on} checked={Boolean(this.searchFormValue.reviewDis.on)} name="on" type="checkbox" /><span>게시됨</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewDis)}} value={this.searchFormValue.reviewDis.will} checked={Boolean(this.searchFormValue.reviewDis.will)} name="will" type="checkbox" /><span>게시예약</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.reviewDis)}} value={this.searchFormValue.reviewDis.pending} checked={Boolean(this.searchFormValue.reviewDis.pending)} name="pending" type="checkbox" /><span>게시보류</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.isDelete} checked={Boolean(this.searchFormValue.isDelete)} name="isDelete" type="checkbox" /><span>삭제</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>포인트 지급 상태</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.all} checked={Boolean(this.searchFormValue.payPoint.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.will} checked={Boolean(this.searchFormValue.payPoint.will)} name="will" type="checkbox" /><span>지급예정</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.waiting} checked={Boolean(this.searchFormValue.payPoint.waiting)} name="waiting" type="checkbox" /><span>지금대기</span></label>
                        {/*<label><input type="checkbox" name="payPointNoMemberWaiting" checked={Boolean(this.searchFormValue.noMemberWaiting)} onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.waiting} /><span>비회원지급대기</span></label>*/}
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.payed} checked={Boolean(this.searchFormValue.payPoint.payed)} name="payed" type="checkbox" /><span>지급완료</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.cancel} checked={Boolean(this.searchFormValue.payPoint.cancel)} name="cancel" type="checkbox" /><span>지급취소</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.noPoint} checked={Boolean(this.searchFormValue.payPoint.noPoint)} name="noPoint" type="checkbox" /><span>포인트없음</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.limit} checked={Boolean(this.searchFormValue.payPoint.limit)} name="limit" type="checkbox" /><span>지급제한</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.recover} checked={Boolean(this.searchFormValue.payPoint.recover)} name="recover" type="checkbox" /><span>회수완료</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.recoverFail} checked={Boolean(this.searchFormValue.payPoint.recoverFail)} name="recoverFail" type="checkbox" /><span>회수실패</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.payPoint)}} value={this.searchFormValue.payPoint.warning} checked={Boolean(this.searchFormValue.payPoint.warning)} name="warning" type="checkbox" /><span>지급경고</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>위젯</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.widgetDis)}} value={this.searchFormValue.widgetDis.all} checked={Boolean(this.searchFormValue.widgetDis.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.widgetDis)}} value={this.searchFormValue.widgetDis.on} checked={Boolean(this.searchFormValue.widgetDis.on)} name="on" type="checkbox" /><span>위젯 노출 중</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.widgetDis)}} value={this.searchFormValue.widgetDis.no} checked={Boolean(this.searchFormValue.widgetDis.no)} name="no" type="checkbox" /><span>지정된 위젯 없음</span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>순서 고정</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.fix)}} value={this.searchFormValue.fix.all} checked={Boolean(this.searchFormValue.fix.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.fix)}} value={this.searchFormValue.fix.top} checked={Boolean(this.searchFormValue.fix.top)} name="top" type="checkbox" /><span>상단 고정</span></label>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.fix)}} value={this.searchFormValue.fix.bottom} checked={Boolean(this.searchFormValue.fix.bottom)} name="bottom" type="checkbox" /><span>하단 고정</span></label>
                      </td>
                    </tr>

                    {/*<tr>*/}
                    {/*  <th>리뷰종류</th>*/}
                    {/*  <td>*/}
                    {/*    <label><input type="checkbox" name="normalReview" checked={this.searchFormValue.normal} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.normalReview} /><span>일반리뷰</span></label>*/}
                    {/*    <label><input type="checkbox" name="snsReview" checked={this.searchFormValue.snsReview} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.snsReview} /><span>SNS리뷰</span></label>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <th>주문상태</th>*/}
                    {/*  <td>*/}
                    {/*    <label><input type="checkbox" name="orderCancel" checked={this.searchFormValue.orderCancel} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.orderCancel} /><span>취소</span></label>*/}
                    {/*    <label><input type="checkbox" name="orderReturn" checked={this.searchFormValue.orderReturn} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.orderReturn} /><span>반품</span></label>*/}
                    {/*    <label><input type="checkbox" name="orderChange" checked={this.searchFormValue.orderChange} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.orderChange} /><span>교환</span></label>*/}
                    {/*    <label><input type="checkbox" name="orderAdmin" checked={this.searchFormValue.orderAdmin} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.orderAdmin} /><span>관리자</span></label>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*  <th>구매여부</th>*/}
                    {/*  <td>*/}
                    {/*    <label><input type="checkbox" name="realBuyer" checked={this.searchFormValue.realBuyer} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.realBuyer} /><span>실제구매자</span></label>*/}
                    {/*    <label><input type="checkbox" name="unKnownBuyer" checked={this.searchFormValue.unKnownBuyer} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.unKnownBuyer} /><span>구매여부미상</span></label>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*  <th>댓글작성</th>*/}
                    {/*  <td>*/}
                    {/*    <label><input type="checkbox" name="commentAdmin" checked={this.searchFormValue.commentAdmin} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.commentAdmin} /><span>관리자작성</span></label>*/}
                    {/*    <label><input type="checkbox" name="commentNo" checked={this.searchFormValue.commentNo} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.commentNo} /><span>미작성</span></label>*/}
                    {/*    <label><input type="checkbox" name="commentRandom" checked={this.searchFormValue.commentRandom} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.commentRandom} /><span>랜덤작성</span></label>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*  <th>작성플랫폼</th>*/}
                    {/*  <td>*/}
                    {/*    <label><input type="checkbox" name="platformOnreview" checked={this.searchFormValue.platformOnreview} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.platformOnreview} /><span>온리뷰</span></label>*/}
                    {/*    <label><input type="checkbox" name="platformSmartstore" checked={this.searchFormValue.platformSmartstore} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.platformSmartstore} /><span>스마트스토어</span></label>*/}
                    {/*    <label><input type="checkbox" name="platformZigzag" checked={this.searchFormValue.platformZigzag} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.platformZigzag} /><span>지그재그</span></label>*/}
                    {/*    <label><input type="checkbox" name="platformCafe24" checked={this.searchFormValue.platformCafe24} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.platformCafe24} /><span>카페24</span></label>*/}
                    {/*    <label><input type="checkbox" name="platformInstagram" checked={this.searchFormValue.platformInstagram} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.platformInstagram} /><span>인스타그램</span></label>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <th>메모</th>*/}
                    {/*  <td>*/}
                    {/*    <label><input type="checkbox" name="memo" checked={this.searchFormValue.memo} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.memo} /><span>메모 있음</span></label>*/}
                    {/*    <label><input type="checkbox" name="memoNo" checked={this.searchFormValue.memoNo} onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.memoNo} /><span>메모 없음</span></label>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    </tbody>
                  </table>
                </div>
                {/*<div className="button-area">*/}
                {/*  <a href="#none" className="search-filter-toggle">검색 필터</a>*/}
                {/*  <a href="#none" className="btn_reset">검색 초기화</a>*/}
                {/*</div>*/}
              </div>
            ) : null}
            <div className="btn-search">
              <Button onClick={event=> this.doSearch()}>검색</Button>
            </div>
            {!this.isWidgetMode ? (
            <div className="btn-toggle" onClick={event=> this.isShowDetailSearch = !this.isShowDetailSearch }><FeatherIcon icon={this.isShowDetailSearch ? "chevron-up" : "chevron-down"} size="37" fill="#ffffff" className="nd-icon"/></div>
            ) : null}
          </div>
        </div>

        <div className="review-list-box">
          <form id="reviewListForm" className="review-list-form">
            <div className="section-box review-list-table">
              <table>
                <thead>
                <tr>
                  {/*<th><FormCheckbox*/}
                  {/*  // checked={this.state.orange}*/}
                  {/*  // onChange={e => this.handleChange(e, "orange")}*/}
                  {/*>*/}
                  {/*</FormCheckbox></th>*/}
                  <th>리뷰정보</th>
                  <th>리뷰내용</th>
                  <th>위젯</th>
                  <th>리뷰관리</th>
                </tr>
                </thead>
                <tbody>
                {this.list && this.list.docs && this.list.docs.length > 0 ?
                  this.list.docs.map((item, i) => (
                    <tr key={i}>
                      {/*<td className="text-center vertical-middle">*/}
                      {/*  <FormCheckbox*/}
                      {/*    // checked={this.state.orange}*/}
                      {/*    // onChange={e => this.handleChange(e, "orange")}*/}
                      {/*  >*/}
                      {/*  </FormCheckbox>*/}
                      {/*</td>*/}
                      <td>
                        <div className="review-info-wrap">
                          {/*<div className="review-num">4</div>*/}
                          <div className="user-name">{item.writerName} {item.writerId ? '(' + item.writerId + ')' : null }
                            {item.isMember ?
                              (item.isAdmin ? (<span className="member-icon admin">관리자</span>) : (<span className="member-icon admin">회원</span>) )
                              : ((<span className="member-icon admin">비회원</span>)) }
                          </div>
                          {item.syncFrom == 'cafe24' ? (<>
                            <div className="review-type"><span>카페24</span></div>
                          </>) : (<>
                          <div className="review-type"><span>{item.fromWrite ? item.fromWrite: '일반리뷰' }</span></div>
                          {!item.isMember ? (
                          <div className="review-history">
                            <ul>
                              <li>포인트 : <PayPointStatusRow payPointStatus={item.payPointStatus} userOrder={item.userOrder_id}/></li>
                              <li>주문상대 : <OrderStatus orderStatus={item.orderStatus} /></li>
                            </ul>
                            {/*<div className="history-more">*/}
                            {/*  <a className="btn-history-more">*/}
                            {/*    <FeatherIcon icon="chevron-down" className="nd-icon" />*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                          </div>) : null }
                          </>) }
                        </div>
                      </td>
                      <td>
                        <div className="review-contents-wrap">
                          <div className="order-product-info">
                            {item.product && item.product.image ? (
                            <div className="product-thumb" onClick={e => this.openProductPage(item)}>
                                <img src={item.product.image} />
                            </div>) : null}
                            {item.orderProductName ? (
                            <div className="product-info" onClick={e => this.openProductPage(item)}>
                              <span className="product-name">{item.orderProductName}</span>
                              <span className="product-option">{item.orderProductOptions}</span>
                            </div> ) : (
                            <div className="product-info" >
                              {item.product_id && item.product_id.productName ? ( <span className="product-name" onClick={e => this.openProductPage(item)}> {item.product_id.productName}</span> )
                                  : <span className="product-name"> 제품 정보가 없습니다.</span>}
                            </div> )
                            }
                            {/*<div className="product-info-more">*/}
                            {/*  <a href="#none" className="btn-prdinfo-more">주문상품 자세히보기*/}
                            {/*    <FeatherIcon icon="chevron-down" className="nd-icon" />*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                          </div>
                          <div className="review-contents">
                            <div className="review-contents-box">
                              <div className="review-contents-area">
                                <div className="review-contents-top">
                                  <ReviewStar goodsPt={item.goodsPt} />

                                  {/*<div className="review-star star03"><span></span></div>*/}

                                  <div className="review-write-date">생성 시간 : {moment(item.writerAt).format('YYYY-MM-DD HH:mm')} / 게시 시간 : {moment(item.displayAt).format('YYYY-MM-DD HH:mm')}</div>
                                  {/*<div className="review-content-length">{item.contents.length}자</div>*/}
                                </div>
                                <div className="review-content">
                                  {!item.isEdit ? (
                                      <p>{item.contents}</p>
                                      ) : (
                                        <FormGroup>
                                          <FormTextarea className="review-content-textarea" name="contents" value={item.contents ? item.contents : ''} onChange={event => {item.contents = item.contents ? item.contents : {}; this.onChangeValue(event, item)}} />
                                          <button onClick={e => this.updateReviewContent(item)} type="button" className="btn-review-content-modify">수정</button>
                                        </FormGroup>
                                      )
                                  }
                                </div>
                                <div className="review-status">
                                  {/*<div className="review-views"><strong>조회수</strong> {item.hit}</div>*/}
                                  <div className="review-help"><strong>도움</strong> {item.likeCount > 0 ? item.likeCount : 0}</div>
                                  <div className="review-platform"><strong>온리뷰</strong>에서 작성됨</div>
                                </div>
                              </div>
                              <div className="review-photo-area">
                                {/*<div className="review-detail-more">*/}
                                {/*  <button className="btn-review-detail-more" type='button'>리뷰 자세히보기*/}
                                {/*    <FeatherIcon icon="chevron-right" className="nd-icon" />*/}
                                {/*  </button>*/}
                                {/*</div>*/}
                                <div className="review-photo">
                                  <ul>
                                    {item.movies && item.movies.length > 0 ?
                                      item.movies.map((item, j) => (
                                        <li key={j}>
                                          {typeof item == 'object' ? (<img src={"https://customer-9lljf7xk3rrg9o6q.cloudflarestream.com/" + item.src + "/thumbnails/thumbnail.gif?width=150&height=150&time=1s&duration=5s"} alt="" />) : <img src={"https://customer-9lljf7xk3rrg9o6q.cloudflarestream.com/" + item + "/thumbnails/thumbnail.gif?width=150&height=150&time=1s&duration=5s"} alt="" /> }
                                        </li>
                                      ))
                                      : null
                                    }
                                    {item.images && item.images.length > 0 ?
                                      item.images.map((item, j) => (
                                    <li key={j}>
                                      {typeof item == 'object' ? (<img src={item.src + "/thumba"} alt="" />) : <img src={item + "/thumba"} alt="" />}
                                    </li>
                                      ))
                                      : null
                                    }
                                    {/*{item.platformImages && item.platformImages.length > 0 ?*/}
                                    {/*    item.platformImages.map((item, j) => (*/}
                                    {/*        <li key={j}><img src={item.url} alt="" /></li>*/}
                                    {/*    )): null*/}
                                    {/*}*/}
                                    {/*<li>*/}
                                    {/*  <a href="#">*/}
                                    {/*    <div className="dim"><span>더보기</span></div>*/}
                                    {/*    <img src="../../images/img/review-photo.png" alt="" />*/}
                                    {/*  </a>*/}
                                    {/*</li>*/}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="review-comment">
                              <div className="reply-content active">
                                <FormGroup>
                                  <FormTextarea name="content" value={item.comment && item.comment.content ? item.comment.content : ''}
                                                onChange={event => {item.comment = item.comment ? item.comment : {}; this.onChangeValue(event, item.comment)}}
                                                placeholder="답글을 작성해 주세요." />
                                  <button onClick={e => this.updateComment(item)} type="button" className="btn-review-comment">답변</button>
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="review-widget-wrap">
                          <div className="review-widget-list">
                            <ul>
                              {item.myWidget_ids && item.myWidget_ids.length > 0 ?
                                item.myWidget_ids.map((key, j) => (
                                  <li key={j}>
                                    <div className="review-widget">
                                      <span className="review-widget-tit">{this.myWidgets[key] ? this.myWidgets[key].title : '???'}</span>
                                      <span className="review-widget-del" onClick={event=> this.removeWidgetInReview(item, key, {review : i, myWidget:j})} >
                                        <FeatherIcon icon="x" className="nd-icon" />
                                      </span>
                                    </div>
                                  </li>
                                ))
                                : <div className="no-data">지정된 위젯이 없습니다.</div>
                              }
                            </ul>
                          </div>
                          <div className="review-widget-select">
                            <FormSelect name="myWidget_id" onChange={event => {item.myWidget_id = item.myWidget_id ? item.myWidget_id : {}; this.onChangeValueAndUpdate('myWidget_id', event, item)}} className="search-type">
                              <option value="">-위젯 선택하기-</option>
                              {this.myWidgets  ?
                                  Object.keys(this.myWidgets).map((key, i) => (
                                      <option key={i} value={key}>{this.myWidgets[key].title}</option>
                                  ))
                                  : null
                              }
                            </FormSelect>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="review-management">
                          <div className="posting-state-box">
                            <DisplayStatus displayStatus={item.displayStatus} />
                            <div className="posting-state-icon">
                              <FeatherIcon icon="more-horizontal" className="nd-icon" title="히스토리 보기" id="isReviewHistoryTip" onClick={event=> this.showReviewHistory(item)}/>
                              <Tooltip
                                open={this.isReviewHistoryTip}
                                target="#isReviewHistoryTip"
                                toggle={e => this.toggleTip('isReviewHistoryTip')}
                              >
                                리뷰 관리 히스토리
                              </Tooltip>
                            </div>
                            <div className="posting-state-select">
                              <FormSelect name="displayStatus" className="posting-select" onChange={event => { this.onChangeValueAndUpdate('displayStatus', event, item) }} value={item.displayStatus}>
                                <option value="">게시 상태 수정하기</option>
                                {/*<option value="">게시 예정</option>*/}
                                <option value="2">게시 중</option>
                                <option value="1">게시 대기</option>
                                <option value="90">숨김</option>
                              </FormSelect>
                            </div>
                          </div>
                          <div className="benefits-state-box">
                            {item.syncFrom == 'cafe24' ? (<p>카페24 관리자에서 확인</p>)
                                : (<PayPointStatus item={item} payPointStatus={item.payPointStatus} payBonusPointStatus={item.payBonusPointStatus} />)}

                            {item.syncFrom != 'cafe24' ? (
                            <div className="benefits-state-icon">
                              <FeatherIcon icon="more-horizontal" className="nd-icon" title="히스토리 보기" id="isReviewHistoryTip" onClick={event=> this.showReviewPoint(item)}/>
                              <Tooltip
                                open={this.isReviewHistoryTip}
                                target="#isReviewHistoryTip"
                                toggle={e => this.toggleTip('isReviewHistoryTip')}
                              >
                                게시 및 포인트 관리
                              </Tooltip>
                            </div>) : null}

                            <div className="benefits-state-select">
                              {/*<ButtonGroup>*/}
                              {/*  <Button disabled={item.payPointStatus == 90 || item.payPointStatus == 1 ? false : true} onClick={event => { this.updatePayPointStatus(item, 4) }}>지급</Button>*/}
                              {/*  <Button disabled={item.payPointStatus == 4 ? false : true} onClick={event => { this.updatePayPointStatus(item, 90) }} theme="warning">회수</Button>*/}
                              {/*</ButtonGroup>*/}
                              { /*1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패 */}
                              { /*1:지급예정, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패 */}
                              {/*<FormSelect onChange={event => { this.onChangeValueAndUpdate('payPointStatus', event, item) }} value={item.payPointStatus} name="payPointStatus" className="benefits-select" disabled={item.payPointStatus == 95} >*/}
                              {/*  <option value="">포인트 상태 수정하기</option>*/}
                              {/*  <option value="1">지급예정</option>*/}
                              {/*  <option value="11">지급대기</option>*/}
                              {/*  <option value="12">비회원 지급대기</option>*/}
                              {/*  <option value="2">지급완료</option>*/}
                              {/*  <option value="9">지급취소</option>*/}
                              {/*  <option value="91">지급제한</option>*/}
                              {/*  <option value="90">지급포인트없음</option>*/}
                              {/*  <option value="92">회수완료</option>*/}
                              {/*  <option value="95">쇼핑몰 관리자에서 확인</option>*/}
                              {/*  <option value="99">회수실패</option>*/}
                              {/*  <option value="98">지급경고</option>*/}
                              {/*</FormSelect>*/}
                            </div>
                          </div>
                          <div className="review-management-tool">
                            <ul>
                              <li className={'review-top' + ( this.isWidgetMode && i > 0 ? '' : ' disabled' )} onClick={ event => { this.onChangeWidgetOrder(item, i, 'up'); }}>
                                <FeatherIcon icon="chevrons-up" className="nd-icon" />
                              </li>
                              <li className={'review-down' + ( this.isWidgetMode && i < this.list.docs.length-1 ? '' : ' disabled' )} onClick={ event => { this.onChangeWidgetOrder(item, i, 'down'); }}>
                                <FeatherIcon icon="chevrons-down" className="nd-icon" />
                              </li>
                              <li className="review-modify" onClick={ event => { this.reviewContentModifyToggle(item); }}>
                                <FeatherIcon icon="edit-2" className="nd-icon" />
                              </li>
                              <li className="review-memo" onClick={event => { this.delReview(item) }}>
                                <FeatherIcon icon="trash-2" className="nd-icon" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={4} className="text-center"><br /><br />리뷰가 없습니다.<br /><br /><br /><a href="/reviewWriteConfig">[카페24 리뷰 데이터 가져오기 및 설정하기]</a><br /><br /></td>
                  </tr>
                }
                </tbody>
              </table>
              <Pagination />
            </div>
          </form>
        </div>


        <Modal size="lg" className='ispointModal' open={this.modals.ispoint} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>리뷰 포인트 관리 / 내역 [주문상태:<OrderStatus orderStatus={this.curReview.orderStatus}/> / 상품 가격 : {this.curReview.reviewHolder_id && this.curReview.reviewHolder_id.orderAmount ? this.curReview.reviewHolder_id.orderAmount : this.curReview.orderAmount}원]</ModalHeader>
          <ModalBody className="history-window-popup">
            <div className="history-popup-contents">
              <div className="popup-contents-top">
                <h4>포인트 지급</h4>
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>작성자</th>
                    <th>후기 포인트</th>
                    <th>보너스 포인트</th>
                    <th>지급된 포인트</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="text-center">{this.curReview.writerName}</td>
                    <td className="text-right">{this.curReview.pointInfo.unpaid} <Button theme='success' size="sm" outline onClick={e => this.updatePoint({type: 'increase', pointAmout:this.curReview.pointInfo.unpaid, reason: '상품후기 - ' + this.curReview.orderProductName}, this.curReview)}>지급</Button></td>
                    <td className="text-right">
                      {this.curReview.pointInfo.bonus && this.curReview.pointInfo.bonus.bonusOnlyOne.point ? (this.curReview.pointInfo.bonus.bonusOnlyOne.point + '(' + this.curReview.pointInfo.bonus.payPointStatusStr + ')') : 0}&nbsp;
                      <Button theme='success' size="sm" outline onClick={e => this.updatePoint({type: 'increase', pointAmout:this.curReview.pointInfo.bonus.bonusOnlyOne.point, reason: '상품후기 보너스 포인트 - ' + this.curReview.orderProductName, isBonus: true}, this.curReview)}>지급</Button>
                    </td>
                    <td className="text-right">{this.curReviewPointInfo.payPointSum}</td>
                  </tr>
                  </tbody>
                </table>
                <h4>포인트 내역</h4>
                <table>
                  <colgroup>
                    <col />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '30%' }} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>내용</th>
                    <th>포인트</th>
                    <th>처리 날짜</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.curReviewHistoryList && this.curReviewHistoryList.docs && this.curReviewHistoryList.docs.length > 0 ?
                      this.curReviewHistoryList.docs.map((item, i) => (
                          <tr key={i}>
                            <td>{item.reason}</td>
                            <td className='text-right'>{item.type == 'increase'? '+' : '-'}{item.pointAmout}</td>
                            <td className='text-right'>{moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                          </tr>
                      )) :
                      <tr>
                        <td colSpan="4">내역이 없습니다.</td>
                      </tr>
                  }
                  {/*{this.curReviewPointInfo && this.curReviewPointInfo.userPointHistory && this.curReviewPointInfo.userPointHistory.length > 0 ?*/}
                  {/*    this.curReviewPointInfo.userPointHistory.map((item, i) => (*/}
                  {/*        <tr key={i}>*/}
                  {/*          <td>{item.reason}</td>*/}
                  {/*          <td className="text-right">{item.type == 'increase' ? '+' : '-'} {item.pointAmout}</td>*/}
                  {/*          <td className="text-center">{moment(item.updatedAt).format('YYYY-MM-DD HH:mm')}</td>*/}
                  {/*        </tr>*/}
                  {/*    )) :*/}
                  {/*    <tr>*/}
                  {/*      <td colSpan="4">내역이 없습니다.</td>*/}
                  {/*    </tr>*/}
                  {/*}*/}
                  </tbody>
                </table>

                <h4>보너스 포인트</h4>
                <table>
                  {/*<colgroup>*/}
                  {/*  <col style={{ width: '15%' }} />*/}
                  {/*  <col style={{ width: '30%' }} />*/}
                  {/*  <col style={{ width: '30%' }} />*/}
                  {/*  <col />*/}
                  {/*</colgroup>*/}
                  <thead>
                  <tr>
                    <th>장문</th>
                    <th>이미지</th>
                    <th>조기 작성</th>
                    <th>첫 리뷰</th>
                    <th>선착순</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{this.curReview.pointInfo.bonus && this.curReview.pointInfo.bonus.perText ? this.curReview.pointInfo.bonus.perText : 0}</td>
                    <td>{this.curReview.pointInfo.bonus && this.curReview.pointInfo.bonus.perImg ? this.curReview.pointInfo.bonus.perImg : 0}</td>
                    <td>{this.curReview.pointInfo.bonus && this.curReview.pointInfo.bonus.early ? this.curReview.pointInfo.bonus.early : 0}</td>
                    <td>{this.curReview.pointInfo.bonus && this.curReview.pointInfo.bonus.joinFirst ? this.curReview.pointInfo.bonus.joinFirst : 0}</td>
                    <td>{this.curReview.pointInfo.bonus && this.curReview.pointInfo.bonus.fsb ? this.curReview.pointInfo.bonus.fsb : 0}</td>
                  </tr>
                  </tbody>
                </table>
                <h4>포인트 추가 / 차감</h4>
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col />
                    <col style={{ width: '30%' }} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>추가/차감</th>
                    <th>내용</th>
                    <th>포인트</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <FormSelect name="type" onChange={event => {this.onChangeValue(event, this.addpoint)}} value={this.addpoint.type} className="search-type">
                        <option value="increase">추가</option>
                        <option value="decrease">차감</option>
                      </FormSelect>
                    </td>
                    <td><FormInput name="reason" placeholder="" onChange={event => {this.onChangeValue(event, this.addpoint)}} value={this.addpoint.reason} placeholder="내용" theme='success' /></td>
                    <td>
                      <InputGroup>
                        <FormInput name="pointAmout" placeholder="" onChange={event => {this.onChangeValue(event, this.addpoint)}} value={this.addpoint.pointAmout} placeholder="포인트" theme='success' />
                        {/*<FormInput value={this.addpoint} placeholder="추가 포인트" theme={this.pointPermisstion ? 'success' : 'secondary'} />*/}
                        <InputGroupAddon type="append">
                          <Button theme='success' onClick={e => this.updatePoint(this.addpoint, this.curReview)}>{this.addpoint.type == 'increase' ? '지급':'차감'}하기</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div className="popup-close">
                <a href="#" onClick={event=> this.modals.ispoint = false} className="btn-popup-close">닫기</a>
              </div>
            </div>

          </ModalBody>
        </Modal>

        <Modal size="lg" className='isHistoryModal' open={this.modals.isHistory} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>리뷰 게시 내역</ModalHeader>
          <ModalBody className="history-window-popup">
            <div className="history-popup-contents">
              <div className="popup-contents-main">
                <h4>게시 상태 히스토리</h4>
                <table>
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col  />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>항목</th>
                    <th>처리내용</th>
                    <th>메모</th>
                    <th>업데이트</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.curReviewHistoryList && this.curReviewHistoryList.docs && this.curReviewHistoryList.docs.length > 0 ?
                    this.curReviewHistoryList.docs.map((item, i) => (
                      <tr key={i}>
                        <ReviewHistoryTd item={item}></ReviewHistoryTd>
                        <td>{moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                      </tr>
                    )) :
                    <tr>
                      <td colSpan="4">내역이 없습니다.</td>
                    </tr>
                  }
                  </tbody>
                </table>
              </div>
              <div className="popup-close">
                <a href="#" onClick={event=> this.modals.isHistory = false} className="btn-popup-close">닫기</a>
              </div>
            </div>

          </ModalBody>
        </Modal>
        <ModalLoading isLoading={this.isLoading} />
      </div>
    )
  }
}

export default ReviewList;
