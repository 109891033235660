import React from 'react'
import { observable, action } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";
import _ from "underscore";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";

//https://fontawesomeicons.com/materialdesign/icons
export class Common {

  @observable curNavInfo = {};
  @observable category = [];
  @observable orgCategory = [];
  @observable isShowModal = false;

  @observable navItems = [
    {
      label: "리뷰",
      mIcon: 'app_registration',
      name: "review",
      to: "/reviewList",
      submenu: [
        {label: "리뷰 리스트", to: "/reviewList",  name: "reviewList", mIcon: 'library_books'},
        {label: "리뷰 작성", to: "/reviewWrite",  name: "reviewWrite", mIcon: 'post_add'},
        // {label: "키워드 알림", to: "/management/keyword",  name: "keyword"},
        // {label: "리뷰 연결", to: "/management/reviewConnect",  name: "reviewConnect"},
        // {label: "외부 리뷰", to: "/management/web2app"},
      ]
    },
    {
      label: "관리",
      mIcon: 'toggle_on',
      name: "setting",
      to: "/reviewWriteConfig",
      submenu: [
        {label: "작성 설정", to: "/reviewWriteConfig",  name: "reviewWriteConfig", mIcon: 'mode_edit'},
        {label: "포인트 지급", to: "/reviewPointConfig",  name: "reviewPointConfig", mIcon: 'money'},
        {label: "리뷰 그룹화", to: "/reviewGroup",  name: "reviewGroup", mIcon: 'group_work'},
        // {label: "텍스트 / 포토리뷰", to: "/textPhotoReview",  name: "textPhotoReview", mIcon: 'table_view'},
        // {label: "디자인 관리", to: "/commonDesign",  name: "commonDesign", mIcon: 'table_view'},
        // {label: "커스텀 위젯 세트(준비중)", to: "/widget",  name: "widget", mIcon: 'table_view'},
      ]
    },
    {
      label: "디자인/위젯",
      mIcon: 'widgets',
      name: "design",
      to: "/commonDesign",
      submenu: [
        {label: "기본 디자인", to: "/commonDesign",  name: "commonDesign", mIcon: 'brush'},
        {label: "위젯", to: "/myWidgetMng",  name: "myWidgetMng", mIcon: 'widgets'},
      ]
    },
    {
      label: "스토어",
      mIcon: 'store',
      name: "store",
      to: "/teamUserInfo",
      submenu: [
        // {label: "고객 공지사항", to: "/web2app", mIcon: 'table_view'},
        {label: "관리자 정보", to: "/teamUserInfo",  name: "teamUserInfoEdit", mIcon: 'person'},
        {label: "회사 정보", to: "/companyInfo",  name: "teamUserInfoEdit", mIcon: 'store'},
        {label: "결제", to: "/inAppOrder",  name: "inAppOrder", mIcon: 'payment'},
        {label: "결제 내역", to: "/inAppOrderList",  name: "inAppOrderList", mIcon: 'receipt'},
      ]
    },
    {
      label: "스토어 관리",
      mIcon: 'business',
      isShowSuperAdmin: true,
      name: "storeList",
      to: "/storeList",
      submenu: [
        {label: "스토어 리스트", to: "/storeList",  name: "StoreList", mIcon: 'store'}
      ]
    },
    // {
    //   label: "상품",
    //   mIcon: 'inventory_2',
    //   name: "products",
    //   submenu: [
    //     {label: "상품 관리", to: "/productList",  name: "productList"},
    //   ]
    // },

    // {
    //   label: "기록",
    //   mIcon: 'verified_user',
    //   name: "history",
    //   submenu: [
    //     {label: "리뷰 요청", to: "/web2app"},
    //     {label: "문자 발송 내역", to: "/web2app"},
    //     {label: "고객 내역", to: "/history/userList",  name: "userList"},
    //   ]
    // },

    // {
    //   label: "MY",
    //   mIcon: 'verified_user',
    //   name: "setting",
    //   submenu: [
    //     {label: "1. 기본설정",
    //       submenu: [
    //         {label: "고객 공지사항", to: "/setting/web2app"},
    //
    //         // {label: "작성유도", to: "/setting/induce",  name: "induce"},
    //         {label: "유저 정보", to: "/setting/teamUserInfo",  name: "teamUserInfoEdit"},
    //         {label: "회사 정보", to: "/setting/companyInfo",  name: "teamUserInfoEdit"},
    //       ]
    //     },
    //     {label: "2. 리뷰 종류별 설정",
    //       submenu: [
    //         {label: "텍스트 / 포토리뷰", to: "/setting/textPhotoReview",  name: "textPhotoReview"},
    //         // {label: "빠른리뷰", to: "/setting/web2app"},
    //         // {label: "설문리뷰", to: "/setting/web2app"},
    //         // {label: "SNS리뷰", to: "/setting/snsReview",  name: "snsReview"},
    //         // {label: "동영상리뷰", to: "/setting/web2app"},
    //       ]
    //     }
    //   ]
    // },
    // {
    //   label: "결제",
    //   mIcon: 'verified_user',
    //   to: "/payment",
    //   name: "payment"
    // },
  ];

  @observable navItemsIndexByName = _.indexBy(this.navItems, 'name');

  @action getCurNav = ( ) => {
    return this.curNavInfo;
  };

  @action setIsShowPopup = (isStatus) => {
    return this.isShowModal = isStatus;
  };

  @action getIsShowPopup = () => {
    return this.isShowModal;
  };




  @action setPageInfo = (route) => {

    let submenuByTo = {};
    let res = {};
    if(this.navItemsIndexByName[route.info.mainMenu].submenu[0] && this.navItemsIndexByName[route.info.mainMenu].submenu[0].submenu) {
      for(let index in this.navItemsIndexByName[route.info.mainMenu].submenu) {
        let row = this.navItemsIndexByName[route.info.mainMenu].submenu[index];
        let middleMenu = row.label;
        for(let index in row.submenu) {
          let cRow = row.submenu[index]
          cRow.middleMenu = middleMenu;
          submenuByTo[cRow.to] = cRow;
        }
      }
    }
    else {
      submenuByTo = _.indexBy(this.navItemsIndexByName[route.info.mainMenu].submenu, 'to');
    }
    res.route = route;
    res.mainMenu = route.info.mainMenu;
    res.mainMenu = this.navItemsIndexByName[route.info.mainMenu];
    res.submenu = submenuByTo[route.path];
    this.curNavInfo = res;
  };


  @action getConBoard = ( boardkey ) => {

    let result = Storage.getObject('board_' + boardkey);
    result.boardKeys = Storage.getObject('boardKeys');
    if(result) {
      return result;
    }
    else {
      console.log(result)
    }

  };



  @action getSetting = ( cb ) => {

    let v = Storage.get('v');

    //test
    v = '0.0.0'
    let params = {
      type: 'query',
      operation: 'getSetting',
      variables: { v  : v ? v : '0.0.0' },
      getData: 'data'
    };

    const gpqParams = gql(params);
    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];
        if(result) {
          if(result.data.v) {
            Storage.set('v', result.data.v);
          }
          if(result.data.ConBoard) {
            let boardKeys = [];
            result = _.indexBy(result.data.ConBoard, 'key')
            for(let key in result) {
              Storage.setObject('board_' + key , result[key]);
              boardKeys.push(key)
            }
            Storage.setObject('boardKeys' , boardKeys);
          }
        }
        else {
          throw res.data.errors;
        }

      })
      .catch(function (error) {
        return error;
      });
  };

  @action getStoreTotalConfig = (data, cb) => {

    let property = ['store_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getStoreTotalConfig',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action getDoc = (operation, param, cb ) => {
    let params = {
      type: 'query',
      operation: 'getDoc',
      variables: {operation: operation, param:param},
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        console.log(res)
        let result = res.data.data[params.operation];

        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action updateDoc = (operation, param, cb ) => {


    let params = {
      type: 'mutation',
      operation: 'updateDoc',
      variables: {operation: operation, param:param},
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action delDoc = (operation, param, cb ) => {

    let params = {
      type: 'mutation',
      operation: operation,
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getCategory = (param, cb) => {

    let category = this.category;
    let orgCategory = this.orgCategory;
    if(category.length > 0) {
      return category;
    }
    else {
      let params = {
        type: 'query',
        operation: 'getCategory',
        variables: param ? param : {},
        getData: 'id cls depth label order'
      };

      const gpqParams = gql(params);

      return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            orgCategory = res.data.data[params.operation];

            let row = {};
            let proc_1 = _.groupBy(res.data.data[params.operation], function(item){ return item.depth; });

            _.each(proc_1, function(data, index) {

              let proc_2;

              if(index === "1") {
                proc_2 = data;
              }
              else {
                proc_2 = _.groupBy(data, function(item){ return item.key });
              }
              row['depth_' + index] = proc_2;
              category = row;
            });
            if(typeof cb === 'function') {
              cb(category);
            }
            else {

              return proc_1;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }

  };



  @action updateCategory = (param, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateCategory',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action updateCategoryOrder = (param, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateCategoryOrder',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getUploadUrl = (mediaType, cb) => {

    let variables = {mediaType:mediaType};
    let params = {
      type: 'query',
      operation: 'getUploadUrl',
      variables: variables,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            if(!res.data.data[params.operation] || !res.data.data[params.operation].data || !res.data.data[params.operation].data.result || !res.data.data[params.operation].data.result.uploadURL) {
              console.log(res.data.data[params.operation]);
            }
            else {
              if(mediaType == 'video') {
                cb(res.data.data[params.operation].data.result)
              }
              else {
                cb(res.data.data[params.operation].data.result.uploadURL)
              }

            }

          }
          else {
            console.log(res.data.data[params.operation])
            //console.log(res.data.data[params.operation])
            //return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        return error;
      });

  }

  @action uploadImg = (uploadUrl, file, cb) => {
    const formData = new FormData();
    formData.append("file", file.files[0]);
    axios.post(uploadUrl, formData )
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.result)
          }
          else {
            return res.data.result;
          }
        }
      })
      .catch(function (error) {
        return error;
      });
  }

  @action removeMedia = (param, cb) => {
    let params = {
      type: 'query',
      operation: 'removeMedia',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  }


  @action sendMail = (param,cb) => {


    let params = {
      type: 'mutation',
      operation: 'sendMail',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };

  @action testCafe24Api = (param,cb) => {


    let params = {
      type: 'mutation',
      operation: 'testCafe24Api',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };


  @action getCollectionCount = (param, cb) => {
    let params = {
      type: 'query',
      operation: 'getCollectionCount',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  }

}
