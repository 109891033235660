import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Store } from "../../flux";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {
  Button,
  Col, Row,
} from "shards-react";
import moment from "moment";

import './Header.scss'
import {Link} from "react-router-dom"
import history from "../../utils/history";
import logo from "../../images/img/logo.png";
import imgBrand from "../../images/avatars/brand_60.png";


@inject(({ common }) => ({ getCurNav: common.getCurNav }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, doLogOut:teamUser.doLogOut }))

@observer
class Header extends React.Component {

  @observable emailId = '';
  @observable isLogin = false;
  @observable isShowSetting = false;

  constructor(props) {
    super(props);
    this.init();
    this.onChange = this.onChange.bind(this);
  }

  @action
  init = async (key) => {

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
    }

  };

  componentDidUpdate(prevProps, prevState){

  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }


  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <div className="header">
        <div className="top-logo">
          <h1>
            <a href="/"><img src={logo} alt={logo} /></a>
          </h1>
        </div>
        <div className="top-gnb">
          <div className="top-notice">
            <span className="notice-icon"><i data-feather="volume" className="nd-icon"></i></span>
            {/*<span className="notice-content">2.0.0 업데이트 안내 리뷰 작성 UI가 개선되었습니다.</span>*/}
            {/*<span className="notice-new">N</span>*/}
          </div>
          <Link to={"/signIn"} className="top-user" >
            {( this.isLogin && this.emailId ) ?
              <i className="material-icons">logout</i>
              :
              <i className="material-icons">login</i>
            }
          </Link>
          <div className="top-tool" onClick={ event=> {this.isShowSetting = !this.isShowSetting;} }>
            <i className="material-icons">settings</i>
          </div>
        </div>
        {this.isShowSetting ? (
            <div className="setting-box">
              <ul>
                <li className="store-info">
                  <Row>
                    <Col md="4">
                      <span className="brand-thumb"><img src={imgBrand} alt="오늘부터마켓" /></span>
                    </Col>
                    <Col>
                      <p className="store-name">{this.store.name}</p>
                      <p className="store-id">{this.store.domain}</p>
                    </Col>
                  </Row>
                </li>
                <li className="service-info">
                  <div>
                    <h6>서비스 이용 현황</h6>
                    {this.store.isPaidUser ? (
                        <ul>
                          <li><span>만료일 : {moment(this.store.useExpiryDate).format('YYYY-MM-DD')}</span></li>
                        </ul>
                    ) : <p>서비스 이용을 신청해 주세요.</p>}
                    <div className="text-center mt-3">
                      <Link to={"/inAppOrder"}><Button theme="light" size="sm">서비스 주문하기</Button></Link>
                    </div>

                  </div>
                </li>
                <li><Link to={"/inAppOrderList"}>결제내역 및 이용내역</Link></li>
                <li><Link to={"/reviewWriteConfig"}>설정</Link></li>
              </ul>
            </div>
        ) : null}

      </div>
    );
  }
}

Header.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

Header.defaultProps = {
  hideLogoText: false
};

export default Header;
