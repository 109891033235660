import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"
import history from "../utils/history";


export class Review {

  @action updateReview = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReview',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateReviewSome = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReviewSome',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateReviewDisplayStatus = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReviewDisplayStatus',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };



  @action updatePayPoint = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updatePayPoint',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updatePoint = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updatePoint',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateUserOrderByCafe24 = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateUserOrderByCafe24',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };




  @action updateReviewComment = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReviewComment',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };


  @action setReviewSync = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'setReviewSync',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action cloudFlareMng = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'cloudFlareMng',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };


  @action getReviewList = (data, cb) => {

    let property = ['store_id', 'limit', 'page', 'sort', 'displayStatus', 'myWidget_id', 'searchParams'];

    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getReviewList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta, refProducts'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action getReviewHistoryList = (data, cb) => {

    let property = ['review_id', 'type']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getReviewHistoryList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getReviewPointInfo = (data, cb) => {

    let property = ['review_id', 'userOrder_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getReviewPointInfo',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation].data;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };


  @action adminDelReview = (data, cb) => {

    let variables = data;

    let params = {
      type: 'mutation',
      operation: 'adminDelReview',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation])
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };


}
