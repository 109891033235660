import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, SignOutLayout } from "./layouts";

// Route containers
import Starter from "./containers/pages/TeamUser/starter";
import signUp from "./containers/pages/TeamUser/signUp";
import signIn from "./containers/pages/TeamUser/signIn";
import Welcome from "./containers/pages/TeamUser/welcome";

import cafe from "./containers/pages/TeamUser/cafe";
import cafeInAppOrderReturnUrl from "./containers/pages/TeamUser/cafeInAppOrderReturnUrl";

import TeamUserInfoEdit from "./containers/pages/Setting/teamUserInfoEdit";
import CompanyInfoEdit from "./containers/pages/Setting/companyInfoEdit";
import InAppOrder from "./containers/pages/Setting/inAppOrder";
import OrderEnd from "./containers/pages/Setting/orderEnd";
import InAppOrderList from "./containers/pages/Setting/inAppOrderList";


import ReviewList from "./containers/pages/Review/reviewList";
import ReviewConnect from "./containers/pages/Review/reviewConnect";
import Keyword from "./containers/pages/Review/keyword";
import ReviewWrite from "./containers/pages/Review/reviewWrite";
import ReviewWriteConfig from "./containers/pages/Setting/reviewWriteConfig";
import ReviewPointConfig from "./containers/pages/Setting/reviewPointConfig";
import ReviewGroup from "./containers/pages/Setting/reviewGroup";
import Induce from "./containers/pages/Setting/induce";

import TextPhotoReview from "./containers/pages/Setting/textPhotoReview";
import SnsReview from "./containers/pages/Setting/snsReview";
import CommonDesign from "./containers/pages/Setting/commonDesign";
import MyWidgetMng from "./containers/pages/Setting/myWidgetMng";

import Widget from "./containers/pages/Setting/widget";
import UserList from "./containers/pages/History/userList";
import ReviewMng from "./containers/pages/Setting/reviewMng";

import ProductList from "./containers/pages/Product/productList";

import StoreList from "./containers/pages/SuperAdmin/storeList";

// Route Views
import Errors from "./views/Errors";


export default [
  {
    path: "/",
    exact: true,
    layout: SignOutLayout,
    component: Starter
  },
  {
    path: "/cafe24",
    layout: SignOutLayout,
    component: cafe
  },
  {
    path: "/cafeRedirect",
    layout: SignOutLayout,
    component: cafe
  },
  {
    path: "/cafeInAppOrderReturnUrl",
    layout: SignOutLayout,
    component: cafeInAppOrderReturnUrl
  },
  {
    path: "/signUp",
    layout: SignOutLayout,
    component: signUp
  },
  {
    path: "/signIn",
    layout: SignOutLayout,
    component: signIn
  },
  {
    path: "/welcome",
    layout: SignOutLayout,
    component: Welcome
  },
  //리뷰
  {
    path: "/reviewList",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewList'},
    component: ReviewList
  },
  {
    path: "/reviewWrite",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewWrite'},
    component: ReviewWrite
  },
  //설정
  {
    path: "/reviewWriteConfig",
    layout: DefaultLayout,
    info: {mainMenu: 'setting', subMenu: 'reviewWriteConfig'},
    component: ReviewWriteConfig
  },
  {
    path: "/reviewPointConfig",
    layout: DefaultLayout,
    info: {mainMenu: 'setting', subMenu: 'reviewPointConfig'},
    component: ReviewPointConfig
  },
  {
    path: "/reviewGroup",
    layout: DefaultLayout,
    info: {mainMenu: 'setting', subMenu: 'reviewGroup'},
    component: ReviewGroup
  },
  //디자인
  {
    path: "/commonDesign",
    layout: DefaultLayout,
    info: {mainMenu: 'design', subMenu: 'commonDesign'},
    component: CommonDesign
  },
  {
    path: "/myWidgetMng",
    layout: DefaultLayout,
    info: {mainMenu: 'design', subMenu: 'reviewList'},
    component: MyWidgetMng
  },
  //관리자
  {
    path: "/teamUserInfo",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'teamUserInfo'},
    component: TeamUserInfoEdit
  },
  {
    path: "/companyInfo",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'reviewList'},
    component: CompanyInfoEdit
  },
  {
    path: "/inAppOrder",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'inAppOrder'},
    component: InAppOrder
  },
  {
    path: "/orderEnd",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'orderEnd'},
    component: OrderEnd
  },
  {
    path: "/inAppOrderList",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'inAppOrderList'},
    component: InAppOrderList
  },





  //??
  {
    path: "/reviewKeyword",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewKeyword'},
    component: Keyword
  },
  {
    path: "/reviewConnect",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewConnect'},
    component: ReviewConnect
  },
  {
    path: "/textPhotoReview",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'textPhotoReview'},
    component: TextPhotoReview
  },
  {
    path: "/induce",
    layout: DefaultLayout,
    info: {mainMenu: 'setting', subMenu: 'induce'},
    component: Induce
  },


  //설정 - 2. 리뷰 종류별 설정

  {
    path: "/snsReview",
    layout: DefaultLayout,
    info: {mainMenu: 'setting', subMenu: 'snsReview'},
    component: SnsReview
  },

  //상품

  {
    path: "/productList",
    layout: DefaultLayout,
    info: {mainMenu: 'products', subMenu: 'productList'},
    component: ProductList
  },

  {
    path: "/userList",
    layout: DefaultLayout,
    info: {mainMenu: 'history', subMenu: 'userList'},
    component: UserList
  },

  // 슈퍼관리자 메뉴
  {
    path: "/storeList",
    layout: DefaultLayout,
    info: {mainMenu: 'storeList', subMenu: 'storeList'},
    component: StoreList
  },

  {
    path: "/reviewMng",
    layout: DefaultLayout,
    component: ReviewMng
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
];
