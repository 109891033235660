import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';

import './style.scss'
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class StoreList extends Component {

  @observable teamUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.teamUser[name] = value;
    }
    else {
      this.teamUser[name] = value;
    }

  };

  render() {
    return (
      <div className="main-content">
        <PageTitle />

        <div className="user-list-box">
          <div className="section-box">
            <form id="" target="" className="">
              <div className="user-search-box">
                <table>
                  <tbody>
                  <tr>
                    <th>검색어</th>
                    <td>
                      <div className="search-input-box">
                        <select name="searchType" className="search-type">
                          <option value="">이름</option>
                          <option value="">주문번호</option>
                        </select>
                        <input type="text" name="keyword" className="table-input-text search-input" />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="btn-search">
                  <input type="submit" value="검색" className="search-button" />
                </div>
              </div>
            </form>
          </div>
          <div className="section-box">
            <form id="" target="" className="">
              <div className="user-list-table">
                <div className="user-pick-list">
                  <div className="pick-list-count">
                    검색결과 <span>2</span>개 / 전체 <span>10</span>개
                  </div>
                  <div className="choice-num-view">
                    <select name="" className="">
                      <option value="">100개씩 보기</option>
                      <option value="">75개씩 보기</option>
                      <option value="">50개씩 보기</option>
                      <option value="">25개씩 보기</option>
                    </select>
                  </div>
                </div>
                <table>
                  <colgroup>
                    <col width="14.75%" />
                    <col width="14.75%" />
                    <col width="14.75%" />
                    <col width="14.75%" />
                    <col width="14.75%" />
                    <col width="14.75%" />
                    <col width="11.84%" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>고객명</th>
                    <th>휴대폰 번호</th>
                    <th>리뷰수</th>
                    <th>구매상품수</th>
                    <th>총매출</th>
                    <th>SNS정보</th>
                    <th>고객명</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>홍길동</td>
                    <td>010-1234-1234</td>
                    <td>
                      <div className="review-count">
                        <div className="review-count-total">
                          <div className="total-count">총 22회</div>
                          <div className="btn-review-count-more">
                            <FeatherIcon icon="chevron-down" className="nd-icon" />
                          </div>
                        </div>
                        <ul className="review-count-detail">
                          <li>일반리뷰 : 0</li>
                          <li>- 텍스트 : 0</li>
                          <li>- 포토 : 0</li>
                        </ul>
                      </div>
                    </td>
                    <td>28회</td>
                    <td>1,237,000</td>
                    <td>skylove</td>
                    <td>
                      <div className="btn-wrap">
                        <button className="btn-send-history">문자 발송 내역</button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="pagination">
                  <ul>
                    <li className="btn-page-first">
                      <a href="#">
                        <FeatherIcon icon="chevrons-left" className="nd-icon" />
                      </a>
                    </li>
                    <li className="btn-page-prev">
                      <a href="#">
                        <FeatherIcon icon="chevron-left" className="nd-icon" />
                      </a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li className="on"><a href="#">10</a></li>
                    <li className="btn-page-next">
                      <a href="#">
                        <FeatherIcon icon="chevron-right" className="nd-icon" />
                      </a>
                    </li>
                    <li className="btn-page-last">
                      <a href="#">
                        <FeatherIcon icon="chevrons-right" className="nd-icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>



      </div>
    )
  }
}

export default StoreList;
