import React, {useRef} from "react";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import './ModalProductList.scss'
import {Modal, ModalBody, ModalHeader, Col, Row, Button, FormGroup, FormSelect, FormInput} from "shards-react";
import _ from "underscore";
import FeatherIcon from "feather-icons-react";

@inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, uploadImg: common.uploadImg, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ product }) => ({ getProductGroupList: product.getProductGroupList, getProductList: product.getProductList, setProductSync: product.setProductSync }))
@observer
class ModalProductList extends React.Component {

  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;


  @observable isShow = false;

  //list
  @observable categories = [];
  @observable listParams = {
    page:1, limit:20,
  }
  @observable searchParams = {
    keyword: ''
  };
  @observable list = [];
  @observable inGroup = [];
  @observable added_ids = [];

  constructor(props) {
    super(props);
    this.isShow = props.isShow;
    this.modal = props.modal;
    if(props.inGroup) {
      this.inGroup = props.inGroup;
    }
    if(props.added_ids) {
      this.added_ids = props.added_ids;
    }

    this.modalToggle = props.modalToggle;

    this.groupProducts = React.createRef();
    this.init();
  }

  @action
  init = async () => {
    let _this = this;
    //basic
    this.isLogin = this.props.isLogin;
    this.teamUser = this.props.me;
    this.store = this.props.me.store ? this.props.me.store : {};
    this.permissions = this.props.me.permissions;


    this.listParams.store_id = this.teamUser.selStore_id;
    //this.listParams.teamUserId = this.teamUser.id;

    this.props.getDoc('getCategories', { store_id:this.teamUser.selStore_id }, function(res) {
      _this.categories = res.data.categoriesData ? res.data.categoriesData : [];
      //this.categories;
    });
    this.getProductList();
  };

  getProductList = async(page) => {
    this.listParams.page = page;
    if(this.listParams.store_id) {
      this.listParams.searchParams = this.searchParams;
      this.list = await this.props.getProductList(this.listParams);
      // this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };

  addGroupBox = async(item) => {
    this.inGroup.unshift(item);
    this.added_ids.unshift(item._id);
    let _this = this;
    // setTimeout(() => {
    //   _this.groupProducts.current.scrollLeft = _this.groupProducts.current.scrollWidth;
    // }, "100");
  }

  onChangeSearchValue = async (event) => {
    const { name, value, type } = event.target;
    if(type == 'checkbox') {
      if(value) {
        this.searchParams[name] = !JSON.parse(value);
      }
      else {
        this.searchParams[name] = true;
      }
    }
    else {
      this.searchParams[name] = value;
    }

  };

  search = async() => {
    this.getProductList();
  };


  saveGroup = async() => {
    //this.props.setGroup(this.inGroup, this.added_ids);
    //this.isShow = false;
    this.modalToggle(this.modal);
  };

  componentDidUpdate(prevProps, prevState){
    console.log(prevProps.inGroup)
    if(this.props.isShow != prevProps.isShow) {
      this.isShow = this.props.isShow;
      this.inGroup = this.props.inGroup;
      this.added_ids = this.props.added_ids;
    }
  }


  // componentDidMount() {
  //   //Store.addChangeListener(this.onChange);
  // }


  render() {
    let _this = this;
    let isModal = this.isShowModal;

    function Pagination() {
      let result = [];
      let limit = _this.list.limit;
      let totalPages = _this.list.totalPages;
      let curPageNum = _this.list.page;
      let nextPage = _this.list.nextPage;
      let prevPage = _this.list.prevPage;
      let startNum = (parseInt((curPageNum-1)/10)*10) +1;
      let endNum = startNum+9 < totalPages ? startNum+9 : totalPages;
      let rendering = () => {
        for(let i = startNum; i <= endNum; i ++) {
          if(i == curPageNum) {
            result.push(<li key={i} className="on" onClick={event=> _this.getProductList(i)} >{i}</li>);
          }
          else {
            result.push(<li key={i} onClick={event=> _this.getProductList(i)}>{i}</li>);
          }
        }
        return result;
      }
      if(_this.list && _this.list.totalPages) {
        return (
          <div className="pagination">
            <ul>
              { curPageNum != 1 ? <li className="btn-page-first" onClick={event=> _this.getProductList(1)}><FeatherIcon icon="chevrons-left" className="nd-icon" /></li> : null}
              { startNum - 1 > 0 ? <li className="btn-page-prev" onClick={event=> _this.getProductList(startNum-1)}><FeatherIcon icon="chevron-left" className="nd-icon" /></li> : null}
              {rendering()}
              { endNum != totalPages  ? <li className="btn-page-next" onClick={event=> _this.getProductList(startNum+10)}><FeatherIcon icon="chevron-right" className="nd-icon" /></li> : null}
              { curPageNum != totalPages ? <li className="btn-page-last" onClick={event=> _this.getProductList(totalPages)}><FeatherIcon icon="chevrons-right" className="nd-icon" /></li> : null}
            </ul>
          </div>);
      }
      else {
        return (<></>);
      }

    }

    return (
      <Modal size="lg" className='ModalProductList' open={this.isShow} toggle={event=> this.modalToggle(this.modal)} centered={true}>
        <ModalHeader>그룹 상품 추가</ModalHeader>
        <ModalBody>
          <div>

            <div className="group-products-wrap" ref={this.groupProducts}>
              <ul className="product-list">
                <li className="holder"><i className="material-icons">outbox</i></li>
                {this.inGroup && this.inGroup.length > 0 ?
                  this.inGroup.map((item, i) => (
                    <li key={i}><img src={item.images.s_1} /></li>
                  )) : null}
              </ul>
            </div>

            <div className="popup-contents-main">
              <Row>
                <Col>
                  <div className="section-box">
                    <div className="product-search-box">
                      <div className="search-input-box">
                        <FormGroup>
                          <FormSelect name="category" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchParams.category} className="search-type">
                            <option value="">-카테고리 전체-</option>
                            {this.categories  ?
                              this.categories.map((item, i) => (
                                <option key={i} value={item.category_no}>{item.category_name}</option>
                              ))
                              : null
                            }
                          </FormSelect>
                          <FormInput name="keyword" placeholder="상품명 검색" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchParams.keyword} className="table-input-text search-input" />
                          <Button onClick={event=> this.search()} theme="secondary">검색</Button>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="list-scroll-wrap">
                    <table>
                      <colgroup>
                        <col width="88px" />
                        <col width="" />
                        <col width="76px" />
                      </colgroup>
                      <tbody>
                      {this.list && this.list.docs && this.list.docs.length > 0 ?
                        this.list.docs.map((item, i) => (
                          <tr key={i}>
                            <td className="text-center"><div className="product-thumb"><img src={item.images.s_1} /></div></td>
                            <td className="left"><div className="product-title"><a href={'//' + this.store.domain + '/product/' + item.productName + '/' + item.platformProductId} target='_blank'>{item.productName}</a></div></td>
                            <td className="text-center">
                              {this.added_ids.indexOf(item._id) == -1 ? (
                                <Button className="btn-review-write" onClick={event => this.addGroupBox(item)} >추가</Button>
                              ) : (
                                '추가됨'
                              )}
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td></td>
                        </tr>
                      }

                      </tbody>
                    </table>
                    <Pagination />
                  </div>

                </Col>
              </Row>
            </div>
            <div className="popup-close">
              <Button onClick={event=> this.modalToggle(this.modal)} theme="dark" >닫기</Button>
              <Button onClick={event=> this.saveGroup()} theme="success" className="ml-2">저장</Button>
            </div>
          </div>

        </ModalBody>
      </Modal>
    )
  }
}

ModalProductList.propTypes = {
  // modals: PropTypes.object
  // modals: PropTypes.bool
};

ModalProductList.defaultProps = {
  // modals: false
};

export default ModalProductList;
