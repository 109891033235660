import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Store } from "../../flux";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {Link} from 'react-router-dom';

import FeatherIcon from 'feather-icons-react';
import Shepherd from 'shepherd.js'; // 'react-shepherd' 패키지에서 Shepherd 클래스를 가져옵니다.
import '../../../node_modules/shepherd.js/dist/css/shepherd.css';
import './StartGuide.scss'

@inject(({ common }) => ({ getCurNav: common.getCurNav }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me}))
@inject(({ company }) => ({ updateStore:company.updateStore  }))
@observer
class StartGuide extends React.Component {

  @observable emailId = '';
  @observable isLogin = false;
  @observable curPath = '';

  constructor(props) {
    super(props);
    this.init();
    this.onChange = this.onChange.bind(this);
    this.updateStore = this.updateStore.bind(this);
  }

  @action
  init = async (key) => {

    let _this = this;
    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
      if(!this.store.settingProg) {
        this.store.settingProg = {
          isEnd: false,
          reviewWrite: false,
          reviewWriteIn: {
            item_1: false,
            item_2: false,
          },
          reviewPoint: false,
          reviewPointIn: {
            item_1: false,
            item_2: false,
            item_3: false,
          },
          commonDesign: false,
          commonDesignIn: {
            item_1: false,
            item_2: false,
          },
          active: false,
          activeIn: {
            item_1: false
          },
        }
        _this.updateStore();
      }
      else {
        if(!this.store.settingProg.reviewWriteIn) {
          this.store.settingProg.reviewWrite = false;
          this.store.settingProg.reviewWriteIn = {
            item_1: false,
            item_2: false,
          }
        }
        if(!this.store.settingProg.reviewPointIn) {
          this.store.settingProg.reviewPoint = false;
          this.store.settingProg.reviewPointIn = {
            item_1: false,
            item_2: false,
            item_3: false,
          }
        }
        if(!this.store.settingProg.commonDesignIn) {
          this.store.settingProg.commonDesign = false;
          this.store.settingProg.commonDesignIn = {
            item_1: false,
            item_2: false,
          }
        }
        if(!this.store.settingProg.activeIn) {
          this.store.settingProg.active = false;
          this.store.settingProg.activeIn = {
            item_1: false
          }
        }

        let sKeys = ['reviewWrite', 'reviewPoint', 'commonDesign', 'active'];
        let isEnd = true;
        for(let i in sKeys) {
          let isSubEnd = true;
          let isSubItemKey = sKeys[i] + 'In';
          for(let j in this.store.settingProg[isSubItemKey]) {
            if(!this.store.settingProg[isSubItemKey][j]) {
              isSubEnd = false;
            }
          }

          if(this.store.settingProg[sKeys[i]] !== isSubEnd) {
            this.store.settingProg[sKeys[i]] = isSubEnd;
            _this.updateStore();
          }
          if(!this.store.settingProg[sKeys[i]]) {
            isEnd = false;
          }
        }
      }


      this.curPath = this.props.getCurNav().submenu && this.props.getCurNav().submenu.name ? this.props.getCurNav().submenu.name : '';
    }

    //작성 설정 /reviewWriteConfig
    _this.tour = new Shepherd.Tour({
      useModalOverlay: false, // 모달 오버레이 사용 여부
      defaultStepOptions: {
        classes: 'shepherd-theme-arrows', // 투어 스타일 클래스
        scrollTo: { behavior: 'smooth', block: 'center' }, // 스크롤 사용 여부
        scrollToHandler: function(){
          console.log('scrollToHandler')
        },
        cancelIcon: {
          enabled: true,
          iconClasses: 'custom-cancel-icon', // 커스텀 아이콘 클래스
          useIcon: '<i class="fas fa-times"></i>', // Font Awesome의 X 아이콘을 사용
          attachTo: 'top-right',
          title: '취소',
          events: {
            click: () => {
              // 아이콘이 클릭될 때 실행될 동작을 정의
              _this.tour.cancel();
            },
          },
        },
      },
    });

    _this.tour.addStep({
      id: 'cafe24UseReviewBoardNo',
      text: '쇼핑몰 관리자에서<br />[게시판 > 게시판 관리 메뉴]이동<br />상품후기의 "게시판 ID"를 입력해 주세요.<br/>기본값은 4로 되어 있습니다.',
      attachTo: {
        element: '#cafe24UseReviewBoardNo',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.tour.show('getPlatformReview')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'getPlatformReview',
      text: '쇼핑몰에서 리뷰를 가져옵니다<br /><strong>(주의! 현재 온리뷰에 리뷰는 모두 삭제됩니.)</strong>',
      attachTo: {
        element: '#getPlatformReview',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_1 = true;
            _this.updateStore();
            _this.tour.show('basicWriteSettings')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'basicWriteSettings',
      text: '리뷰 작성시 최소, 최대 글자수를 설정해 주시고<br />작성영역에 작성을 위한 가이드 또는 혜택 문구를 입력해 주세요.',
      attachTo: {
        element: '#basicWriteSettings',
        on: 'top',
      },
      buttons: [
        {
          text: '완료',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_2 = true;
            _this.updateStore();
            _this.tour.cancel();
          },
        }
      ]
    });

    //포인트 지급 /reviewWriteConfig
    _this.tour.addStep({
      id: 'noticeText',
      text: '상세페이지 리븊 리스트에 노출되는 포인트에 대한 안내 문구를 입력해 주세요.',
      attachTo: {
        element: '#noticeText',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewPointIn.item_1 = true;
            _this.tour.show('basicPointSettings')
          },
        }
      ]
    });


    _this.tour.addStep({
      id: 'basicPointSettings',
      text: '기본적인 포인트 지급에 대하여 설정을 해주세요.',
      attachTo: {
        element: '#basicPointSettings',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewPointIn.item_2 = true;
            _this.tour.show('bonusPointSettings')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'bonusPointSettings',
      text: '보너스 포인트 지급에 대하여 설정을 해주세요.',
      attachTo: {
        element: '#bonusPointSettings',
        on: 'top',
      },
      buttons: [
        {
          text: '완료',
          action: function () {
            _this.store.settingProg.reviewPointIn.item_3 = true;
            _this.updateStore();
            _this.tour.cancel();
          },
        }
      ]
    });


    //기본 디자인 /commonDesign
    _this.tour.addStep({
      id: 'commonsDesign',
      text: '폰트 크키와 컬러, 테마 색상 2가지, 작성자 계정 노출 설정 방법등에 대하여 설정해 주세요.',
      attachTo: {
        element: '#commonsDesign',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.commonDesignIn.item_1 = true;
            _this.tour.show('displaySettings')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'displaySettings',
      text: '보너스 포인트 지급에 대하여 설정을 해주세요.',
      attachTo: {
        element: '#displaySettings',
        on: 'top',
      },
      buttons: [
        {
          text: '완료',
          action: function () {
            _this.store.settingProg.commonDesignIn.item_2 = true;
            _this.updateStore();
            _this.tour.cancel();
          },
        }
      ]
    })

    _this.tour.addStep({
      id: 'inAppOrder',
      text: '주문서를 모두 입력 및 선택해 주시고 한달무료 사용 또는 첫 이벤트 결제를 통하여 더 많은 혜택으로 서비스를 활성화 해주세요.',
      attachTo: {
        element: '#inAppOrder',
        on: 'top',
      },
      buttons: [
        {
          text: '완료',
          action: function () {
            _this.store.settingProg.activeIn.item_1 = true;
            _this.updateStore();
            _this.tour.cancel();
          },
        }
      ]
    })


  };

  updateStore = async (event) => {

    let updateStore = {
      id: this.store._id,
      settingProg: this.store.settingProg,
    }

    this.props.updateStore( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {

      }
    });
  }

  noDisplaySguide = async (event) => {
    let cf = window.confirm('설정 가이드를 그만보시겠습니까?');
    if(cf) {
      this.store.settingProg.isEnd = true;
      this.updateStore();
    }
    else {
      return false;
    }

  }

  forceIndexGuideShow = async (event) => {
    this.curPath = '';
  }

  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)
  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {

  }


  startTour = (startStepId) => {
    this.tour.show(startStepId);
  };


  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );




    return (
        <>
        {
          this.store && this.store.settingProg && !this.store.settingProg.isEnd ? (
            <div className="start-guide">
              {(() => {
                switch (this.curPath) {
                  case "reviewWriteConfig":
                    return (
                      <>
                        <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322" />작성 설정 진행 가이드</h3>
                        <p>리뷰 작성에 필요한 기본 설정을 진행합니다.</p>
                        <div className="prograss-bars">
                          {this.store.settingProg.reviewWriteIn.item_1 ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.reviewWriteIn.item_2 ? <span className="active"></span> : <span></span>}
                        </div>
                        <ul>
                          <li onClick={event=> this.startTour('cafe24UseReviewBoardNo')} >{this.store.settingProg.reviewWriteIn.item_1 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            쇼핑몰 관리자에서 리뷰 게시판을 id를 입력하시고 저장 후 리뷰 가져오기 및 작성시 내용 공유가 가능합니다.(default: 4)
                          </li>
                          <li>{this.store.settingProg.reviewWriteIn.item_2 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            리뷰 글자수와 주요 안내문구를 작성해 주세요.
                          </li>
                        </ul>
                        <p><span onClick={event=> this.forceIndexGuideShow()}>다른 설정 보기</span></p>
                      </>
                    );
                    break;
                  case "reviewPointConfig":
                    return (
                      <>
                        <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322" />작성 설정 진행 가이드</h3>
                        <p><strong>포인트 지급</strong> 필요한 기본 설정을 진행합니다.</p>
                        <div className="prograss-bars">
                          {this.store.settingProg.reviewPointIn.item_1 ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.reviewPointIn.item_2 ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.reviewPointIn.item_3 ? <span className="active"></span> : <span></span>}
                        </div>
                        <ul>
                          <li onClick={event=> this.startTour('noticeText')} >{this.store.settingProg.reviewPointIn.item_1 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            상세페이지 리븊 리스트에 노출되는 포인트에 대한 안내 문구를 입력해 주세요.
                          </li>
                          <li>{this.store.settingProg.reviewPointIn.item_2 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            기본적인 포인트 지급에 대하여 설정을 해주세요.
                          </li>
                          <li>{this.store.settingProg.reviewPointIn.item_3 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            보너스 포인트 지급에 대하여 설정을 해주세요.
                          </li>
                        </ul>
                        <p><span onClick={event=> this.forceIndexGuideShow()}>다른 설정 보기</span></p>
                      </>
                    );
                    break;
                  case "commonDesign":
                    return (
                      <>
                        <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322" />작성 설정 진행 가이드</h3>
                        <p><strong>기본 디자인</strong>에 필요한 기본 설정을 진행합니다.</p>
                        <div className="prograss-bars">
                          {this.store.settingProg.commonDesignIn.item_1 ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.commonDesignIn.item_2 ? <span className="active"></span> : <span></span>}
                        </div>
                        <ul>
                          <li onClick={event=> this.startTour('commonsDesign')} >{this.store.settingProg.commonDesignIn.item_1 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            폰트 크키와 컬러, 테마 색상 2가지, 작성자 계정 노출 설정 방법등에 대하여 설정해 주세요.
                          </li>
                          <li>{this.store.settingProg.commonDesignIn.item_2 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            리뷰 노출 항목을 설정해 주세요.
                          </li>
                        </ul>
                        <p><span onClick={event=> this.forceIndexGuideShow()}>다른 설정 보기</span></p>
                      </>
                    );
                    break;

                  case "inAppOrder":
                    return (
                      <>
                        <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322" />작성 설정 진행 가이드</h3>
                        <p>서비스를 시작하시려면 <strong>온리뷰 서비스를 활성화</strong>를 설정해 주세요.</p>
                        <div className="prograss-bars">
                          {this.store.settingProg.activeIn.item_1 ? <span className="active"></span> : <span></span>}
                        </div>
                        <ul>
                          <li onClick={event=> this.startTour('inAppOrder')} >{this.store.settingProg.activeIn.item_1 ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            주문서를 모두 입력 및 선택해 주시고 한달무료 사용 또는 첫 이벤트 결제를 통하여 더 많은 혜택으로 서비스를 활성화 해주세요.
                          </li>
                        </ul>
                      </>
                    );
                    break;
                  default:
                    return (
                      <>
                        <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322" />시작하기</h3>
                        <p>아래 설치 지침에 따라 셋팅을 시작하십시오.</p>
                        <div className="prograss-bars">
                          {this.store.settingProg.reviewWrite ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.reviewPoint ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.commonDesign ? <span className="active"></span> : <span></span>}
                          {this.store.settingProg.active ? <span className="active"></span> : <span></span>}
                        </div>
                        <ul>
                          <li>{this.store.settingProg.reviewWrite ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            <Link to="/reviewWriteConfig">기존 리뷰 가져오와 작성 설정을 해주세요.</Link>
                          </li>
                          <li>{this.store.settingProg.reviewPoint ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            <Link to="/reviewPointConfig">리뷰 작성시 혜택을 줄 포인트 설정을 해주세요.</Link>
                          </li>
                          <li>{this.store.settingProg.commonDesign ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            <Link to="/commonDesign">리뷰의 색상과 폰트 색상등 디자인에 대한 설정을 해주세요.</Link>
                          </li>
                          <li>{this.store.settingProg.active ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            <Link to="/inAppOrder">온리뷰 서비스 활성화 해주세요.</Link>
                          </li>
                        </ul>
                        <p className="noDisplaySguide" onClick={event=> this.noDisplaySguide()} >시작하기 가이드 그만보기</p>
                      </>
                    );
                }
              })()}
            </div>
          ) : null
        }
        </>
    );
  }
}

StartGuide.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

StartGuide.defaultProps = {
  hideLogoText: false
};

export default StartGuide;
