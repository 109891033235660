import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { ColorRing } from  'react-loader-spinner'
import _ from "underscore";
import './style.scss'
import './reviewGroup.scss'

import PageTitle from "../../../components/common/PageTitle";
import ModalProductList from "../../../components/modal/ModalProductList";
import ModalLoading from "../../../components/common/ModalLoading";

import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Col, Row
} from "shards-react";
import moment from "moment";
// import Calendar from "react-calendar";
// import DateTimePicker from 'react-datetime-picker';
// import axios from "axios";


@inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, uploadImg: common.uploadImg, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ product }) => ({ getProductGroupList: product.getProductGroupList, updateProductGroup: product.updateProductGroup, removeProductGroup: product.removeProductGroup}))
@inject(({ review }) => ({ updateReview:review.updateReview }))

@observer
class ReviewGroup extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {};
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = false;
  @observable urlParams = new URLSearchParams(window.location.search);

  //list
  @observable grouplistParams = {
    page:1, limit:0,
  }
  // @observable searchParams = {
  //   keyword: ''
  // };
  @observable groupList = [];


  //modals
  @observable modals = {
    ModalProductList: false,
    ModalChildProductList: false
  };

  @observable dropDowns = {
    type: false
  };

  //page

  @observable newGroup = {
    store_id: '',
    type: 'share',
    parentProduct_ids: [],
    childProduct_ids: [],
    product_ids: [],
    products: [],
    name: ''
  };
  @observable added_ids = [];
  @observable  parentGroupProducts = [];

  @observable selGroup = {};


  constructor(props) {
    super(props);
    this.typeStr = {
      share: '공유형',
      integration: '통합형'
    };
    this.init();

    this.modalToggle = this.modalToggle.bind(this);
  }


  componentDidUpdate(prevProps, prevState) {

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.permissions = this.props.me.permissions;

      this.newGroup.store_id = this.teamUser.selStore_id;
      this.selGroup.store_id = this.teamUser.selStore_id;
      this.grouplistParams.store_id = this.teamUser.selStore_id;
      this.getProductGroupList();
    }

  };

  getProductGroupList = async() => {
    if(this.grouplistParams.store_id) {
      this.groupList = await this.props.getProductGroupList(this.grouplistParams);
      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };

  //fn
  addGroup = async() => {
    this.selGroup = this.newGroup;
    this.parentGroupProducts = [];
    this.added_ids = [];
  };

  // addChildGroup = async() => {
  //   this.selGroup = this.newGroup;
  // };

  showModal = async(target) => {
    this.modals[target] = true;
  };
  @action
  modalToggle = async (target, isShow, data) => {
    if(isShow) {
      this.modals[target] = true;
    }
    else {
      this.modals[target] = !this.modals[target];
    }

    if(!target) {
      for( var i in this.modals) {
        this.modals[i] = false;
      }
    }
    if(data) {
      this.modals.data = data;
    }
  };

  dropDownToggle = async (target) => {
    if(target) {
      this.dropDowns[target] = !this.dropDowns[target];
    }
  };

  setGroup = async (list, ids) => {
    this.selGroup.products = list;
  };

  setParentGroup = async (list) => {
    this.parentGroupProducts = list;
  };

  revmoeProduct = async (target, item) => {
    if(target == 'product') {
      this.selGroup.products = _.reject(this.selGroup.products, function(obj) {
        return obj._id === item._id;
      });
    }
    else if(target == 'parent') {
      this.parentGroupProducts = _.reject(this.parentGroupProducts, function(obj) {
        return obj._id === item._id;
      });
    }

    this.added_ids = _.without(this.added_ids, item._id);
  };

  selecedGroup = async (item) => {
    item.products = item.product_ids;
    this.parentGroupProducts = item.parentProduct_ids;
    this.selGroup = item;
    let product_ids = _.pluck(item.product_ids, '_id');
    let childGroupProduct_ids = _.pluck(item.parentProduct_ids, '_id');
    this.added_ids = product_ids.concat(childGroupProduct_ids);
  };

  revmoeGroup = async (item) => {
    let isConfirm = window.confirm('"' + item.name + '" 그룹을 정말 삭제할까요?');
    if(isConfirm) {
      this.props.removeProductGroup(item, function(res) {
        window.location.reload();
      });
    }
    else {
      return false;
    }

  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.newGroup[name] = value;
  };

  saveGroup = async (event) => {
    this.selGroup.product_ids = _.pluck(this.selGroup.products, '_id');
    this.selGroup.parentProduct_ids = _.pluck(this.parentGroupProducts, '_id');
    console.log(this.selGroup)
    this.props.updateProductGroup(this.selGroup, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        window.location.reload();
      }
    });

  };

  render() {
    let _this = this;



    return (
      <div className="main-content">
        <PageTitle />
        {/*<div className="add-box"><i className="material-icons">add</i></div>*/}
        {/*<div><i className="material-icons">add_box</i></div>*/}
        {/*<div><i className="material-icons">add_circle_outline</i></div>*/}
        {/*<div><i className="material-icons">add_circle</i></div>*/}
        {/*<div><i className="material-icons">library_add</i></div>*/}

        <Row>
          <Col className="group-set-list">
            <ul>
              {this.groupList && this.groupList.docs && this.groupList.docs.length > 0 ?
                this.groupList.docs.map((item, i) => (
                  <li key={i}><Button size="lm" onClick={event=> this.selecedGroup(item)}>{item.name}</Button><span onClick={event=> this.revmoeGroup(item)} className="material-icons btn-remove">clear</span></li>
                ))
                : null
              }
              {/*<li><Button size="lm">커피원두</Button></li>*/}
              {/*<li><Button size="lm" theme="secondary">머신기</Button></li>*/}
              {this.newGroup && this.newGroup.name ? <li><Button size="lm">{this.newGroup.name}</Button></li> : null}
              <li>
                <InputGroup>
                  <FormInput name="name" placeholder="그룹추가" value={this.newGroup.name} onChange={event => {this.onChangeValue(event)}} className="ml-2 border-radius" onKeyPress={event => {if(event.key === 'Enter') this.addGroup()}}/>
                  <InputGroupAddon type="append"><Button theme="secondary" onClick={date => {this.addGroup()}} ><i className="material-icons">add</i></Button></InputGroupAddon>
                </InputGroup>
              </li>
            </ul>
          </Col>
        </Row>
        {this.selGroup && this.selGroup.name ?
        <>
          <Row className="mt-3">
            <Col>
              <div style={{ display: "flex" }}>
                <Dropdown open={this.dropDowns.type} toggle={() => this.dropDownToggle('type')} className="mr-2">
                  <DropdownToggle outline caret>{this.typeStr[this.selGroup.type]}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.selGroup.type='share'}>{this.typeStr['share']}</DropdownItem>
                    <DropdownItem onClick={() => this.selGroup.type='integration'}>{this.typeStr['integration']}</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
            <Col className="text-right" >
                <Button onClick={event=> this.saveGroup()}>저장</Button>
            </Col>
          </Row>
          {this.selGroup && this.selGroup.type == 'integration' ? (
            <>
              <Row className="mt-3">
                <Col>
                  <div className="group-products-wrap">
                    {!this.parentGroupProducts || this.parentGroupProducts.length == 0 ?
                      <div className="add-box" onClick={event=> this.modalToggle('ModalChildProductList', true)}><i className="material-icons">loupe</i></div> : null }
                    {this.parentGroupProducts && this.parentGroupProducts.length > 0 ?
                      <ul className="product-list">
                        <li className="holder"><i className="material-icons" onClick={event=> this.modalToggle('ModalChildProductList', true)}>loupe</i></li>
                        {this.parentGroupProducts.map((item, i) => (
                          <li key={i}><img src={item.images.s_1} /><span onClick={event=> this.revmoeProduct('parent', item)} className="material-icons btn-remove">clear</span></li>
                        ))}
                      </ul> : null}
                  </div>
                  <ModalProductList isShow={this.modals.ModalChildProductList} modal="ModalChildProductList" modalToggle={this.modalToggle} setGroup={this.setParentGroup} inGroup={this.parentGroupProducts} added_ids={this.added_ids}/>
                </Col>
              </Row>
              <Row className="integration-guide-icon-box"><Col className="text-center"><span className="material-icons">play_for_work</span><span style={{'display': 'none'}}>아래 각각의 상품 리뷰에 위의 상품의 리뷰를 통합하여 노출이 됩니다.</span></Col></Row>
            </>
          ) : null}
          <Row className="mt-3">
            <Col>
              <div className="group-products-wrap">
                {!this.selGroup.products || this.selGroup.products.length == 0 ?
                  <div className="add-box" onClick={event=> this.modalToggle('ModalProductList', true)}><i className="material-icons">loupe</i></div> : null }
                {this.selGroup.products && this.selGroup.products.length > 0 ?
                  <ul className="product-list">
                    <li className="holder"><i className="material-icons" onClick={event=> this.modalToggle('ModalProductList', true)}>loupe</i></li>
                    {this.selGroup.products.map((item, i) => (
                      <li key={i}><img src={item.images.s_1} /><span onClick={event=> this.revmoeProduct('product', item)} className="material-icons btn-remove">clear</span></li>
                    ))}
                  </ul> : null}
              </div>
              <ModalProductList isShow={this.modals.ModalProductList} modal="ModalProductList" modalToggle={this.modalToggle} setGroup={this.setGroup} inGroup={this.selGroup.products} added_ids={this.added_ids}/>
            </Col>
          </Row>
        </>
          : "이미지로 가이드 - 그룹설정으로 리뷰를 더 활용해 보세요. 단, 고객의 신뢰를 저하하는 그룹설정을 자제하시는게 좋습니다."}
        <ModalLoading isLoading={this.isLoading} />
      </div>
    )
  }
}

export default ReviewGroup;
